import Image from 'next/image'

type SNSIconProps = {
  sns: {
    iconPath: string
    link: string
  }
}

export const SNSIcon = (props: SNSIconProps) => {
  const { sns } = props

  return (
    <div>
      <a href={sns.link} target="_blank" rel="noopener noreferrer">
        <Image src={sns.iconPath} width={40} height={40} />
      </a>
    </div>
  )
}
