export enum ItemListType {
  SpecialEdition = 'SpecialEdition', // 特集商品
  SpecialEditionTomato = 'SpecialEditionTomato', // 特集商品 トマト
  Latest = 'Latest', // 新着の食材
  Limited = 'Limited',
  Repeat = 'Repeat', // 購入したことのある食材
  Ranking = 'Ranking', // 🥇売れ筋トップ50
  Mushroom = 'Mushroom', //蘑菇
  FreshFish = 'FreshFish', //鲜鱼
  Recommend = 'Recommend', // レコメンド
  WinterVegetables = 'WinterVegetables', //冬野菜
  Meat = 'Meat', //精肉類
  Wishlist = 'Wishlist', //お気に入り
  Asparagus = 'Asparagus', //アスパラ
  Chicken = 'Chicken', //地鶏
  TopRecommend = 'TopRecommend', //レコメンド商品
  RestockNotice = 'RestockNotice', //入荷通知メールからの流入イベント
  MorningVegetables = 'MorningVegetables',
  SeasonalIngredients = 'SeasonalIngredients', //旬の食材
  BargainArticle = 'BargainArticle', // 特価品
  NextdayDelivery = 'NextdayDelivery', //翌日便
  Reservation = 'Reservation', //予約商品
}

//GA item:item_list_name,item_list_id
export const ITEM_LIST_TYPE = {
  [ItemListType.Asparagus]: {
    //アスパラ
    name: 'Asparagus',
    id: 'asparagus',
  },
  [ItemListType.Meat]: {
    //精肉類
    name: 'Meat',
    id: 'meat',
  },
  [ItemListType.WinterVegetables]: {
    //冬野菜
    name: 'WinterVegetables',
    id: 'wintervegetables',
  },
  [ItemListType.FreshFish]: {
    //鲜鱼
    name: 'FreshFish',
    id: 'freshFish',
  },
  [ItemListType.Latest]: {
    // 新着の食材
    name: 'Latest',
    id: 'latest',
  },
  [ItemListType.Mushroom]: {
    //蘑菇
    name: 'Mushroom',
    id: 'mushroom',
  },
  [ItemListType.Ranking]: {
    // 売れ筋トップ50
    name: 'Ranking',
    id: 'ranking',
  },

  [ItemListType.Recommend]: {
    // レコメンド
    name: 'Recommend',
    id: 'recommend',
  },
  [ItemListType.Repeat]: {
    // 再購入する
    name: 'Repeat',
    id: 'repeat',
  },
  [ItemListType.SpecialEdition]: {
    // 特集商品
    name: 'Summer Campaign',
    id: 'summer_campaign',
  },
  [ItemListType.SpecialEditionTomato]: {
    // 特集商品 トマト
    name: 'Colorful Tomato Campaign',
    id: 'colorful_tomato_campaign',
  },
  [ItemListType.Wishlist]: {
    //お気に入り
    name: 'Wishlist',
    id: 'wishlist',
  },
  [ItemListType.Chicken]: {
    //地鶏
    name: 'Chicken',
    id: 'chicken',
  },
  [ItemListType.TopRecommend]: {
    //あなたへのおすすめ
    name: 'TopRecommend',
    id: 'topRecommend',
  },
  [ItemListType.RestockNotice]: {
    //入荷通知メールからの流入イベント
    name: 'RestockNotice',
    id: 'restockNotice',
  },
  [ItemListType.MorningVegetables]: {
    //今朝採れ野菜
    name: 'MorningVegetables',
    id: 'morningVegetables',
  },
  [ItemListType.SeasonalIngredients]: {
    //旬の食材
    name: 'SeasonalIngredients',
    id: 'seasonalIngredients',
  },
  [ItemListType.BargainArticle]: {
    //特価品
    name: 'BargainArticle',
    id: 'bargainArticle',
  },
  [ItemListType.NextdayDelivery]: {
    //翌日便
    name: 'NextdayDelivery',
    id: 'nextdayDelivery',
  },
}
