import 'twin.macro'
import React, { useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '../Button/Button'
import { useCartDispatch } from 'src/contexts/cart'
import { leftMenuProps } from 'src/consts/leftMemuItems'
import { storageKey } from 'src/consts/storageKey'
import router from 'next/router'
import { useCurrentUser } from 'src/contexts/currentUser'
import { UserType } from 'src/generated/graphql'

interface Props {
  showDrawer: boolean
  closeDrawer: () => void
  leftMemuItems: leftMenuProps[]
}
export function Drawer({ showDrawer, closeDrawer, leftMemuItems }: Props) {
  const { logout } = useAuth0()
  const cartDispatch = useCartDispatch()
  const { showRole } = useCurrentUser()
  const showSeller = showRole === UserType.Buyer
  const rootDivRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const refDiv = rootDivRef?.current
    const eventListener = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
    }
    refDiv?.addEventListener('touchmove', eventListener)

    refDiv?.addEventListener('wheel', eventListener)
    return () => {
      refDiv?.removeEventListener('touchmove', eventListener)
      refDiv?.removeEventListener('wheel', eventListener)
    }
  }, [])

  return (
    <div ref={rootDivRef}>
      <CSSTransition in={showDrawer} classNames="drawer-ltr" timeout={500} unmountOnExit>
        <div tw="fixed top-0 bottom-0 w-full z-10 opacity-100 h-full mb-16">
          <div
            tw="relative w-5/6 bg-gray-alabaster fixed top-0 z-10 bottom-16 opacity-100"
            css={'height:calc(100% - 64px);box-shadow: 4px 0px 4px rgba(117, 117, 117, 0.08)'}
          >
            <div tw="pl-5 flex flex-col">
              {leftMemuItems.map((item, index) =>
                !item.disable ? (
                  <button onClick={() => router.push(item.href)} key={index}>
                    <div
                      tw="w-full h-16 border-b flex items-center justify-between cursor-pointer"
                      onClick={closeDrawer}
                    >
                      <div tw="flex justify-start items-center">
                        <img src={item.icon} tw="w-8 h-8 object-cover" />
                        <span tw="ml-2 text-14px text-black font-bold">{item.title}</span>
                      </div>
                      <img src={item.arrowIcon} tw="text-right mr-5 object-cover" />
                    </div>
                  </button>
                ) : (
                  <button key={index}>
                    <div tw="w-full h-16 border-b flex items-center justify-between outline-none">
                      <div tw="flex justify-start items-center">
                        <img src={item.disableIcon} tw="w-8 h-8 object-cover" />
                        <span tw="ml-2 text-14px text-placeholder font-bold">{item.title}</span>
                      </div>
                      <img src={item.disableArrowIcon} tw="text-right mr-5 object-cover" />
                    </div>
                  </button>
                )
              )}
            </div>

            <div tw="absolute left-0 bottom-0 px-12 py-10 w-full border-t">
              {!showSeller ? (
                <Button
                  tw="bg-white text-black w-full border border-black"
                  onClick={() => {
                    closeDrawer()
                    cartDispatch({ type: 'clear' })
                    sessionStorage.removeItem(storageKey.BUTTON_ACTIVE)
                    setTimeout(() => {
                      logout({ returnTo: window.location.origin })
                      localStorage.removeItem(storageKey.LOCAL_JUDGING_STATUS)
                    }, 500)
                  }}
                >
                  ログアウト
                </Button>
              ) : (
                <Button tw="text-white" disabled>
                  ログアウト
                </Button>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}
