import { TwStyle } from 'twin.macro'
import { Button } from 'src/components/Button/Button'
import { Portal } from 'src/components/Portal/Portal'
import { Overlay } from 'src/components/Overlay/Overlay'
import React from 'react'

export interface Props {
  title?: string
  message: React.ReactNode
  buttonTitle?: string
  OverlayCss?: (string | TwStyle)[]
  messageCss?: (string | TwStyle)[]
  note?: string
  onButtonClick?: () => void
}

export function WarningModal({ title, message, buttonTitle, OverlayCss, messageCss, note, onButtonClick }: Props) {
  return (
    <Portal>
      <Overlay css={OverlayCss}>
        <div tw="relative flex flex-col justify-between items-center w-11/12 max-w-lg bg-white rounded-lg p-24px max-h-5/6 overflow-y-auto">
          <div tw="flex justify-center items-center">
            <img src="/icon_warning.svg" />
            <p tw="pl-4 text-12px font-bold text-green-deep  whitespace-pre">{title}</p>
          </div>
          <div tw="pt-2 text-12px whitespace-pre-wrap" css={messageCss}>
            {message}
          </div>
          <Button type="button" tw="bg-orange text-white w-4/5 flex-shrink-0 mt-16px" onClick={onButtonClick}>
            <span tw="text-14px">{buttonTitle}</span>
          </Button>
          {note && <p tw="text-center text-black text-12px mt-1 whitespace-pre-wrap">{note}</p>}
        </div>
      </Overlay>
    </Portal>
  )
}
