export const SNS = {
  line: {
    iconPath: '/sns/line_icon.svg',
    link: 'https://liff.line.me/1645278921-kWRPP32q/?accountId=185dxbqf',
  },
  instagram: {
    iconPath: '/sns/instagram_icon.svg',
    link: 'https://www.instagram.com/yamato_food_market?igsh=MTk4cDR3cmthYnJqZw==',
  },
} as const
