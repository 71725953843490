import tw, { TwStyle } from 'twin.macro'
import { useCallback, useMemo } from 'react'
import { ButtonType } from 'src/consts/navMenuItems'

export interface TabBarItemProps {
  type: ButtonType
  image: string
  hightlight: string
  disableImage: string
  text: string
  active: boolean
  cartCount?: number
  cssParams: TwStyle[]
  clickAction?: (key: ButtonType, isActive: boolean) => void
  disable: boolean
}

export function TabBarItem({
  type,
  image,
  hightlight,
  text,
  active,
  cartCount,
  cssParams,
  clickAction,
  disable,
  disableImage,
}: TabBarItemProps) {
  const _clicked = useCallback(() => {
    clickAction && clickAction(type, active)
  }, [type, active, clickAction])

  const imagePath = useMemo(() => {
    if (disable) {
      return disableImage
    } else {
      return active ? hightlight : image
    }
  }, [image, hightlight, active, disable, disableImage])

  const cssParamArr: TwStyle[] = useMemo(() => {
    const tmpinactive: TwStyle = tw`pointer-events-none cursor-default`
    const tmpactive: TwStyle = tw`pointer-events-auto`
    const result = [...cssParams]
    if (disable) {
      result.push(tmpinactive)
    } else {
      result.push(tmpactive)
    }
    return result
  }, [cssParams, disable])

  return (
    <div
      tw="flex flex-row justify-center items-center"
      css={[cssParamArr]}
      onClick={() => {
        _clicked()
      }}
    >
      {type === ButtonType.Inquiry && (
        <div
          tw="hidden landing-pc:flex w-20px h-28px"
          css="border-left: 0.5px solid; border-left-color: #747474;"
        ></div>
      )}
      <div tw="flex flex-col justify-center cursor-pointer space-y-4px">
        <div tw="relative block w-26px h-26px mx-auto">
          <img src={imagePath} tw="w-26px h-26px object-cover" />
          {cartCount ? cartCount > 0 && <div tw="w-3 h-3 bg-red rounded-full absolute top-0 right-0"></div> : ''}
        </div>
        <ButtonText css={[disable ? tw`text-placeholder` : tw`text-black`, tw`pt-0 landing-pc:pt-2px`]}>
          {text}
          <div tw="w-22px h-2px bg-black mx-auto rounded-full mt-1" css={[active ? tw`visible` : tw`invisible`]}></div>
        </ButtonText>
      </div>
    </div>
  )
}

const ButtonText = tw.div`
font-bold text-black text-12px text-center leading-none pt-2px whitespace-nowrap
`
