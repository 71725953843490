import { format, isAfter, isBefore, isSameDay, isToday, isTomorrow, isYesterday, addDays, parseISO } from 'date-fns'
import ja from 'date-fns/locale/ja'
import { ProductAvailableCalendar } from 'src/generated/graphql'

export const toDateInJapanese = (input: string | Date): string => {
  return format(new Date(input), 'M/d（EEEEE）', { locale: ja })
}

export const formatDateInJapanese = (input: string | Date): string => {
  return format(new Date(input), 'yyyy/MM/dd（EEEEE）', { locale: ja })
}

export const humanize = (input: string, hideYesterday?: boolean): string | undefined => {
  switch (true) {
    case isYesterday(new Date(input)) && !hideYesterday:
      return '昨日'
    case isToday(new Date(input)):
      return '本日'
    case isTomorrow(new Date(input)):
      return '明日'
  }

  return
}

export const toDateInJapaneseWithoutWeekday = (input: string): string => {
  return format(new Date(input), 'M/d', { locale: ja })
}

export const toDayOfWeek = (input: string): string => {
  return format(new Date(input), 'EEEEE', { locale: ja })
}

export function formatDateObject(date: Date) {
  return format(date, 'yyyy-MM-dd')
}

export function formatDateString(date: string) {
  return format(new Date(date), 'yyyy-MM-dd')
}

export function formatDateString2(date: string) {
  return format(new Date(date), 'yyyy/MM/dd')
}

export function formatDateMonthAndDay(date: string) {
  return format(new Date(date), 'M月d日')
}

export function formatDateYearAndMonth(date: string) {
  return format(new Date(date), 'yyyy年 M月')
}

export function formatJapaneseDate(date: Date) {
  return format(date, 'yyyy年M月d日')
}

export function dayWithWeekday(date: string) {
  return `${toDateInJapaneseWithoutWeekday(date)} (${toDayOfWeek(date)})`
}

export function isValidDeliveryDate(selectedDeliveryDate: Date, productAvailableCalendar: ProductAvailableCalendar) {
  const minSelectableDateStr = productAvailableCalendar?.minDeliveryDate
  const minSelectableDate = new Date(formatDateString(minSelectableDateStr))
  const maxSelectableDateStr = productAvailableCalendar?.maxDeliveryDate
  const maxSelectableDate = new Date(formatDateString(maxSelectableDateStr))
  const unavailableDates = productAvailableCalendar?.unavailableDates
  const preOrderLimitDeliveryDate = productAvailableCalendar?.preOrderLimitDeliveryDate
    ? new Date(formatDateString(productAvailableCalendar?.preOrderLimitDeliveryDate))
    : undefined
  const maxDate =
    preOrderLimitDeliveryDate && preOrderLimitDeliveryDate < maxSelectableDate
      ? preOrderLimitDeliveryDate
      : maxSelectableDate

  if (isAfter(minSelectableDate, maxDate)) {
    return true
  }
  if (isSameDay(selectedDeliveryDate, minSelectableDate) || isSameDay(selectedDeliveryDate, maxDate)) {
    return true
  }
  if (
    isBefore(selectedDeliveryDate, minSelectableDate) ||
    isAfter(selectedDeliveryDate, maxDate) ||
    unavailableDates.includes(formatDateObject(selectedDeliveryDate))
  ) {
    return false
  }

  return true
}

export function incorrectDeliveryDate(minDeliveryDate: string, maxDeliveryDate: string) {
  const minDate = new Date(formatDateString(minDeliveryDate))
  const maxDate = new Date(formatDateString(maxDeliveryDate))
  return isAfter(minDate, maxDate)
}

/**
 * Get current date/time as as JS Date object
 * @returns
 */
export function getNowDateObject(): Date {
  return new Date()
}

/**
 * Convert string to JS date object
 * @param dateString
 * @returns
 */
export function toDateObject(dateString: string): Date {
  return new Date(dateString)
}

/**
 *最短お届け日～〇日後までの日付のみを選択候補として表示し
 */
export function getMinDateToSomedayArray(minDate: string, some: number) {
  const dates: string[] = []
  if (minDate) {
    const maxDate: Date = addDays(new Date(minDate), some)
    let loop = new Date(minDate)
    while (!isAfter(loop, maxDate)) {
      dates.push(formatDateObject(loop))
      loop = addDays(loop, 1)
    }
  }
  return dates
}

export function isDate(data: string) {
  return !isNaN(Date.parse(data))
}

/**
 * 今日から指定された日付までの日数を取得します。
 * @param date - 日数を計算する対象の日付（yyyy-MM-dd形式の文字列）
 * @returns 指定された日付までの日数（整数）
 */
export const getDaysFromToday = (date: string) => {
  const today = new Date()
  const targetDate = new Date(date)
  const diff = targetDate.getTime() - today.getTime()
  const msPerDay = 1000 * 60 * 60 * 24
  return Math.ceil(diff / msPerDay)
}

/**
 * 2つの日付配列をマージし、重複を排除します。
 * 引数はISO文字列を想定してます。
 * @param productDates
 * @param inputProductDates
 * @returns 重複のない、マージされた日付の配列
 */
export const mergeDates = (productDates: string[], inputProductDates: string[]): string[] => {
  const mergedDates = [...productDates, ...inputProductDates]
  // 日付の重複を排除
  return mergedDates.reduce<string[]>((acc, currentDate) => {
    const current = parseISO(currentDate)
    const isDuplicate = acc.some((date) => isSameDay(current, parseISO(date)))
    if (!isDuplicate) {
      acc.push(currentDate)
    }
    return acc
  }, [])
}

/**
 * 現在の日付から指定された日数後の日付を返します。
 * @param {number} daysToAdd - 現在の日付に加算する日数
 * @returns {Date} 計算された将来の日付
 */
export const getFutureDateFromToday = (daysToAdd: number): Date => {
  return addDays(new Date(), daysToAdd)
}
