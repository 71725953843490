import tw from 'twin.macro'
import Link from 'next/link'
import { Route } from 'src/consts/route'
import { useCurrentUser } from 'src/contexts/currentUser'
import { UserType } from 'src/generated/graphql'
import { SUPPORT_EMAIL, SUPPORT_TEL } from 'src/consts/service'
import { URL } from 'src/consts/URLs'
import { SNSIcon } from 'src/components/SNSIcon/SNSIcon'
import { SNS } from 'src/consts/sns'

export default function FooterNew({}) {
  const { role } = useCurrentUser()
  const isSeller = role === UserType.Seller
  const isBuyer = role === UserType.Buyer
  const { showRole } = useCurrentUser()
  const showSeller = showRole === UserType.Buyer
  return (
    <footer tw="flex flex-col text-black" css={[isBuyer || showSeller ? tw`block` : tw`hidden`]}>
      <div tw="pt-8 pb-6 flex-col bg-white border-t border-gray-border">
        <div tw="flex justify-center pb-26px">
          <img src="/overallUI/icon_footer_logo.svg" height={30} width={145} />
        </div>
        <FooterText tw="font-bold">お問い合わせ</FooterText>
        <FooterText tw="mt-2">
          TEL :{' '}
          <a href={URL.TAP_CONTACT_WITH_PHONE}>
            <TextTap>{SUPPORT_TEL}</TextTap>
          </a>
          （9:00-18:00 土日祝含む）
        </FooterText>
        <FooterText tw="mt-2">
          MAIL :{' '}
          <a href={URL.TAP_CONTACT_WITH_EMAIL}>
            <TextTap>{SUPPORT_EMAIL}</TextTap>
          </a>
        </FooterText>
        <div tw="flex justify-center">
          <a href={URL.FREQUENTLY_ASKED_QUESTIONS}>
            <TextLink tw="mt-2 text-12px">よくあるお問い合わせ（FAQ）</TextLink>
          </a>
          <img src="/icon_external_link_black.svg" tw="self-end ml-1" />
        </div>
        <div tw="mt-7 flex justify-center max-w-md self-center mx-auto flex-wrap">
          {role && (
            <Link href={isSeller && !showSeller ? Route.sellerTermsAndConitions : Route.termsAndConitions}>
              <TextLink tw="mx-2 text-center">利用規約</TextLink>
            </Link>
          )}
        </div>
        <div tw="mt-3 flex justify-center max-w-md self-center mx-auto flex-wrap">
          <TextLink tw="mx-2">
            <a href={URL.DEAL_WITH_PERSONAL_INFORMATION}>個人情報の取扱いについて</a>
          </TextLink>
          <Link href={isSeller && !showSeller ? Route.sellerScta : Route.scta}>
            <TextLink tw="mx-2">特定商取引に関する記載</TextLink>
          </Link>
        </div>
        <div tw="flex justify-center gap-2 my-6">
          <SNSIcon sns={SNS['instagram']} />
          <SNSIcon sns={SNS['line']} />
        </div>
        <div tw="my-6 flex justify-center">
          <img src="/logo_yamato.svg" height={24} />
        </div>
        <p tw="text-10px flex items-center justify-center">© Yamato Transport Co., Ltd. All Rights Reserved.</p>
      </div>
    </footer>
  )
}

const FooterText = tw.p`text-14px text-center leading-tight`

const TextLink = tw.p`
whitespace-nowrap
  text-11px
  underline
  leading-none
  cursor-pointer
`
const TextTap = tw.span`
underline
cursor-pointer
`
