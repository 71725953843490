import { Buyer } from 'src/generated/graphql'
import { Seller } from 'src/generated/graphql'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isDefinedBuyer(user: any): user is Buyer {
  return user !== undefined && (user as Buyer).__typename === 'Buyer'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isDefinedSeller(user: any): user is Seller {
  return user !== undefined && (user as Seller).__typename === 'Seller'
}
