import tw from 'twin.macro'
import React from 'react'

import { Portal } from 'src/components/Portal/Portal'
import { URL } from 'src/consts/URLs'

export interface Props {
  onModalClose?: () => void
}
export function InquiryModal({ onModalClose }: Props) {
  return (
    <Portal>
      <Overlay>
        <div tw="relative flex flex-col w-327px rounded-ml bg-white overflow-y-auto max-h-full">
          <div tw="mt-10 text-14px line-height[22.4px] mb-10 text-black">
            <div tw="flex flex-col justify-center items-center">
              <img src="/icon_inquiry_modal.svg" />
              <p tw="text-18px line-height[25px] font-bold mt-6px color[#203A45]">お問い合わせ</p>
            </div>
            <div tw="flex flex-col items-center justify-center mx-6 mt-4 text-12px line-height[22px] font-normal color[#1D1D1D]">
              <p>不明点やお困りごとがありましたら、</p>
              <p>お気軽にお問い合わせください。</p>
            </div>
            <div tw="flex flex-col items-center mt-4 text-justify font-normal mx-6">
              <TitleText>電話でのお問い合わせはこちら</TitleText>
              <a href={URL.TAP_CONTACT_WITH_PHONE}>
                <p tw="text-28px line-height[45px] font-weight[900] color[#125133] mt-5px underline flex flex-row items-center">
                  03-4564-2161
                  <img src="/icon_phone_inquiries.png" tw="ml-1 w-15px h-15px" />
                </p>
              </a>
              <Remark tw="mt-2">受付時間：9:00-18:00（土日祝含む）</Remark>
              <TitleText tw="mt-8">Webからのお問い合わせはこちら</TitleText>
              <a href={URL.MAILER_INQUIRY_FORM}>
                <p tw="text-green-dark text-22px font-black leading-9 underline cursor-pointer flex flex-row justify-center my-2 h-35px">
                  お問い合わせフォーム
                  <img src="/icon_inquriy_vector.svg" tw="ml-6px w-15px h-15px mt-10px" />
                </p>
              </a>
              <Remark tw="mt-3px">受付時間：24時間</Remark>
            </div>
            <div tw="width[264px] h-1px mt-8 mx-auto background[#E2E2E2] border-radius[0px 0px 8px 8px]"></div>
            <p tw="text-12px line-height[22px] color[#1D1D1D] text-center mt-8">メールでも24時間受け付けています。</p>
            <a href={URL.TAP_CONTACT_WITH_EMAIL}>
              <p tw="mt-4 text-10px line-height[22px] color[#1D1D1D] text-center">
                MAIL：
                <span tw="underline cursor-pointer ml-1">yfm0301@kuronekoyamato.co.jp</span>
              </p>
            </a>
            <div tw="flex justify-center mt-35px mb-10">
              <a tw="" href={URL.FREQUENTLY_ASKED_QUESTIONS}>
                <p tw="text-12px line-height[12px] font-normal underline cursor-pointer whitespace-nowrap">
                  よくあるご質問（FAQ）
                </p>
              </a>
              <img src="/icon_external_link_black.svg" tw="self-end ml-1" />
            </div>
            <div
              onClick={onModalClose}
              tw="h-10 flex flex-auto justify-center items-center border[1px solid #1D1D1D] rounded-full margin-left[64px] margin-right[64px] leading-4 cursor-pointer"
            >
              <p tw="text-12px line-height[14px] font-bold color[#161722]">閉じる</p>
            </div>
          </div>
        </div>
      </Overlay>
    </Portal>
  )
}

const Overlay = tw.div`fixed top-0 left-0 flex justify-center items-center w-full h-full z-index[11] bg-black bg-opacity-75 px-6`
const TitleText = tw.p`text-12px font-bold line-height[22px] text-center text-blackBorder`
const Remark = tw.div`width[230px] h-20px items-center background[#F3F0E1] border-radius[3px] mx-auto text-10px text-center font-bold color[#125133]`
