import { createContext, useContext, useEffect, useReducer } from 'react'
import { storageKey } from 'src/consts/storageKey'
import { UserType } from 'src/generated/graphql'
import useLocalStorage from 'src/hooks/useLocalStorage'

const HistoryItemCount = 20
export type HistoryItem = {
  time: number
  userId: string
  role: UserType
  productId: string
}
export type UserHistory = {
  userId: string
  role: UserType
  userHistory: HistoryItem[]
}
interface State {
  browsingHistory: UserHistory[]
}
type Action =
  | {
      type: 'add'
      payload: { item: HistoryItem }
    }
  | {
      type: 'clear'
    }

function historyReducer(state: State, action: Action) {
  const { browsingHistory } = state
  switch (action.type) {
    case 'add': {
      const inputProduct = action.payload.item
      const sameUserIdAndRole = browsingHistory.find(
        (item: UserHistory) => item.userId === inputProduct.userId && item.role === inputProduct.role
      )

      if (sameUserIdAndRole) {
        return {
          browsingHistory: browsingHistory.map((item: UserHistory) => {
            if (item.userId === inputProduct.userId) {
              const filterItems = item.userHistory.filter((history) => history.productId !== inputProduct.productId)
              if (filterItems.length >= HistoryItemCount) {
                const differ = filterItems.length - HistoryItemCount + 1
                filterItems.splice(0, differ)
              }
              return {
                ...item,
                userHistory: [...filterItems, inputProduct],
              }
            }
            return item
          }),
        }
      } else {
        const newHistory = {
          userId: inputProduct.userId,
          role: inputProduct.role,
          userHistory: [inputProduct],
        }
        return {
          browsingHistory: [...browsingHistory, newHistory],
        }
      }
    }
    case 'clear': {
      return {
        browsingHistory: [],
      }
    }
  }
}

type Dispatch = (action: Action) => void
export const BrowseStateContext = createContext<State | undefined>(undefined)
export const BrowseDispatchContext = createContext<Dispatch | undefined>(undefined)

export function BrowsingHistoryProvider({ children }: { children: React.ReactNode }) {
  const [storedValue, setValue] = useLocalStorage(storageKey.LOCAL_BROWSE_HISTORY, [])
  const [state, dispatch] = useReducer(historyReducer, {
    browsingHistory: storedValue,
  })

  useEffect(() => {
    // @ts-expect-error TS2345
    state && state.browsingHistory && setValue(state.browsingHistory)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]) //Dont include setValue as dependency as it would create an infinite loop
  return (
    <BrowseStateContext.Provider value={state}>
      <BrowseDispatchContext.Provider value={dispatch}>{children}</BrowseDispatchContext.Provider>
    </BrowseStateContext.Provider>
  )
}

export const useBrowseDispatch = () => {
  const context = useContext(BrowseDispatchContext)
  if (context === undefined) {
    throw new Error('useBrowseDispatch must be used within a BrowsingHistoryProvider')
  }
  return context
}

export const useBrowseState = () => {
  const context = useContext(BrowseStateContext)
  if (context === undefined) {
    throw new Error('useBrowseDispatch must be used within a BrowsingHistoryProvider')
  }
  return context
}
