import tw from 'twin.macro'
import React, { useEffect, useRef } from 'react'

import { Portal } from 'src/components/Portal/Portal'
// @ts-expect-error TS6133
import { URL } from 'src/consts/URLs'
// @ts-expect-error TS6133
import { STATUS_SUPPORT_TEL } from 'src/consts/service'

export interface Props {
  onModalClose?: () => void
}
export function YcfUnusableStatusModal({ onModalClose }: Props) {
  const rootDivRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const refDiv = rootDivRef?.current
    const eventListener = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
    }
    refDiv?.addEventListener('touchmove', eventListener)

    refDiv?.addEventListener('wheel', eventListener)
    return () => {
      refDiv?.removeEventListener('touchmove', eventListener)
      refDiv?.removeEventListener('wheel', eventListener)
    }
  }, [])

  return (
    <Portal>
      <Overlay ref={rootDivRef}>
        <div tw="relative flex flex-col max-w-lg rounded-ml bg-white w-full">
          <div tw="mt-14 text-14px line-height[22.4px] my-8 text-black">
            <div tw="flex flex-col justify-center items-center">
              <img src="/icon_ycf_modal_Unavailable.svg" />
              <p tw="text-18px line-height[25px] font-bold text-red mt-1">サービスをご利用いただけません</p>
            </div>
            <div tw="flex flex-row items-center justify-center mx-6 ">
              <img tw="mt-6 mx-10" src="/icon_judging_modal_kurokake.svg" />
            </div>
            <div tw="mt-6 mb-8 text-justify font-normal mx-6">
              <p>
                お申し込みの決済サービス「クロネコ掛け払い」の審査は、総合的に検討した結果、承認を見送らせて頂きます。
              </p>
              <p tw="mt-2">
                なお、審査内容の詳細につきましては開示しておらず、お問い合わせを頂きましてもお答えすることが出来ません。
              </p>
            </div>
            <div
              onClick={onModalClose}
              tw="h-11 flex flex-auto justify-center items-center border border-solid border-black rounded-full mx-52px leading-4 cursor-pointer"
            >
              <p tw="">閉じる</p>
            </div>
          </div>
        </div>
      </Overlay>
    </Portal>
  )
}

const Overlay = tw.div`fixed top-0 left-0 flex justify-center items-center w-full h-full z-index[11] bg-black bg-opacity-75 px-6`
