import tw from 'twin.macro'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useMemo, useState, useCallback, useContext } from 'react'
import { Route } from 'src/consts/route'
import { useCurrentUser } from 'src/contexts/currentUser'
import { UserType } from 'src/generated/graphql'
import { useRouter } from 'next/router'
import { useUiDispatch } from 'src/contexts/ui'
import { useCartState } from 'src/contexts/cart'
import { ProductSearchBar } from 'src/components/ProductSearchBar/ProductSearchBar'
import { routePath } from 'src/util/routePath'
import { ButtonType, BUYER_NAV_MENU, SELLER_NAV_MENU } from 'src/consts/navMenuItems'
import { throttle } from 'lodash'
import { useImmerReducer } from 'use-immer'
import { TabBar } from 'src/components/TabBar/TabBar'
// @ts-expect-error TS6192
import { SearchFormValues, SearchModel } from 'src/components/SearchModel/SearchModel'
import { envNames } from 'src/consts/envTypeSetting'
import { ProductInfoContext } from 'src/contexts/commonContext'
import { useBuyable } from 'src/hooks/useBuyable'

export default function Navbar({}) {
  const [activeTab, setActiveTab] = useState<ButtonType>(ButtonType.None)
  const { role, isOnboarded } = useCurrentUser()
  const { showRole } = useCurrentUser()
  const showSeller = showRole === UserType.Buyer
  const { isAuthenticated } = useAuth0()
  const { pathname, push, query } = useRouter()
  const { currentCartItems } = useCartState()
  const isSeller = role === UserType.Seller
  const isBuyer = role === UserType.Buyer
  const uiDispatch = useUiDispatch()
  const { loginWithRedirect } = useAuth0()
  // @ts-expect-error TS6133
  const [showSearchModel, setShowSearchModel] = useState(false)
  // @ts-expect-error TS6133
  const [lastActiveTab, setLastActiveTab] = useState<ButtonType>(activeTab)
  const [
    // @ts-expect-error TS6133
    productInfo,
    // @ts-expect-error TS6133
    setProductInfo,
    purchaseStatus,
    ,
    ,
    ,
    ,
    setDisplayBackTop,
  ] = useContext(ProductInfoContext)
  const buyable = useBuyable()

  useEffect(() => {
    if (isSeller && !showSeller) {
      switch (pathname) {
        case Route.sellerHome:
          setActiveTab(ButtonType.Home)
          break
        case Route.sellerOrderHistory:
          setActiveTab(ButtonType.History)
          break
        case Route.sellerSetting:
          setActiveTab(ButtonType.Setting)
          break
        default:
          setActiveTab(ButtonType.None)
          break
      }
    } else {
      switch (pathname) {
        case Route.productSearch:
          setActiveTab(ButtonType.Home)
          break
        case Route.cart:
          setActiveTab(ButtonType.Cart)
          break
        case Route.orderHistory:
          setActiveTab(ButtonType.History)
          break
        case Route.setting:
          setActiveTab(ButtonType.Setting)
          break
        case Route.favorite:
          setActiveTab(ButtonType.Favorite)
          break
        default:
          setActiveTab(ButtonType.None)
          break
      }
    }
  }, [pathname, isSeller, showSeller])

  const handleHomeLink = () => {
    if (
      pathname === Route.onboarding ||
      pathname === Route.verifyEmail ||
      pathname === Route.sellerOnboarding ||
      pathname === Route.landing ||
      pathname === Route.ycfJudge
    ) {
      return
    }

    if (isBuyer || showSeller) {
      push(Route.productSearch)
    } else if (isSeller) {
      push(Route.sellerHome)
    }
  }

  const logoCenter = useMemo(() => {
    if (
      pathname === Route.onboarding ||
      pathname === Route.verifyEmail ||
      pathname === Route.sellerOnboarding ||
      pathname === Route.ycfJudge
    ) {
      return true
    }
    return false
  }, [pathname])

  const isLandingPage = pathname === Route.landing
  const isCampaignLanding = pathname === Route.campaignLanding
  const hideNavMenu = useMemo(() => {
    if (isLandingPage || isCampaignLanding) return false

    if (!role) {
      return false
    }

    if (!isAuthenticated) return false

    if (
      Route.onboarding === pathname ||
      Route.sellerOnboarding === pathname ||
      Route.verifyEmail === pathname ||
      Route.sendVerificationEmail === pathname ||
      Route.ycfJudge === pathname
    ) {
      return false
    }

    const hideMenuIsOnboardedPathname: string[] = [
      Route.sellerScta,
      Route.scta,
      Route.sellerTermsAndConitions,
      Route.termsAndConitions,
    ]

    if (!isOnboarded && hideMenuIsOnboardedPathname.includes(pathname)) {
      return false
    }

    return true
  }, [isAuthenticated, isOnboarded, role, pathname, isLandingPage, isCampaignLanding])

  const handleLogin = useCallback(() => {
    uiDispatch({
      type: 'showLoginModal',
      payload: {
        onBuyerButtonClicked: () => {
          loginWithRedirect({
            role: UserType.Buyer,
            type: 'login',
          })
        },
        onSellerButtonClicked: () => {
          loginWithRedirect({
            role: UserType.Seller,
            type: 'login',
          })
        },
      },
    })
  }, [uiDispatch, loginWithRedirect])

  const keywordInitialValues = (query.keyword ?? '') as string

  const [changeKeyword, setChangeKeyword] = useState<boolean>(false)

  const setChangeKeywordFalse = () => {
    setChangeKeyword(false)
  }

  useEffect(() => {
    setChangeKeyword(true)
  }, [pathname, query])

  const search = (values: { [key: string]: string }) => {
    if (values.keyword === '' || values.keyword === undefined) delete values.keyword
    const category = (query.category ?? '') as string
    const categoryId = (query.categoryId ?? '') as string
    const productCategory = (query.productCategory ?? '') as string
    if (pathname === Route.productSearchResult) {
      let queryValues = { ...values }
      queryValues = {
        category: category,
        categoryId: categoryId,
        keyword: queryValues.keyword,
      }
      if (queryValues.category === '') delete queryValues.category
      if (queryValues.categoryId === '') delete queryValues.categoryId
      if (queryValues.keyword === '' || queryValues.keyword === undefined) delete queryValues.keyword
      if (Object.keys(queryValues).length) {
        if (productCategory) {
          queryValues.productCategory = productCategory
        }
        push({
          pathname: routePath(Route.productSearchResult),
          query: queryValues,
        })
      } else {
        push({
          pathname: routePath(Route.productSearch),
        })
      }
    } else if (pathname === Route.productSearchDelivery) {
      push({
        pathname: routePath(Route.productSearchDelivery),
        query: {
          deliveryDates: query.deliveryDates,
          keyword: values.keyword,
          category: category,
          categoryId: categoryId,
        },
      })
    } else if (pathname === Route.productSearchDeliveryResult) {
      push({
        pathname: routePath(Route.productSearchDeliveryResult),
        query: {
          deliveryDate: query.deliveryDate,
          keyword: values.keyword,
          category: category,
          categoryId: categoryId,
        },
      })
    } else if (Object.keys(values).length) {
      const queryValues = { ...values }
      push({
        pathname: routePath(Route.productSearchResult),
        query: queryValues,
      })
    }
  }

  const [btnArrsImmer, dispatch] = useImmerReducer(
    (draft, action) => {
      switch (action.type) {
        case 'resetForSeller':
          return SELLER_NAV_MENU
        case 'resetForBuyer':
          return BUYER_NAV_MENU
        case 'setCartCount':
          return void draft.map((o) => {
            if (o.type === ButtonType.Cart) {
              o.cartCount = currentCartItems.length
            }
          })
        case 'setShowSeller':
          return void draft.map((o) => {
            if (
              showSeller &&
              (o.type === ButtonType.Cart ||
                o.type === ButtonType.Favorite ||
                o.type === ButtonType.History ||
                o.type === ButtonType.Setting)
            ) {
              o.disable = true
            }
          })
        case 'setActive':
          //タブボタンの状態を切り替える
          return void draft.map((o) => {
            if (o.type === activeTab) {
              o.active = true
            } else {
              o.active = false
            }
          })
      }
    },
    showSeller || isBuyer ? BUYER_NAV_MENU : SELLER_NAV_MENU
  )

  useEffect(() => {
    if (showSeller || isBuyer) {
      dispatch({ type: 'resetForBuyer' })
    } else {
      dispatch({ type: 'resetForSeller' })
    }
    dispatch({ type: 'setShowSeller' })
  }, [showSeller, isBuyer, dispatch])

  useEffect(() => {
    dispatch({ type: 'setCartCount' })
  }, [currentCartItems.length, showSeller, dispatch])

  useEffect(() => {
    dispatch({ type: 'setActive' })
  }, [activeTab, showSeller, dispatch])

  // @ts-expect-error TS6133
  const buyerNavClicked = (type: ButtonType, isActive: boolean) => {
    setActiveTab(type)
    switch (type) {
      case ButtonType.Home:
        push(Route.productSearch)
        break
      case ButtonType.Favorite:
        push(Route.favorite)
        break
      case ButtonType.History:
        push(Route.orderHistory)
        break
      case ButtonType.Cart:
        push(Route.cart)
        break
      case ButtonType.Setting:
        push(Route.setting)
        break
      default:
        break
    }
  }

  // @ts-expect-error TS6133
  const sellerNavClicked = (type: ButtonType, isActive: boolean) => {
    setActiveTab(type)
    switch (type) {
      case ButtonType.Home:
        push(Route.sellerHome)
        break
      case ButtonType.History:
        push(Route.sellerOrderHistory)
        break
      case ButtonType.Setting:
        push(Route.sellerSetting)
        break
      default:
        break
    }
  }

  const navClickedThrottle = throttle(showSeller || isBuyer ? buyerNavClicked : sellerNavClicked, 500, {
    leading: true,
    trailing: false,
  })

  // const initialSearchValues: SearchFormValues = {
  //   keyword: '',
  //   categoryId: [],
  //   deliveryDates: [],
  // }

  // const searchSubmit = (values: SearchFormValues) => {
  //   if (values.deliveryDates && values.deliveryDates.length > 0) {
  //     push({
  //       pathname: routePath(Route.productSearchDelivery),
  //       query: {
  //         deliveryDates: values.deliveryDates.join('='),
  //         keyword: values.keyword,
  //         categoryId: values.categoryId.join(','),
  //       },
  //     })
  //   } else {
  //     push({
  //       pathname: routePath(Route.productSearchResult),
  //       query: {
  //         keyword: values.keyword,
  //         categoryId: values.categoryId.join(','),
  //       },
  //     })
  //   }
  //   setShowSearchModel(false)
  //   setActiveTab(lastActiveTab)
  // }

  const showSearchBar = useMemo(() => {
    if (pathname === Route.cart && purchaseStatus) {
      return false
    }
    return true
  }, [pathname, purchaseStatus])

  useEffect(() => {
    setDisplayBackTop(!showSearchModel)
  }, [setDisplayBackTop, showSearchModel])

  const processEnv = process.env.OPT_ENV

  return (
    <nav tw="w-full bg-background2">
      {processEnv && envNames[processEnv] && (
        <div tw="h-6 text-center text-white text-14px" css={[envNames[processEnv].cssParam]}>
          {envNames[processEnv].title}
        </div>
      )}
      <div
        tw="w-full landing-pc:max-w-1025px landing-pc:h-16 h-60px m-auto flex flex-row justify-between items-center"
        css={[isLandingPage && 'max-width: 1025px;', logoCenter ? tw`justify-center` : tw`justify-between`]}
      >
        <div
          tw="h-full ml-4 landing-pc:ml-3 flex justify-center cursor-pointer py-3 landing-pc:py-10px"
          onClick={handleHomeLink}
          css={[
            logoCenter
              ? tw`ml-0 landing-pc:ml-0 py-2 landing-pc:py-2 cursor-default`
              : tw`ml-4 landing-pc:ml-3 py-3 landing-pc:py-10px cursor-pointer`,
          ]}
        >
          <img src="/header_logo.svg" tw="h-full w-auto object-cover" />
        </div>
        {isLandingPage && (
          <div tw="flex items-center px-5px">
            <a tw="bg-white rounded-lg px-3 py-4 leading-none text-14px mr-1 cursor-pointer" onClick={handleLogin}>
              ログイン
            </a>
            <a tw="bg-orange text-white rounded-lg px-3 py-4 leading-none text-14px cursor-pointer" href="#signUp">
              新規登録
            </a>
          </div>
        )}
        {hideNavMenu && (isBuyer || showSeller) && showSearchBar && (
          <div tw="pl-3 landing-pc:w-464px mr-4" css={'width:calc(100vw - 95px)'}>
            <ProductSearchBar
              searchKeyword={keywordInitialValues}
              changeKeyword={changeKeyword}
              setChangeKeyword={setChangeKeywordFalse}
              search={(values) => search(values)}
            />
          </div>
        )}
        {hideNavMenu && (
          <div tw="h-full flex-row flex-nowrap" css={[showSeller || isBuyer ? tw`hidden landing-pc:flex` : tw`flex`]}>
            <TabBar buttons={btnArrsImmer} cssParams={[tw`space-x-4 pt-2`]} clickAction={navClickedThrottle} />
            {/* {showSearchModel && (
              <SearchModel
                initialValues={initialSearchValues}
                onModalClosed={() => {
                  setShowSearchModel(false)
                  setActiveTab(lastActiveTab)
                }}
                onSubmit={searchSubmit}
              ></SearchModel>
            )} */}
          </div>
        )}
      </div>
      {!buyable && isLandingPage && (
        <div tw="py-4">
          <div tw="border-red-landing border bg-white py-3 landing-pc:(max-w-1025px mx-auto text-center) text-left mx-6 px-4 rounded-lg ">
            <p tw="text-14px line-height[22px] text-red-landing">
              年末年始の休業期間のため、2023年12月29日（金）17時～2024年1月4日（木）13時は、飲食店様は注文ができません。
            </p>
          </div>
        </div>
      )}
    </nav>
  )
}
