import { Route } from './route'
import { URL } from 'src/consts/URLs'

export interface leftMenuProps {
  href: string
  icon: string
  disableIcon: string
  disableArrowIcon: string
  title: string
  arrowIcon: string
  disable: boolean
}

export const leftMemuItems: leftMenuProps[] = [
  {
    href: Route.orderHistory,
    icon: '/icon_truck_active.svg',
    disableIcon: '/icon_truck_inactive.svg',
    disableArrowIcon: '/icon_right_arrow_inactive.svg',
    title: '購入履歴',
    arrowIcon: '/icon_right_arrow.svg',
    disable: false,
  },
  {
    href: Route.setting,
    icon: '/icon_account_active.svg',
    disableIcon: '/icon_account_inactive.svg',
    disableArrowIcon: '/icon_right_arrow_inactive.svg',
    title: '設定',
    arrowIcon: '/icon_right_arrow.svg',
    disable: false,
  },
  {
    href: Route.coupon,
    icon: '/icon_coupon_active.svg',
    disableIcon: '/icon_coupon_inactive.svg',
    disableArrowIcon: '/icon_right_arrow_inactive.svg',
    title: 'クーポン',
    arrowIcon: '/icon_right_arrow.svg',
    disable: false,
  },
  {
    href: URL.FREQUENTLY_ASKED_QUESTIONS,
    icon: '/icon_FAQ_passive.svg',
    disableIcon: '/icon_FAQ_passive.svg',
    disableArrowIcon: '/icon_faq_link.svg',
    title: 'よくあるご質問はこちら',
    arrowIcon: '/icon_faq_link.svg',
    disable: false,
  },
]
