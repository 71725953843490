import tw from 'twin.macro'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { ParsedUrlQuery } from 'querystring'
import { Fragment, useEffect, useMemo, useState, useContext } from 'react'
import { Route, RouteValue, LEVEL_TWO_ROUTE } from 'src/consts/route'
import { routePath } from 'src/util/routePath'
import { useCurrentUser } from 'src/contexts/currentUser'
import { UserType, ProductDetailsQuery, SubCategoriesQuery, SubCategoriesDocument } from 'src/generated/graphql'
import { useQuery } from 'urql'
import { SPECIAL_FEATURE } from 'src/consts/specialFeature'
import { SpecialType } from 'src/consts/specialType'
import { PRODUCT_CATEGORY_MAP } from 'src/consts/breadcrumbPathMap'
import { ProductInfoContext } from 'src/contexts/commonContext'

interface BreadcrumbHistory {
  pathname: string
  query: ParsedUrlQuery
  asPath: string
}

export function Breadcrumb({}) {
  const router = useRouter()

  const { role, isOnboarded } = useCurrentUser()

  const { showRole } = useCurrentUser()

  const [currentRouter, setCurrentRouter] = useState<BreadcrumbHistory | null>(null)
  const [prevRouter, setPrevRouter] = useState<BreadcrumbHistory | null>(null)

  const [productInfo] = useContext(ProductInfoContext)
  const [subCategoriesRes] = useQuery({
    query: SubCategoriesDocument,
    variables: { parentCategoryId: null },
    pause: !isOnboarded,
  })
  const subCategoriesInfo = subCategoriesRes.data?.subCategories
  const breadcrumb = useMemo(() => {
    return _breadcrumb(
      showRole,
      router.pathname as RouteValue,
      router.query,
      role,
      prevRouter,
      productInfo,
      subCategoriesInfo
    )
  }, [role, router.pathname, router.query, prevRouter, productInfo, subCategoriesInfo, showRole])

  useEffect(() => {
    if (router.asPath !== currentRouter?.asPath) {
      if (currentRouter) {
        setPrevRouter({
          pathname: currentRouter.pathname,
          query: currentRouter.query,
          asPath: currentRouter.asPath,
        })
      }
      setCurrentRouter({
        pathname: router.pathname,
        query: router.query,
        asPath: router.asPath,
      })
    }
  }, [router, currentRouter])

  if (breadcrumb.length === 0) return null

  return (
    <div tw="w-full bg-background2">
      <div tw="w-full landing-pc:max-w-1025px mx-auto h-44px p-4 bg-background2 flex items-center flex-shrink-0 relative">
        {breadcrumb.map((b, index) => (
          <Fragment key={index}>
            <Link href={b.path || router.asPath}>
              <p
                tw="text-gray-caption text-10px mr-2"
                css={[breadcrumb[index + 1] ? tw`text-gray-caption` : tw`text-black`]}
              >
                {b.name}
              </p>
            </Link>
            {breadcrumb[index + 1] && <img src="/icon-chevron-right-black.svg" tw="mr-2" />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

type IBreadcrumb = {
  [key in RouteValue]: Array<{
    name: string
    path?: string
  }>
}
const getSearchResultName = (query: ParsedUrlQuery) => {
  if (query.category) return query.category as string

  if (query.productCategory) return PRODUCT_CATEGORY_MAP[query.productCategory as string]
}

const _breadcrumb = (
  showRole: string | null | undefined,
  pathname: RouteValue,
  query: ParsedUrlQuery,
  role: UserType | undefined | null,
  preRouterHistory: BreadcrumbHistory | null | undefined,
  productInfo: ProductDetailsQuery['productDetails'] | null | undefined,
  subCategoriesInfo: SubCategoriesQuery['subCategories'] | null | undefined
) => {
  const isBuyer = role === UserType.Buyer

  const showSeller = showRole === UserType.Buyer

  const specialType: SpecialType = query.type as SpecialType

  const level2Link = { name: '', path: '' }

  // 商品詳細画面
  if (pathname === Route.productDetail) {
    // 第二階層である場合
    if (preRouterHistory && LEVEL_TWO_ROUTE.includes(preRouterHistory.pathname)) {
      const level2Breadcrumb = _breadcrumb(
        showRole,
        preRouterHistory.pathname as RouteValue,
        preRouterHistory.query,
        role,
        null,
        null,
        null
      )
      // 第二階層のパンクズ
      level2Link.name = level2Breadcrumb.length >= 2 ? level2Breadcrumb[level2Breadcrumb.length - 1].name : ''
      level2Link.path = preRouterHistory.asPath
    } else {
      if (productInfo && subCategoriesInfo) {
        // 中カテゴリーの場合
        if (productInfo?.category?.id) {
          const subCategoryInfo = subCategoriesInfo.find((subCategory) =>
            productInfo.subCategories.find((value) => value.id === subCategory.id)
          )
          if (subCategoryInfo) {
            level2Link.name = subCategoryInfo.name
            level2Link.path = `${Route.productSearchResultOld}/?category=${level2Link.name}&categoryId=${subCategoryInfo.id}&parentCategoryId=${subCategoryInfo.parentCategoryId}`
          }
        } else {
          // その他の場合
          level2Link.name = 'すべての食材（新着順）'
          level2Link.path = `${Route.productSearchResultOld}/?productCategory=Latest`
        }
      }
    }
  }

  const BREADCRUMB: IBreadcrumb = {
    // Common
    '/': [],
    '/product/[id]':
      showSeller || isBuyer
        ? [
            {
              name: 'ホーム',
              path: Route.productSearch,
            },
            level2Link,
            { name: '商品詳細' },
          ]
        : [
            // this should be divided into seller and buyer
            {
              name: '商品一覧',
              path: Route.sellerHome,
            },
            { name: '商品詳細' },
          ],
    // Buyer
    '/order/history': [{ name: '購入履歴' }],
    '/product/search': [{ name: 'ホーム' }],
    '/product/delivery-date': [{ name: 'ホーム', path: Route.productSearch }, { name: '検索結果' }],
    '/product/search/result/DeliveryDateResult': [{ name: 'ホーム', path: Route.productSearch }, { name: '検索結果' }],
    '/product/search/resultOld': [
      { name: 'ホーム', path: Route.productSearch },
      { name: getSearchResultName(query) ?? '検索結果' },
    ],
    // @ts-expect-error TS2322
    '/product/meat': [{ name: 'ホーム', path: Route.productSearch }, { name: '特集『🍖精肉🥩』' }],
    '/product/asparagus': [{ name: 'ホーム', path: Route.productSearch }, { name: '特集『アスパラ』' }],
    '/product/chicken': [{ name: 'ホーム', path: Route.productSearch }, { name: '特集『地鶏』' }],
    '/product/special/[type]': [
      { name: 'ホーム', path: Route.productSearch },
      { name: SPECIAL_FEATURE[specialType]?.breadcrumbName },
    ],

    '/cart': [{ name: 'カート' }],
    '/product/favorite': [{ name: 'お気に入り' }],
    '/setting': [{ name: '設定' }],
    '/terms-and-conditions': [{ name: '利用規約' }],
    '/scta': [{ name: '特定商品取引法に基づく表記' }],
    // Seller
    '/seller/home': [{ name: '商品一覧' }],
    '/seller/product/new': [{ name: '商品新規登録' }],
    '/seller/product/edit-draft-product': [{ name: '商品新規登録' }],
    '/seller/product/[id]': [{ name: '商品一覧', path: Route.sellerHome }, { name: '登録済み商品詳細' }],
    '/seller/product/[id]/edit': [
      { name: '商品一覧', path: Route.sellerHome },
      {
        name: '登録済み商品詳細',
        path: routePath(Route.sellerProductDetail, query),
      },
      { name: '商品情報編集' },
    ],
    '/seller/order/history': [{ name: '受注履歴' }],
    '/seller/setting': [{ name: '設定' }],
    '/seller/terms-and-conditions': [{ name: '利用規約' }],
    '/seller/scta': [{ name: '特定商品取引法に基づく表記' }],
    '/seller/setting/edit/business': [{ name: '設定', path: Route.sellerSetting }, { name: 'アカウント設定' }],
    '/seller/setting/edit/bank': [{ name: '設定', path: Route.sellerSetting }, { name: '口座情報' }],
    '/seller/setting/edit/pr': [{ name: '設定', path: Route.sellerSetting }, { name: '生産者PR情報' }],
    '/seller/setting/edit/businessType': [{ name: '設定', path: Route.sellerSetting }, { name: '事業者区分' }],
  }

  return BREADCRUMB[pathname] || []
}
