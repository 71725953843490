import { ProductInfoContext } from 'src/contexts/commonContext'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { GetYcfJudgeStatusDocument, UserType, YcfJudgeStatus, YcfUsageStatus } from 'src/generated/graphql'
import { useCurrentUser } from 'src/contexts/currentUser'
import tw from 'twin.macro'
import { useUiDispatch } from 'src/contexts/ui'
import { JUDGE_STATUS, STATUS_UNAVAILABLE_TITLE } from 'src/consts/judgeStatus'
import useLocalStorage from 'src/hooks/useLocalStorage'
import { storageKey } from 'src/consts/storageKey'
import { useRouter } from 'next/router'
import { Route } from 'src/consts/route'
// @ts-expect-error TS6133
import { getDaysFromToday } from 'src/util/date'

interface Props {
  setHeaderHeight: (height: number) => void
}

export function Header({ setHeaderHeight }: Props) {
  // global　status
  // @ts-expect-error TS6133
  const [productIno, setProductInfo, purchaseStatus, setPurchaseStatus, ycfJudgeStatus, setYcfJudgeStatus] = useContext(
    ProductInfoContext
  )
  const router = useRouter()
  const { role, isOnboarded } = useCurrentUser()

  //会員登録完了画面で非表示
  const ycfJudge = Route.ycfJudge === router.pathname
  //審査状況表示条件: 対象需要家 && isOnboarded
  const isBuyer = role === UserType.Buyer && isOnboarded && !ycfJudge

  const uiDispatch = useUiDispatch()

  //×を押したら消えるようにしたい
  //エクスプローラlocalでstatusを保存
  const [storedValue, setValue] = useLocalStorage(storageKey.LOCAL_JUDGING_STATUS, 'true')

  const productSearchPath = router.pathname === Route.productSearch
  //ルートを更新するときにAPIを呼び出す
  const [res, getYcfJudgeStatusRequest] = useQuery({
    query: GetYcfJudgeStatusDocument,
    pause: !isBuyer,
  })

  useEffect(() => {
    if (isBuyer && productSearchPath) {
      getYcfJudgeStatusRequest()
    }
  }, [getYcfJudgeStatusRequest, isBuyer, productSearchPath])

  const getYcfJudgeStatus = useMemo(() => res.data?.getYcfJudgeStatus, [res.data?.getYcfJudgeStatus])
  //審査状況ステータス
  const ycfStatus = useMemo(() => getYcfJudgeStatus?.ycfJudgeStatus, [getYcfJudgeStatus?.ycfJudgeStatus])
  //利用状況ステータス
  const ycfUsageStatus = useMemo(() => getYcfJudgeStatus?.ycfUsageStatus, [getYcfJudgeStatus?.ycfUsageStatus])

  //　審査状況表示内容
  const judgeStatusText = useMemo(() => {
    if (!ycfStatus) return ''
    if (ycfStatus === null || ycfStatus === YcfJudgeStatus.Judging)
      //審査中
      return JUDGE_STATUS[YcfJudgeStatus.Judging]
    //　審査中・利用不可
    if (ycfStatus === YcfJudgeStatus.Unavailable) return JUDGE_STATUS[YcfJudgeStatus.Unavailable]
    // 審査完了　利用停止->利用停止
    if (ycfUsageStatus === YcfUsageStatus.Suspend) return STATUS_UNAVAILABLE_TITLE
    // 解約->非表示
    if (ycfUsageStatus === YcfUsageStatus.Surrendered) return ''
    //利用可　+　上記以外
    if (storedValue === 'false') return ''
    if (ycfStatus && getYcfJudgeStatus?.ycfJudgeUpdated && getYcfJudgeStatus?.now) {
      //日付＝7日以内：非表示。
      if (
        new Date(getYcfJudgeStatus?.ycfJudgeUpdated).getTime() + 7 * 24 * 60 * 60 * 1000 >
        new Date(getYcfJudgeStatus?.now).getTime()
      ) {
        //日付＝7日以内:審査完了
        return JUDGE_STATUS[YcfJudgeStatus.Available]
      } else {
        //7日超える
        return ''
      }
    }
  }, [getYcfJudgeStatus?.now, getYcfJudgeStatus?.ycfJudgeUpdated, storedValue, ycfStatus, ycfUsageStatus])

  //color
  const ycfJudgeStatusSelect = useMemo(() => {
    if (judgeStatusText === JUDGE_STATUS[YcfJudgeStatus.Available]) {
      return tw`bg-green-instruction`
    } else if (
      judgeStatusText === JUDGE_STATUS[YcfJudgeStatus.Unavailable] ||
      judgeStatusText === STATUS_UNAVAILABLE_TITLE
    ) {
      return tw`bg-red`
    } else {
      return tw`bg-orange`
    }
  }, [judgeStatusText])

  //スクロール時,縦幅を狭く
  const [isClientHight, setIsClientHight] = useState(false)
  const onWinScrollEvent = () => {
    const windowHight = window.scrollY
    if (windowHight > 0) {
      setIsClientHight(true)
    } else {
      setIsClientHight(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', onWinScrollEvent)
    onWinScrollEvent()
    return () => {
      window.removeEventListener('scroll', onWinScrollEvent)
    }
  })

  // global　status
  useEffect(() => {
    setYcfJudgeStatus(judgeStatusText as string)
    const height =
      isBuyer && judgeStatusText !== ''
        ? judgeStatusText === JUDGE_STATUS[YcfJudgeStatus.Available]
          ? 0
          : isClientHight
          ? 32
          : 56
        : 0
    setHeaderHeight(height)
  }, [isBuyer, isClientHight, judgeStatusText, setHeaderHeight, setYcfJudgeStatus])

  //横幅を押す
  const toYcfStopStatusModal = () => {
    if (judgeStatusText === STATUS_UNAVAILABLE_TITLE) {
      return uiDispatch({
        type: 'showYcfStopStatusModal',
      })
    }
    if (judgeStatusText === JUDGE_STATUS[YcfJudgeStatus.Unavailable]) {
      return uiDispatch({
        type: 'showYcfUnusableStatusModal',
      })
    }
    if (judgeStatusText === JUDGE_STATUS[YcfJudgeStatus.Judging]) {
      return uiDispatch({
        type: 'showYcfJudgingStatusModal',
      })
    }
  }

  return (
    <>
      {isBuyer && judgeStatusText !== '' && (
        <div tw="w-full p-0 m-0" onScroll={() => onWinScrollEvent} onClick={() => toYcfStopStatusModal()}>
          <div
            tw="w-full"
            css={[
              judgeStatusText === JUDGE_STATUS[YcfJudgeStatus.Available] ? tw`h-0` : isClientHight ? tw`h-8` : tw`h-14`,
            ]}
          />
          <div
            tw="w-full left-0 top-0 text-white z-index[10]"
            css={[
              ycfJudgeStatusSelect,
              isClientHight ? tw`h-8` : tw`h-14`,
              judgeStatusText !== JUDGE_STATUS[YcfJudgeStatus.Available] ? tw`fixed` : tw``,
            ]}
          >
            <div
              tw="relative flex flex-auto justify-center items-center text-14px leading-4 font-bold "
              css={[
                isClientHight ? tw`h-8` : tw`h-14`,
                judgeStatusText !== JUDGE_STATUS[YcfJudgeStatus.Available] && tw`cursor-pointer`,
              ]}
            >
              {judgeStatusText === JUDGE_STATUS[YcfJudgeStatus.Available] && (
                <>
                  <img tw="mr-1" src="/icon_header_Available.svg" />
                  <div>{judgeStatusText}</div>
                  <div
                    onClick={(e) => {
                      setValue('false'), e.stopPropagation()
                    }}
                  >
                    <img
                      tw="absolute right-18px landing-pc:right-36px cursor-pointer"
                      css={[isClientHight ? tw`top-10px` : tw`top-22px`]}
                      src="/icon_header_close.svg"
                    />
                  </div>
                </>
              )}
              {judgeStatusText === STATUS_UNAVAILABLE_TITLE && (
                <>
                  <img tw="mr-1" src="/icon_header_Unavailable.svg" />
                  <div>{judgeStatusText}</div>
                </>
              )}
              {judgeStatusText === JUDGE_STATUS[YcfJudgeStatus.Judging] && (
                <>
                  <img tw="mr-1" src="/icon_hearder_Judging.svg" />
                  <div>{judgeStatusText}</div>
                </>
              )}
              {judgeStatusText === JUDGE_STATUS[YcfJudgeStatus.Unavailable] && (
                <>
                  <img tw="mr-1" src="/icon_header_Unavailable.svg" />
                  <div>{judgeStatusText}</div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
