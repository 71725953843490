import { createGlobalStyle } from 'styled-components'
import { Reset } from './Reset'
import { Slick } from './Slick'
import { SlickTheme } from './SlickTheme'
import { Style } from './Style'

export const GuestStyle = createGlobalStyle`
    ${Reset}
    ${Slick}
    ${SlickTheme}
    ${Style}
`
