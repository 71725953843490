import tw from 'twin.macro'
import { Portal } from 'src/components/Portal/Portal'
import { Button } from 'src/components/Button/Button'
import { useAuth0 } from '@auth0/auth0-react'
import { SELLER_TERMS_AND_CONDITIONS_TEXT } from 'src/assets/sellerTnC'
import { BUYER_TERMS_AND_CONDITIONS_TEXT } from 'src/assets/buyerTnC'
import { useCurrentUser } from 'src/contexts/currentUser'
import { NewsAppearanceType, UserType } from 'src/generated/graphql'
import { useMutation, useQuery } from 'urql'
import { MarkNewsReadDocument, NewsListDocument } from 'src/generated/graphql'
import { useCallback, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { storageKey } from 'src/consts/storageKey'
import { useRouter } from 'next/router'
import { Route } from 'src/consts/route'
import { useUiDispatch } from 'src/contexts/ui'

export function TnCChangedModal() {
  const { logout } = useAuth0()
  const router = useRouter()
  const uiDispatch = useUiDispatch()
  const { role, isOnboarded, isAuthenticated } = useCurrentUser()
  const [, markNewsRead] = useMutation(MarkNewsReadDocument)
  const [response] = useQuery({
    query: NewsListDocument,
    pause: !isAuthenticated || !isOnboarded,
  })

  const tnCChangedNews = useMemo(() => {
    return response.data?.newsList?.find(
      (news) => news.appearanceType === NewsAppearanceType.Modal && news.title === '利用規約が変更になりました'
    )
  }, [response.data?.newsList])

  const newsId = useMemo(() => {
    return tnCChangedNews?.id
  }, [tnCChangedNews])

  const [userAgreed, setUserAgreed] = useState(false)
  const isSeller = role === UserType.Seller

  const _onAgreed = useCallback(
    async (newsId: string) => {
      try {
        const res = await markNewsRead({
          id: newsId,
        })

        if (res.error) {
          console.log(res.error)
          uiDispatch({
            type: 'showAlertModal',
            payload: {
              OverlayCss: [tw`z-index[21]`],
              message: 'ネットワークエラーが発生しました。もう一度お試しください。',
              buttonTitle: 'OK',
              onButtonClick: () => {},
            },
          })
        }
        if (res.data?.readNews) {
          //Close the modal
          setUserAgreed(true)
        }
      } catch (error) {
        console.log(error)
        uiDispatch({
          type: 'showAlertModal',
          payload: {
            OverlayCss: [tw`z-index[21]`],
            message: 'エラーが発生しました。画面を再読み込みしてもう一度お試しください。',
            buttonTitle: 'OK',
            onButtonClick: () => {},
          },
        })
      }
    },
    [markNewsRead, uiDispatch]
  )

  const onAgreed = debounce((newsId: string) => {
    _onAgreed(newsId)
  }, 200)

  if (!isAuthenticated || !isOnboarded || !newsId || userAgreed || router.pathname === Route.ycfJudge) {
    return null
  }

  return (
    <Portal>
      <Overlay>
        <div tw="relative  w-full h-4/5 bg-white rounded-lg p-4 pt-0 flex flex-col justify-between">
          <Title>利用規約が変更になりました</Title>
          <SubTitle>
            カート機能搭載に伴い、商品の売買契約の成立基準を「商品の出荷時」から「商品の
            {isSeller ? '受注時' : '注文時'}」に変更いたします。
          </SubTitle>
          <Contents>
            {isSeller
              ? `Yamato Food Market販売者利用規約 \n\n` + SELLER_TERMS_AND_CONDITIONS_TEXT
              : `Yamato Food Market会員利用規約 \n\n` + BUYER_TERMS_AND_CONDITIONS_TEXT}
          </Contents>
          <Button
            tw="bg-orange text-white  mt-4 h-14 text-14px"
            onClick={() => {
              newsId && onAgreed(newsId)
            }}
          >
            同意の上サービスを利用します
          </Button>
          <Button
            tw="border-green border border-solid text-green-DEFAULT　mt-4 h-14 text-14px"
            onClick={() => {
              sessionStorage.removeItem(storageKey.BUTTON_ACTIVE)
              logout({ returnTo: window.location.origin })
            }}
          >
            ログアウトする
          </Button>
        </div>
      </Overlay>
    </Portal>
  )
}

const Overlay = tw.div`fixed overflow-hidden top-0 left-0 flex justify-center items-center w-full h-full z-20 bg-black bg-opacity-75 py-6 px-6`
const Title = tw.p`text-16px  mt-6 font-bold text-green-deep`
const SubTitle = tw.p`text-12px  my-2 text-green-deep whitespace-pre-wrap`
const Contents = tw.p`rounded-md text-12px text-green-deep p-2 bg-background2 flex-1 w-full mx-auto overflow-scroll whitespace-pre-wrap`
