import Loader from 'react-loader-spinner'
import { Portal } from '../Portal/Portal'
import { PortalWrapper } from '../Portal/PortalWrapper'

export function Spinner({
  inline = false,
  width = 70,
  height = 70,
}: {
  inline?: boolean
  width?: number
  height?: number
}) {
  return inline ? (
    <Loader
      type="Oval"
      color="#4FA99B"
      height={height}
      width={width}
      timeout={20000} // 20 secs
    />
  ) : (
    <Portal>
      <PortalWrapper>
        <Loader
          type="Oval"
          color="#4FA99B"
          height={height}
          width={width}
          timeout={20000} // 20 secs
        />
      </PortalWrapper>
    </Portal>
  )
}
