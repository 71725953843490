type Address = {
  address1: string
  address2: string
  address3: string
  address4: string
}

type ResponseData = {
  code: string
  data: { en: Address; ja: Address; prefcode: string }[]
}

export const getPostalCode = async (zipCode: string): Promise<ResponseData> => {
  if (zipCode.length !== 7) {
    throw new Error('invalid zipCode')
  }

  const url = `https://madefor.github.io/postal-code-api/api/v1/${zipCode.slice(0, 3)}/${zipCode.slice(3, 7)}.json`

  return fetch(url).then((res) => (res.ok ? res.json() : Promise.reject(res)))
}
