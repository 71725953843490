import tw from 'twin.macro'
import React from 'react'
import { Button } from 'src/components/Button/Button'

export type Props = {
  onClose: () => void
}

export const UnBuyableUserModal = ({ onClose }: Props): JSX.Element => {
  return (
    <Overlay>
      <div tw="flex flex-col items-center rounded-2xl bg-white p-8 w-7/12 text-18px leading-10" css="width: 450px;">
        <p tw="whitespace-pre-wrap">
          ご登録されている配送先住所がサービス提供エリア外のためご購入いただけません。
          <br />
          サービス提供エリアは順次拡大いたしますので今しばらくお待ちください。
        </p>
        <div tw="w-full mt-10">
          <Button type="button" onClick={onClose} tw="bg-orange text-white h-12">
            OK
          </Button>
        </div>
      </div>
    </Overlay>
  )
}
const Overlay = tw.div`fixed top-0 left-0 flex justify-center items-center w-full h-full z-10 bg-black bg-opacity-75`
