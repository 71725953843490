import { ReactElement } from 'react'
import ReactDOM from 'react-dom'

interface Props {
  children: ReactElement
}

export function Portal(props: Props) {
  let root: HTMLElement | null = null
  if (process.browser && !root) {
    root = document.getElementById('modal')
  }

  return root && ReactDOM.createPortal(props.children, root)
}
