import tw from 'twin.macro'
import Link from 'next/link'
import { Route } from 'src/consts/route'
import { useCurrentUser } from 'src/contexts/currentUser'
import { UserType } from 'src/generated/graphql'
import { SUPPORT_EMAIL, SUPPORT_TEL } from 'src/consts/service'
import { URL } from 'src/consts/URLs'

export default function Footer({}) {
  const { role } = useCurrentUser()
  const isSeller = role === UserType.Seller
  const { showRole } = useCurrentUser()
  const showSeller = showRole === UserType.Buyer
  const isBuyer = role === UserType.Buyer
  return (
    <footer tw="flex flex-col text-black" css={[isBuyer || showSeller ? tw`hidden` : tw`block`]}>
      <div tw="pt-44px pb-6 flex-col bg-background2">
        <div tw="bg-black mx-4 h-0.5 mb-8"></div>
        <div tw="flex justify-center pb-6">
          <img src="/logo.svg" height={36} width={140} />
        </div>
        <FooterText tw="font-bold">お問い合わせ</FooterText>
        <FooterText tw="mt-2">
          TEL :{' '}
          <a href={URL.TAP_CONTACT_WITH_PHONE}>
            <TextTap>{SUPPORT_TEL}</TextTap>
          </a>
          （9:00-18:00 土日祝含む）
        </FooterText>
        <FooterText tw="mt-2">
          MAIL :{' '}
          <a href={URL.TAP_CONTACT_WITH_EMAIL}>
            <TextTap>{SUPPORT_EMAIL}</TextTap>
          </a>
        </FooterText>
        <div tw="flex justify-center">
          <a href={!isSeller && !isBuyer ? URL.FREQUENTLY_ASKED_QUESTIONS_NO_LOG : URL.FREQUENTLY_ASKED_QUESTIONS}>
            <TextLink tw="mt-10px">よくあるご質問（FAQ）</TextLink>
          </a>
          <img src="/icon_external_link_black.svg" tw="self-end ml-1" />
        </div>
        <div tw="mt-6 flex justify-center max-w-md self-center mx-auto flex-wrap">
          {role && (
            <Link href={isSeller && !showSeller ? Route.sellerTermsAndConitions : Route.termsAndConitions}>
              <TextLink tw="mx-2 text-center">利用規約</TextLink>
            </Link>
          )}
        </div>
        <div tw="mt-2 flex justify-center max-w-md self-center mx-auto flex-wrap">
          <TextLink tw="mx-2">
            <a href={URL.DEAL_WITH_PERSONAL_INFORMATION}>個人情報の取扱いについて</a>
          </TextLink>
          <Link href={isSeller && !showSeller ? Route.sellerScta : Route.scta}>
            <TextLink tw="mx-2">特定商取引に関する記載</TextLink>
          </Link>
        </div>
        <div tw="mt-6 flex justify-center pb-2">
          <img src="/logo_yamato.svg" height={34} width={128} />
        </div>
        <p tw="text-black text-10px flex items-center justify-center">
          <span tw="text-2xl">©️</span>
          Yamato Transport Co., Ltd. All Rights Reserved.
        </p>
      </div>
    </footer>
  )
}

const FooterText = tw.p`
  text-black
text-14px
text-center
`

const TextLink = tw.p`
whitespace-nowrap
  text-black
  text-12px
  underline
  cursor-pointer
`
const TextTap = tw.span`
underline
cursor-pointer
`
