export const SELLER_TERMS_AND_CONDITIONS_TEXT = `ヤマト運輸株式会社（以下「当社」といいます。）が運営する「Yamato Food Market」（以下「本サイト」といいます。）において提供するサービス（以下「本サービス」といいます。）を、第１条に定める認定販売者が利用する際の条件は、以下の「Yamato Food Market販売者利用規約」（以下「本規約」といいます。）の定めるとおりとします。
また、当社は、本規約とは別に、本サービスにかかるウェブサイト上への掲載等により本サービスの利用上の規定その他の告知（以下総称して「個別規約等」といいます。）を行うことがあります。
この場合、個別規約等は本規約の一部を構成するものとし、本サービスの利用あたっては、個別規約等を含めた本規約（以下「本規約等」といいます。）の規定が適用されます。

第１条（定義）
本規約等における用語の定義は、次のとおりとします。
（１） 「本サイト」とは、当社が運営する商品の売買契約を締結するための場及び当該商品の支払手続きを行う場であるインターネット上のプラットフォーム「Yamato Food Market」をいいます。
（２） 「認定販売者」とは、本規約を承認の上、第５条に従い、当社所定の審査を受けて登録を認められ、当社からアカウントを貸与された法人または個人事業主をいいます。
（３） 「会員」とは、本サイトにおいて認定販売者から商品を購入するために、第５条に定める当社所定の手続きを経てアカウント登録をした法人または個人事業主をいいます。
（４） 「本サービス」とは、本サイトにおいて当社が提供する各号に定めるサービスの総称をいいます。
① 認定販売者が自己の商品を販売する機能。
② 認定販売者が会員に販売した商品代金の代理受領（当社が指定する収納代行会社（ヤマトクレジットファイナンス株式会社（以下「YCF」といいます。））が行う場合を含みます。）、および認定販売者への商品代金等に係る銀行振込みなどの決済にかかる機能
③ 認定販売者が会員に販売した商品の配送

第２条（売買契約）
１ 本サービスにおいて、認定販売者と会員との間で締結される売買契約は、認定販売者と会員との間に成立するものとし、当社は当該売買契約の当事者となるものではありません。
２ 認定販売者は、売主としての一切の責任を負うものとします。
３ 認定販売者は、当社が売主であるとの誤解を与える表示、発言等を行ってはなりません。

第３条（認定販売者資格）
１ 認定販売者は、本規約に基づき、本サービスを通じて商品を出品することができる資格を有します。
２ 認定販売者は、前項の資格を第三者に利用、貸与、譲渡、売買、質入等をすることはできないものとします。
３ 認定販売者は、自己の責任において、アカウントのIDおよびパスワードを適切に管理し、第三者に開示または漏洩しないものとします。
４ 認定販売者に貸与されたアカウントを利用した行為は、すべて、当該認定販売者が行ったものとみなすものとします。

第４条（商品の配送）
１ 商品は、当社の運送サービスにて配送します。
２ 商品の配送に関しては、当社が提供する運送サービスにかかる運送約款が適用されます。
３ 商品の配送に際して、当社の責に帰すべき事由により、き損等の事故が生じた場合の賠償は、運送約款の規定に基づき対応します。

第５条（登録）
１ 本サービスの認定販売者として登録の申込みをする者（以下「登録希望者」といいます。）は、本規約等を遵守することに合意し、かつ登録事項その他当社が求める事項を当社の定める方法で当社に提供することにより、当社に対し登録申請をすることができます。
２ 前項の登録申請は、登録希望者本人が行わなければならず、また、登録希望者は、登録事項に関し、当社に対して真実、正確かつ最新の情報を提供しなければなりません。なお、登録希望者が法人である場合には、登録希望者を代理して登録申請を行うことについて正当な権限を有する登録希望者の役員または従業員が行うものとします。また、当社はその認識にかかわらず、当該役員または従業員が当該権限を有しているものとみなすことができるものとします。
３ 当社は、本条第１項に基づく登録申請を行った登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に電磁的な方法で通知します。登録希望者の認定販売者としての登録（以下「認定販売者登録」といい、登録された情報を「認定販売者情報」といいます。）は、当社が本項の電磁的な方法で通知を行ったことをもって完了するものとします。
４ 当社は、登録希望者が次の各号のいずれかに該当すると判断した場合には、申請を拒絶できるものとします。なお、当社はその理由を開示する義務を負わないものとします。
（１） 本規約に違反するおそれがあると当社が判断した場合
（２） 認定販売者情報に係る情報の全部または一部につき虚偽、誤記または記載漏れがあった場合
（３） 過去に本サービスまたは当社の他のサービスの利用の登録を取り消された者である場合
（４） 登録の申込み時点で未成年である場合
（５） 成年被後見人、被保佐人または被補助人のいずれかであり、成年後見人､保佐人または補助人の同意等を得ていなかった場合
（６） 反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または、資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等と何らかの交流もしくは関与その他社会的に非難さ れるべき関係を有している、と当社が判断した場合
（７） 取り扱う商品の内容、顧客への対応その他の事由に鑑み、本サービスの認定販売者として不適切と当社が判断した場合
（８） その他、当社が登録を適当でないと判断した場合
５ 認定販売者が法人である場合、当該認定販売者による本サービスの利用に関する一切の行為について、当社はその認識にかかわらず、当該認定販売者自身の行為または当該認定販売者を代理して行為することについて正当な権限を有する認定販売者の役員または従業員の行為とみなします。

第６条（出店）
１ 認定販売者は、取り扱う商品について、全ての責任を負うものとします。
２ 認定販売者は、取り扱う商品等の販売にあたって必要な免許・許認可等を認定販売者自身の責任において取得しなければなりません。
３ 認定販売者は、必要な利用環境や業務環境（以下に例示するものを含みますが、これに限りません。）を自己の責任において用意しなければなりません。
（１） 商品の受注に十分対応できる人員体制および商品管理体制の確保
（２） 取り扱う商品についての知識、商品販売にかかわる関連法規などに関する知識、本サービスの仕様に沿った商品データや商品画像を管理・運用する知識
（３） パソコンまたはスマートフォンおよびインターネット接続環境
（４） 常に電子メールを送受信できる環境（添付ファイルを含む電子メールの送受信ができること。）
（５） 常に当社ウェブサイトおよび当社からの電子メールを閲覧・受信できる環境
４ 認定販売者は、当社の以下の基準に基づく出店時審査および出店中の途上審査を受けることを予め承諾した上で出店することに同意するものとします。
（１） 取り扱う商品および販売方法が、法令等に違反せず適正であること
（２） お申し込み内容および当社にご提出いただく本人確認書類等が、正確かつ最新の内容であること
（３） 当社が行う本人確認および存在確認において不備がないこと
（４） 注文総額の振込先として登録された金融機関口座（受取口座）の有効性が確認できること
（５） 当社が定める経営状況判断基準（指定収納会社および第三者調査機関による与信審査等も参考にします。）を満たしていること
（６） その他、本サービスの認定販売者としてふさわしいと当社が判断すること
５ 認定販売者による出店費用および出店資格の更新は、原則、無償とします。

第７条（出品）
１ 認定販売者は、当社所定の方式により出品を行うものとします。
２ 認定販売者は、出品した商品の内容および販売・提供条件について、文字および画像により具体的かつ適切な説明を、本サービスのウェブサイト上に掲載するものとします。認定販売者は、会員等に誤解を生じさせる可能性のある説明を掲載しないものとします。
３ 認定販売者は、出品した商品についての説明が真実であることを保証するものとします。
４ 認定販売者は、出品に際して、特定商取引に関する法律の規定を遵守した表示を行うものとします。
５ 認定販売者は、出品した商品について法令等に違反した場合または法令等に違反するおそれがあると判明した場合には、直ちに当社に書面で通知するものとします。
６ 前項の場合または当社からウェブサイト上の記載について修正を求められた場合、認定販売者は直ちに当該記載を是正するものとします。

第８条（購入）
１ 売買契約は、会員が認定販売者の出品する商品を当社の定める方法によって注文し、当社が会員に対し注文確認メールを送信した時点で成立します。
２ 前項において成立した売買契約において、商品の瑕疵（梱包不備による破損等を含みます。）、商品数量の不一致等の契約不適合が確認された場合は、認定販売者が一切の責任を負い、当社は一切の責任を負いません。この場合、認定販売者は、返金、代金の減額、商品返品等一切の必要となる対応について、自ら会員に対して行うものとします。
３ 会員の認定販売者に対する債務の履行は、当社が別途定める方法により行うものとします。
４ 当社と認定販売者との本サービスの利用または当社と会員との利用契約が、契約期間の満了、解除、登録取消その他の事由により終了した場合でも、第１項により既に成立した認定販売者と会員との間における売買契約は有効に存続するものとします。

第９条（利用手数料）
１ 認定販売者は、当社に対し、本サービスで成立した売買契約の商品販売額（消費税相当額を含みます。）に応じた利用手数料を支払うものとします。
２ 前項の利用手数料は、第１条第４項に定める本サービスの利用料とし、別途、当社が認定販売者に対して発行する料金表にて取り決めるものとします。
３ 本サービスで成立した売買契約が無効、取消しまたは解除等されたとしても、当社は、会員から当該商品販売額を受領した後は、認定販売者に対し、当該商品販売額に応じた利用手数料を徴収できることとし、当該利用手数料の返金等は行わないものとします。

第１０条（商品販売額の支払い方法）
１ 認定販売者は、YCFに対し、出品した商品等を購入した会員から受領した代金（消費税相当額を含みます。）を代理受領することを委託するものとし、YCFは、当該代金額から前条に定める利用手数料を控除した金額を、商品の売買契約が成立した日の属する月の翌月末日（金融機関の休日の場合、前営業日とします。）までに認定販売者の指定する銀行口座に送金します。但し、会員の取り消し等により商品の販売が無効となる場合は、この限りではありません。
２ 会員が認定販売者の出品した商品等の代金を期限内に支払わない場合は、YCFは当該会員の保証人として、代金額を第１項のとおり前条に定める利用手数料を控除した上で認定販売者に対して支払うものとし、当該支払いによって認定販売者の会員に対する代金債権をYCFは取得し、直接会員に請求を行うことができるものとします。
３ 振り込み金額の詳細については、所定フォーマットの精算書を振込日から５営業日以内に原則申込時に登録いただくメールアドレスに電磁的な方法で送付いたします。
４ 第１項及び第２項の支払い時に発生する振込手数料は、認定販売者の負担とします。

第１１条（登録取消）
１ 当社は、認定販売者が、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、認定販売者について本サービスの利用を一時的に停止、または認定販売者としての登録を抹消することができます。また、当社は、認定販売者についてこれらの事由の該当性を確認するために、当社が必要と判断する本人確認および事実確認等を行うことができ、当該確認が完了するまでの間、認定販売者に対する本サービスの利用を一時的に停止する等の必要な措置を講ずることができます。当社は、本項に定める措置を講じた場合、認定生産者に対してその理由を開示する義務を負わないものとし、認定販売者は予めこれを承諾するものとします。
（１） 本規約等のいずれかの条項に違反した場合
（２） 販売認定者情報に虚偽の事実があることが判明した場合
（３） 支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算手続開始もしくはこれらに類する手続の開始の申立てがあった場合
（４） 本サービスのアカウントへのログインが３年以上ない場合
（５） 当社からの問い合わせその他の回答を求める連絡に対して３０日以上応答がない場合
（６） 第５条４項各号に掲げる事由に該当する場合
（７） 会員に対する債務不履行等、会員または当社との信頼関係を破壊したと認められる場合
（８） 第６条４項の審査の結果、認定販売者による本サービスの利用の継続が適当でないと当社が判断した場合
（９） 第１２条各号に掲げる事由に該当する場合
（１０） その他、当社が本サービスの利用、認定販売者としての登録が適当でないと判断した場合
２ 前項各号のいずれかの事由に該当した場合、認定販売者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
３ 本条に基づく当社の措置によって認定販売者に生じた損害について、当社は一切の責任を負わないものとします。
４ 当社は本条に基づく登録抹消措置の対象となった認定販売者について、その個人情報およびアカウント情報等を引き続き保有する義務を負わないものとします。

第１２条（本サービスにおける禁止事項）
当社は、認定販売者が、本サービスを利用するに際して、次に定める行為をすることを禁止いたします。次の禁止事項に該当すると当社が判断した場合には、当社は、認定販売者への事前の通知なく、本サービスの全部または一部について、提供の中止または一時停止、アカウントの削除または一時停止、登録の取消等、その他当社が合理的に必要と判断する一切の措置を講じることができます。
（１） 本規約等に違反する行為
（２） 日本国および認定販売者の居住ないし所在する国または地域の法令等に違反する行為
（３） 他人の権利を侵害し、または社会規範や公序良俗に反する行為
（４） 当社サービスソースコードのリバースエンジニアリングや抽出を試みる行為
（５） 本サービスを、本来のサービス提供の目的と実質的に異なる目的で利用する行為
（６） 当社による本サービスの運営を妨害するおそれのある行為
（７） 資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与その他社会的に非難されるべき関係が伺われる行為
（８） 会員に対する債務を履行しない行為
（９） その他、当社が不適切と判断する行為

第１３条（本サービスの利用停止等）
当社は、次の場合には、認定販売者への事前の通知なく、本サービスの全部または一部について、本サービスの全部または一部の提供の中止等、当社が必要と判断する一切の措置を講じることができます。
（１） 本サービスにかかるコンピューターシステムの点検または保守作業を緊急に行う必要がある場合
（２） 当社のコンピューターや通信回線等、本サービスの提供に必要な設備等が事故等により使用できなくなった場合
（３） 地震、落雷、火災、風水害、停電、天災地変等により本サービスの運営が不可能もしくは著しく困難となった場合
（４） 戦争、変乱、暴動、騒乱、労働争議、感染症等その他不可抗力により本サービスの提供が不可能もしくは著しく困難となった場合
（５） 法令またはこれに基づく命令等により本サービスの提供が不可能もしくは著しく困難となった場合
（６） その他当杜が本サービスの停止または中断が必要であると判断した場合

第１４条（個人情報の取扱い）
１ 認定販売者は、他の認定販売者、会員その他第三者の個人情報を取得した場合には、これを秘密に保持し、第三者に開示または漏洩してはならず、また本サービスを利用する目的以外に使用してはならないものとします。
２ 当社は、認定販売者情報を、前項に定める本サービスを提供する目的のほか、次の各号に掲げる目的に利用することができるものとします。
（１） 本サービス及び当社、当社グループ各社のサービスに関連する案内のため
（２） アンケート、広告、キャンペーン実施等の情報の配信のため
（３） 本サービスの改善、当社における新商品・サービスの研究や開発を目的とする市場調査やデータ分析のため
（４） ダイレクトメールの発送等、商品・サービスに関するご案内のため
（５） 認定販売者としての資格の有無の確認のため
（６） その他当社との取引を適切かつ円滑に遂行するため
３ 当社は認定販売者情報を第三者に開示する場合は、必ず事前に認定販売者の同意を得るものとします。ただし、当社は認定販売者情報を特定できない形態に加工した情報については、これを自由に利用し、又は第三者に開示することができるものとします。
４ 本条に定めるほか認定販売者情報の個人情報は、当社が別途定める「個人情報のお取り扱いについて」に従って取り扱われます。
「個人情報のお取り扱いについて」の詳細は、当社ホームページをご確認下さい。

第１５条（設備の負担等）
１ 本サービスの提供を受けるために必要な、コンピューター、スマートフォン、ソフトウェアその他の機器、通信回線その他の通信環境等の準備および維持は、認定販売者の費用と責任において行うものとします。
２ 認定販売者は、自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセスおよび情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。

第１６条（認定販売者の賠償等の責任）
１ 認定販売者は、本規約等に違反または本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害（弁護士費用を含みます。）を賠償しなければなりません。
２ 認定販売者が、本サービスに関連して他の認定販売者、会員、その他第三者からクレームを受けまたはそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、認定販売者の費用と責任において当該クレームまたは紛争を処理し、当社からの要請に基づき、その経過および結果を当社に報告するものとします。
３ 認定販売者による本サービスの利用に関連して、当社が、他の認定販売者、会員、その他第三者から権利侵害その他の理由により何らかの請求を受けた場合は、認定販売者は当該請求に基づき当社が支払を余儀なくされた金額を賠償しなければなりません。

第１７条（保証否認および免責）
１ 当社は本サービスについて、特定の目的への適合性、商業的有用性、収益性の向上、完全性、最新性、継続性等を含め、一切の保証をしません。
２ 本サービスにおいて収集、蓄積、保管されるデータ等（会員の利用に関する情報を含みますが、この限りではありません。）について、認定販売者は自らの責任でバックアップを保存しておくものとし、当社はかかるデータ等の保管、保存、バックアップ等に関して、一切の保証をしません。
３ 認定販売者は、本サービスを利用することが、認定販売者に適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、認定販売者による本サービスの利用が、認定販売者に適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
４ 本サービスに関連して認定販売者と他の認定販売者、会員、外部事業者その他第三者との間において生じた取引、連絡、紛争等については、認定販売者の責任において処理および解決するものとし、当社はかかる事項について一切の責任を負いません。
５ 当社は、当社による本サービスの提供の中断、停止、終了、利用不能または変更、認定販売者情報の削除または消失､認定販売者の登録を取消、本サービスの利用によるデータの消失または機器の故障もしくは損傷、その他本サービスに関連して認定販売者が被った損害につき、賠償する責任を一切負いません。
６ 当社は、本サービスの履行に関連して認定販売者が被った損害について、一切の賠償責任を負いません。万一、何らかの理由により当社が認定販売者に対して損害賠償責任を負う場合においても、当社の賠償責任は、当社に故意または重過失が存在する場合に限定され、かつ、直接かつ現実に認定販売者に生じた損害に限定されます。

第１８条（権利帰属）
１ 本サービスのうち当社が提供するソフトウェアおよびシステム等に関する所有権および知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、認定販売者に対する当該ソフトウェアおよびシステム等の利用許諾は、本規約等において明示されているものを除き、当社または当社にライセンスを許諾している者の知的財産権の譲渡または使用許諾を意味するものではありません。
２ 認定販売者は、いかなる理由によっても当社または当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしないものとします。
３ 本サイト、本サービス及びその内容の一切に関する著作権、その他の知的財産権等は当社に帰属するものとします。

第１９条（秘密保持義務）
１ 認定販売者は、本サービスの利用に基づき知り得た本サービスに関する運営、技術等の事項一切を秘密として保持し、これを第三者に漏洩してはならないものとします。
２ 認定販売者は、当社より交付または貸与されたマニュアル、書類、ツール、資料等を、第三者の目に触れないように厳重に保管し、自ら転写、複写等をし、もしくは第三者をして閲覧、転写、複写等をさせてはならないものとします。
３ 認定販売者は、自社の社員、契約社員、委託社員、派遣社員、パート社員、役員（取締役、監査役、相談役、顧問等）等（以下、総称して「従業員等」といいます。）および本サービスの関係者に対しても、本条に基づく秘密保持義務を遵守させるものとします。
４ 認定販売者は、本サービスの利用終了後も１年間、本条の義務を負うものとします。
５ 認定販売者は、本サービスの利用期間中および終了後を問わず、本条の秘密保持義務に違反した場合、認定販売者は合理的な算出方法により算出した損害額を賠償する義務を負うものとします。

第２０条（本規約等の変更）
１ 当社は、本規約等の変更が認定販売者の一般の利益に適合する場合、または、本規約等の変更が、本サービス利用の目的に反せず、当該変更が必要かつ相当であって、当該変更に合理的な理由があると当社が判断した場合には、民法の定型約款の変更に係る規定に基づき、本規約等の内容を変更できます。
２ 当社は、本規約等を変更する場合には、本サイト上に表示する等、適切と認められる方法で、本規約等の変更内容および変更の効力発生日を認定販売者に相当な期間をもって周知し、当該効力発生日の到来をもって、変更後の利用規約が適用されます。
３ 当社は、第１項に基づかずに本規約等の変更を行う場合には、変更後の利用規約等の内容について、前項に定める方法により周知したうえで、認定販売者の同意を得るものとします。認定販売者から明示的な同意を得ることができない場合であっても、前項に定める方法により周知した後、認定販売者が何らの留保なく本サービスを利用した場合または周知の日から相当な期間を超えて退会の手続をとらなかった場合は、認定販売者は当該変更後の利用規約等の内容に同意したものとみなします。

第２１条（公表・通知）
当社は、本サービスに関する連絡事項がある場合には、当該連絡事項を、本サイトにて掲載する方法により公表するか、または認定販売者が本サービスにおいて登録している電子メールアドレスに対して個別に送信する方法により伝達します。通知を電子メールで行う場合には、当社が、認定販売者の電子メールアドレスを管理するメールサーバーにデータを発信した時点をもって通知が完了したものとします。

第２２条（再委託）
当社は、本規約等に定める本サービスの運営、管理等について、業務の一部を第三者に再委託することができるものとします。

第２３条（権利義務等の譲渡禁止）
認定販売者は、本規約等に基づくすべての契約（会員との間の売買契約を含みます。）について、その契約上の地位および権利義務の全部または一部を、当社の事前の書面による承諾なく第三者に譲渡することはできません。

第２４条（完全合意）
本規約等は、本サービスの利用に関する当社と認定販売者との完全な合意を構成し、口頭または書面を問わず、本サービスの利用に関する当社と認定販売者との事前の合意、表明および了解に優先します。

第２５条（分離可能性）
本規約等のいずれかの条項またはその一部が、法令等により無効または執行不能と判断された場合であっても、本規約等の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社および認定販売者は、当該無効もしくは執行不能の条項または部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効もしくは執行不能な条項または部分の趣旨ならびに法律的および経済的に同等の効果を確保できるように努めるものとします。

第２6条（商品保管に関する特約）
1 当社は、認定販売者との合意により、認定販売者が本サイトにて販売を行う特定の商品について、当社が指定する数量を認定販売者に代わって保管を行う（以下「商品保管」といいます。）ものとします。保管に係る詳細は次の各号に定めるとおりです。
（１）認定販売者は、当社に保管を依頼する商品（以下「指定商品」といいます。）について、商品名、指定商品の保管温度帯、保管期限に関する情報等当社が指定する情報を、当社の指定する方法で提供するものとします。
（２）当社は、前号により認定販売者から提供を受けた情報に基づき審査を行い、審査の結果商品保管に適すると当社が判断した場合、商品保管開始のための手続きを認定販売者に案内します。認定販売者による当該手続き完了後、当社が認定販売者に対して商品保管を利用した本サービスに係る利用手数料について、認定販売者に対して料金表を発行することをもって、指定商品につき商品保管を開始することができるものとします。なお、商品保管にかかる費用も利用手数料に含まれるものとします。
（３）当社は認定販売者に対し、指定商品の種類、数量、発送日等を記載した発送依頼を書面（電磁的方法を含む）により送付し、認定販売者は発送依頼の記載に従い当該指定商品を当社宛に発送するものとします。
（４）第8条第1項に基づき指定商品に係る売買契約が成立し、かつ売買契約成立時に当該指定商品の保管を当社が行っている場合、当社は会員に対して第4条に基づき当該指定商品の配送を行います。
（５）当社は、第8条第1項に基づき売買契約が成立した指定商品につき外装チェックを行い、商品の瑕疵（梱包不備による破損等を含む。）、数量の不一致等が確認された場合、認定販売者に対して代品の送付等を請求することができ、認定販売者は、これを行うものとします。
（６）指定商品の保管実施上の細目については、本規約に定めるほか、当社の標準倉庫寄託約款・標準冷蔵倉庫寄託約款その他指定商品の保管に適用される当社の約款（以下「倉庫約款」という。）に定めるとおりとします。
2 当社は、前項第1号に定める保管期限を超過しても売買契約が成立しなかった指定商品（以下「期限超過在庫」という。）について、認定販売者から買い取るものとします。買取に係る詳細は次の各号に定めるとおりです。
（１）当社が買取を行う場合の買取価格は、当該期限超過在庫に係る指定商品の本サービスでの販売単価から利用手数料を控除した金額とします。
（２）当社は、期限超過在庫について、保管期限の翌日に本サービスを通じて買い取り、当該買取に係る金額は、毎月末日締めで集計し、締日の属する月の翌月末日までに、第10条に定める方法により認定販売者に支払うものとします。
（３）当社は、前号に定める締日の3営業日後までに、当該締日に係る期間での商品保管での販売数量および買取数量を認定販売者に電磁的方法で通知します。


２０２１年４月１日 制定
２０２１年５月１７日 改定
２０２３年１２月２５日 改定
ヤマト運輸株式会社

`
