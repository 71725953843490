import { ParsedUrlQuery } from 'querystring'
import { RouteValue } from 'src/consts/route'

export const routePath = (routeValue: RouteValue, pathParams?: ParsedUrlQuery | Record<string, string>) => {
  if (!pathParams) return routeValue

  return Object.keys(pathParams).reduce((pre, cur) => {
    const pathParam = pathParams[cur]
    if (!pathParam) return pre
    if (Array.isArray(pathParam)) {
      return pathParam.reduce((p, c) => p.replace(`[${c}]`, pre))
    }
    return pre.replace(`[${cur}]`, pathParam)
  }, routeValue)
}
