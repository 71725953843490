import { ItemListType, ITEM_LIST_TYPE } from 'src/consts/ItemListType'
import { CartItem } from 'src/contexts/cart'
import { Product, Seller } from 'src/generated/graphql'

import { DEFAULT_CATEGORY_ID, ITEM_CATEGORY } from 'src/consts/itemCategory'

export const fromCartItemtoGtagItemObject = (cartItem: CartItem) => {
  return {
    item_id: cartItem.productId,
    item_name: cartItem.productName,
    affiliation: cartItem.shopName,
    item_category: ITEM_CATEGORY[cartItem.categoryId],
    price: cartItem.price,
    currency: 'JPY',
    quantity: cartItem.selectedProductQuantity,
  }
}

export const fromCartItemtoGtagPurchaseObject = (cartItem: CartItem) => {
  return {
    item_id: cartItem.productId,
    item_name: cartItem.productName,
    affiliation: cartItem.shopName,
    item_category: ITEM_CATEGORY[cartItem.categoryId],
    price: cartItem.price,
    currency: 'JPY',
    quantity: cartItem.selectedProductQuantity,
  }
}

export const fromProductToGtagItemObject = (product: Product, seller?: Seller) => {
  return {
    item_id: product.id,
    item_name: product.name,
    affiliation: (product.seller?.shopName ? product.seller.shopName : seller?.shopName) || '',
    item_category: ITEM_CATEGORY[product.category?.id || DEFAULT_CATEGORY_ID],
    item_category2: product.subCategories?.map((v) => v.name).join(','),
    item_category3: product.miniCategories?.[0]?.name,
    price: product.price,
    currency: 'JPY',
    quantity: 1,
  }
}

//https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?hl=ja#view_item
export const toViewItemEventObject = (product: Product) => {
  return {
    items: [fromProductToGtagItemObject(product)],
    currency: 'JPY',
    value: product.price,
  }
}

//https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?hl=ja#view_item
export const toViewDetailItemEventObject = (product: Product, itemListName: string, itemListId: string) => {
  return {
    items: [
      {
        ...fromProductToGtagItemObject(product),
        item_list_id: itemListId,
        item_list_name: itemListName,
      },
    ],
    currency: 'JPY',
    value: product.price,
  }
}
//https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#view_item_list
export const toViewItemListEventObject = (products: Product[]) => {
  const items = products.map((product, index) => {
    return {
      index: index,
      item_id: product.id,
      item_name: product.name,
      item_category: ITEM_CATEGORY[product.category?.id || DEFAULT_CATEGORY_ID],
    }
  })
  const eventObject = {
    items: items,
    item_list_name: 'Buyer Products Home',
    item_list_id: 'buyer_products_home',
  }
  return eventObject
}
//https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#view_item_list
export const toViewItemPageListEventObject = (products: Product[], itemListName: string, itemListId: string) => {
  const items = products.map((product, index) => {
    return {
      index: index,
      item_id: product.id,
      item_name: product.name,
      item_list_id: itemListId,
      item_list_name: itemListName,
      affiliation: product.seller?.shopName || '',
      item_category: ITEM_CATEGORY[product.category?.id || DEFAULT_CATEGORY_ID],
      item_category2: product.subCategories?.map((v) => v.name).join(','),
      item_category3: product.miniCategories?.[0]?.name,
      price: product.price,
      currency: 'JPY',
      quantity: 1,
    }
  })
  const eventObject = {
    items: items,
    item_list_name: itemListName,
    item_list_id: itemListId,
  }
  return eventObject
}

export const toAddToCartEventObject = (
  product: Product,
  selectedQuantity: number,
  itemListTypes: ItemListType,
  seller?: Seller
) => {
  return {
    items: [
      {
        ...fromProductToGtagItemObject(product, seller),
        quantity: selectedQuantity,
        // @ts-expect-error TS7053
        item_list_name: ITEM_LIST_TYPE[itemListTypes]?.name,
        // @ts-expect-error TS7053
        item_list_id: ITEM_LIST_TYPE[itemListTypes]?.id,
      },
    ],
    currency: 'JPY',
    value: product.price * selectedQuantity,
  }
}

export const toRemoveFromCartEventObject = (cartItem: CartItem) => {
  return {
    items: [
      {
        ...fromCartItemtoGtagItemObject(cartItem),
        item_list_name:
          // @ts-expect-error TS7053
          ITEM_LIST_TYPE[cartItem.itemListTypes as ItemListType]?.name,
        item_list_id:
          // @ts-expect-error TS7053
          ITEM_LIST_TYPE[cartItem.itemListTypes as ItemListType]?.id,
      },
    ],
    currency: 'JPY',
    value: cartItem.price * cartItem.selectedProductQuantity,
  }
}

//https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?hl=ja#begin_checkout
export const toBeginCheckoutEventObject = (cartItems: CartItem[]) => {
  return {
    items: cartItems.map((cartItem: CartItem) => {
      return {
        ...fromCartItemtoGtagItemObject(cartItem),
        item_list_name:
          // @ts-expect-error TS7053
          ITEM_LIST_TYPE[cartItem.itemListTypes as ItemListType]?.name,
        item_list_id:
          // @ts-expect-error TS7053
          ITEM_LIST_TYPE[cartItem.itemListTypes as ItemListType]?.id,
      }
    }),

    currency: 'JPY',
    value: cartItems.reduce((acc, currentItem) => acc + currentItem.price * currentItem.selectedProductQuantity, 0),
  }
}

//https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce
export const toPurchseSuccessEventObject = (
  cartItems: CartItem[],
  orderId: number,
  couponcode: string,
  couponDiscount: number | undefined
) => {
  return {
    transaction_id: orderId,
    affiliation: 'Yamato Food Market',
    items: cartItems.map((cartItem) => {
      return {
        ...fromCartItemtoGtagPurchaseObject(cartItem),
        item_list_name:
          // @ts-expect-error TS7053
          ITEM_LIST_TYPE[cartItem.itemListTypes as ItemListType]?.name,
        item_list_id:
          // @ts-expect-error TS7053
          ITEM_LIST_TYPE[cartItem.itemListTypes as ItemListType]?.id,
      }
    }),
    currency: 'JPY',
    value: cartItems.reduce(
      (acc, currentItem) => acc + currentItem.price * currentItem.selectedProductQuantity,
      couponDiscount ? -couponDiscount : 0
    ),
    couponcode: couponcode,
  }
}

//https://developers.google.com/gtagjs/reference/ga4-events?hl=ja#add_to_wishlist
export const toAddToWishlistEventObject = (product: Product, itemListTypes: ItemListType, seller?: Seller) => {
  return {
    items: [
      {
        ...fromProductToGtagItemObject(product, seller),
        // @ts-expect-error TS7053
        item_list_name: ITEM_LIST_TYPE[itemListTypes]?.name,
        // @ts-expect-error TS7053
        item_list_id: ITEM_LIST_TYPE[itemListTypes]?.id,
      },
    ],
    currency: 'JPY',
    value: product.price,
  }
}
