import tw from 'twin.macro'
import { useAuth0 } from '@auth0/auth0-react'
// @ts-expect-error TS6133
import { useEffect, useMemo, useState, useCallback, useContext } from 'react'
import { Route } from 'src/consts/route'
import { useCurrentUser } from 'src/contexts/currentUser'
import { UserType } from 'src/generated/graphql'
import { useRouter } from 'next/router'
import { useCartState } from 'src/contexts/cart'
import { KeywordSearchBar } from 'src/components/OverallUIComponents/KeywordSearchBar/KeywordSearchBar'
import { routePath } from 'src/util/routePath'
import { ButtonType, BUYER_NAV_MENU, SELLER_NAV_MENU } from 'src/consts/navMenuItems'
import { throttle } from 'lodash'
import { useImmerReducer } from 'use-immer'
import { TabBar } from 'src/components/TabBar/TabBar'
import { envNames } from 'src/consts/envTypeSetting'
import { ProductInfoContext } from 'src/contexts/commonContext'
import { InquiryModal } from 'src/components/InquiryModal/InquiryModal'
import { storageKey } from 'src/consts/storageKey'

export default function NavBarNew({}) {
  const [activeTab, setActiveTab] = useState<ButtonType>(ButtonType.None)
  const { role, isOnboarded } = useCurrentUser()
  const { showRole } = useCurrentUser()
  const showSeller = showRole === UserType.Buyer
  const { isAuthenticated } = useAuth0()
  const { pathname, push, query } = useRouter()
  const { currentCartItems } = useCartState()
  const isSeller = role === UserType.Seller
  const isBuyer = role === UserType.Buyer
  const [, , purchaseStatus] = useContext(ProductInfoContext)
  const [showInquiryModel, setShowInquiryModel] = useState(false)
  const [, , , , , , , setDisplayBackTop] = useContext(ProductInfoContext)

  useEffect(() => {
    if (isSeller && !showSeller) {
      switch (pathname) {
        case Route.sellerHome:
          setActiveTab(ButtonType.Home)
          break
        case Route.sellerOrderHistory:
          setActiveTab(ButtonType.History)
          break
        case Route.sellerSetting:
          setActiveTab(ButtonType.Setting)
          break
        default:
          setActiveTab(ButtonType.None)
          break
      }
    } else {
      switch (pathname) {
        case Route.productSearch:
          setActiveTab(ButtonType.Home)
          break
        case Route.cart:
          setActiveTab(ButtonType.Cart)
          break
        case Route.orderHistory:
          setActiveTab(ButtonType.History)
          break
        case Route.setting:
          setActiveTab(ButtonType.Setting)
          break
        case Route.favorite:
          setActiveTab(ButtonType.Favorite)
          break
        case Route.coupon:
          setActiveTab(ButtonType.Coupon)
          break
        default:
          setActiveTab(ButtonType.None)
          break
      }
    }
  }, [pathname, isSeller, showSeller])

  const handleHomeLink = () => {
    if (
      pathname === Route.onboarding ||
      pathname === Route.verifyEmail ||
      pathname === Route.sellerOnboarding ||
      pathname === Route.landing ||
      pathname === Route.ycfJudge
    ) {
      return
    }

    if (isBuyer || showSeller) {
      push(Route.productSearch)
    } else if (isSeller) {
      push(Route.sellerHome)
    }
  }

  const isLandingPage = pathname === Route.landing
  const isCampaignLanding = pathname === Route.campaignLanding
  const hideNavMenu = useMemo(() => {
    if (isLandingPage || isCampaignLanding) return false

    if (!role) {
      return false
    }

    if (!isAuthenticated) return false

    if (
      Route.onboarding === pathname ||
      Route.buyerQuestionnaire === pathname ||
      Route.buyerQuestionnaireCompleted === pathname ||
      Route.sellerOnboarding === pathname ||
      Route.verifyEmail === pathname ||
      Route.sendVerificationEmail === pathname ||
      Route.ycfJudge === pathname
    ) {
      return false
    }

    const hideMenuIsOnboardedPathname: string[] = [
      Route.sellerScta,
      Route.scta,
      Route.sellerTermsAndConitions,
      Route.termsAndConitions,
    ]

    if (!isOnboarded && hideMenuIsOnboardedPathname.includes(pathname)) {
      return false
    }

    return true
  }, [isAuthenticated, isOnboarded, role, pathname, isLandingPage, isCampaignLanding])

  const keywordInitialValues = (query.keyword ?? '') as string

  const [changeKeyword, setChangeKeyword] = useState<boolean>(false)

  const setChangeKeywordFalse = () => {
    setChangeKeyword(false)
  }

  useEffect(() => {
    setChangeKeyword(true)
  }, [pathname, query])

  const search = (values: { [key: string]: string }) => {
    sessionStorage.removeItem(storageKey.SEARCH_FILTER_CATEGORY)
    const queryParams = {
      categoryId: query.categoryId,
      subcategoryId: query.subcategoryId,
      minicategoryId: query.minicategoryId,
      productCategory: query.productCategory,
      deliveryDates: query.deliveryDates,
      keyword: values.keyword || undefined,
    }
    if (!queryParams.categoryId) delete queryParams.categoryId
    if (!queryParams.subcategoryId) delete queryParams.subcategoryId
    if (!queryParams.minicategoryId) delete queryParams.minicategoryId
    if (!queryParams.productCategory) delete queryParams.productCategory
    if (!queryParams.deliveryDates) delete queryParams.deliveryDates
    if (!queryParams.keyword) delete queryParams.keyword

    if (Object.keys(queryParams).length === 0 && pathname !== Route.productSearchResult) return

    if (pathname === Route.productSearchResultOld) {
      push({
        pathname: routePath(Route.productSearchResultOld),
        query: queryParams,
      })
      return
    }
    push({
      pathname: routePath(Route.productSearchResult),
      query: queryParams,
    })
  }

  const [btnArrsImmer, dispatch] = useImmerReducer(
    (draft, action) => {
      switch (action.type) {
        case 'resetForSeller':
          return SELLER_NAV_MENU
        case 'resetForBuyer':
          return BUYER_NAV_MENU
        case 'setCartCount':
          return void draft.map((o) => {
            if (o.type === ButtonType.Cart) {
              o.cartCount = currentCartItems.length
            }
          })
        case 'setShowSeller':
          return void draft.map((o) => {
            if (
              showSeller &&
              (o.type === ButtonType.Cart ||
                o.type === ButtonType.Favorite ||
                o.type === ButtonType.History ||
                o.type === ButtonType.Setting ||
                o.type === ButtonType.Coupon ||
                o.type === ButtonType.Inquiry)
            ) {
              o.disable = true
            }
          })
        case 'setActive':
          //タブボタンの状態を切り替える
          return void draft.map((o) => {
            if (o.type === activeTab) {
              o.active = true
            } else {
              o.active = false
            }
          })
      }
    },
    showSeller || isBuyer ? BUYER_NAV_MENU : SELLER_NAV_MENU
  )

  useEffect(() => {
    if (showSeller || isBuyer) {
      dispatch({ type: 'resetForBuyer' })
    } else {
      dispatch({ type: 'resetForSeller' })
    }
    dispatch({ type: 'setShowSeller' })
  }, [showSeller, isBuyer, dispatch])

  useEffect(() => {
    dispatch({ type: 'setCartCount' })
  }, [currentCartItems.length, showSeller, dispatch])

  useEffect(() => {
    dispatch({ type: 'setActive' })
  }, [activeTab, showSeller, dispatch])

  const buyerNavClicked = (type: ButtonType) => {
    setActiveTab(type)
    switch (type) {
      case ButtonType.Home:
        push(Route.productSearch)
        break
      case ButtonType.Favorite:
        push(Route.favorite)
        break
      case ButtonType.History:
        push(Route.orderHistory)
        break
      case ButtonType.Cart:
        push(Route.cart)
        break
      case ButtonType.Setting:
        push(Route.setting)
        break
      case ButtonType.Coupon:
        push(Route.coupon)
        break
      case ButtonType.Inquiry:
        setActiveTab(activeTab)
        setShowInquiryModel(true)
        setDisplayBackTop(false)
        break
      default:
        break
    }
  }

  const sellerNavClicked = (type: ButtonType) => {
    setActiveTab(type)
    switch (type) {
      case ButtonType.Home:
        push(Route.sellerHome)
        break
      case ButtonType.History:
        push(Route.sellerOrderHistory)
        break
      case ButtonType.Setting:
        push(Route.sellerSetting)
        break
      default:
        break
    }
  }

  const navClickedThrottle = throttle(showSeller || isBuyer ? buyerNavClicked : sellerNavClicked, 500, {
    leading: true,
    trailing: false,
  })

  const showSearchBar = useMemo(() => {
    if (pathname === Route.cart && purchaseStatus) {
      return false
    }
    return true
  }, [pathname, purchaseStatus])

  const processEnv = process.env.OPT_ENV

  const logoCenter = useMemo(() => {
    if (
      pathname === Route.onboarding ||
      pathname === Route.buyerQuestionnaire ||
      pathname === Route.buyerQuestionnaireCompleted ||
      pathname === Route.verifyEmail ||
      pathname === Route.sellerOnboarding ||
      pathname === Route.ycfJudge
    ) {
      return true
    }
    return false
  }, [pathname])

  return (
    <nav tw="w-full bg-white" id="buyer_navbar">
      {processEnv && envNames[processEnv] && (
        <div tw="h-6 text-center text-white text-14px" css={[envNames[processEnv].cssParam]}>
          {envNames[processEnv].title}
        </div>
      )}
      <div
        tw="w-full landing-pc:max-w-1200px landing-pc:h-24 h-16 mx-auto flex flex-row justify-between items-center"
        css={[isLandingPage && 'max-width: 1025px;', logoCenter ? tw`justify-center` : tw`justify-between`]}
      >
        <div
          tw="w-16 landing-pc:w-106px h-full ml-4 landing-pc:ml-0 flex justify-center cursor-pointer py-15px landing-pc:py-5"
          onClick={handleHomeLink}
          css={[
            logoCenter
              ? tw`ml-0 landing-pc:ml-0 py-15px landing-pc:py-5 cursor-default`
              : tw`ml-4 landing-pc:ml-0 py-15px landing-pc:py-5 cursor-pointer`,
          ]}
        >
          <img src="/overallUI/icon_navbar_logo.svg" tw="h-full w-auto object-cover" />
        </div>

        {hideNavMenu && (isBuyer || showSeller) && showSearchBar && (
          <div
            tw="pl-4 landing-pc:pl-2 landing-pc:w-335px mr-4 landing-pc:mr-0 landing-pc:margin-left[185px]"
            css={'width:calc(100vw - 182px)'}
          >
            <KeywordSearchBar
              searchKeyword={keywordInitialValues}
              changeKeyword={changeKeyword}
              setChangeKeyword={setChangeKeywordFalse}
              search={(values) => search(values)}
            />
          </div>
        )}
        {hideNavMenu && (
          <div
            tw="h-full flex-row flex-nowrap mr-4 landing-pc:mr-0"
            css={[showSeller || isBuyer ? tw`landing-pc:flex` : tw`flex`]}
          >
            <TabBar
              buttons={btnArrsImmer}
              cssParams={[tw`mt-2 landing-pc:mt-0 landing-pc:space-x-6`]}
              clickAction={navClickedThrottle}
            />
            {showInquiryModel && (
              <InquiryModal
                onModalClose={() => {
                  setShowInquiryModel(false)
                  setDisplayBackTop(true)
                }}
              ></InquiryModal>
            )}
          </div>
        )}
      </div>
    </nav>
  )
}
