import { TabBarItemProps } from 'src/components/TabBar/TabBarItem'
import tw, { TwStyle } from 'twin.macro'

const layoutCss: TwStyle[] = [tw`w-11`, tw`h-full`]
export enum ButtonType {
  Menu = 'Menu',
  Home = 'Home',
  Search = 'Search',
  Favorite = 'Favorite',
  Cart = 'Cart',
  History = 'History',
  Setting = 'Setting',
  Inquiry = 'Inquiry',
  None = 'None',
  SearchMenu = 'SearchMenu',
  Coupon = 'Coupon',
}
export const SELLER_NAV_MENU: TabBarItemProps[] = [
  {
    type: ButtonType.Home,
    image: '/icon_market_passive.svg',
    hightlight: '/icon_market_active.svg',
    text: '商品',
    cssParams: layoutCss,
    active: false,
    disableImage: '/icon_market_active.svg',
    disable: false,
  },
  {
    type: ButtonType.History,
    image: '/icon_truck_active.svg',
    hightlight: '/icon_truck_passive.svg',
    text: '受注履歴',
    cssParams: layoutCss,
    active: false,
    disableImage: '/icon_truck_inactive.svg',
    disable: false,
  },
  {
    type: ButtonType.Setting,
    image: '/icon_account_active.svg',
    hightlight: '/icon_account_passive.svg',
    text: '設定',
    cssParams: layoutCss,
    active: false,
    disableImage: '/icon_account_inactive.svg',
    disable: false,
  },
]

const navbarCss: TwStyle[] = [tw`min-w-44px h-full hidden landing-pc:flex`]
export const BUYER_NAV_MENU: TabBarItemProps[] = [
  {
    type: ButtonType.Home,
    image: '/icon_home_passive.svg',
    hightlight: '/icon_home_active.svg',
    text: 'ホーム',
    cssParams: navbarCss,
    active: false,
    disableImage: '/icon_home_active.svg',
    disable: false,
  },
  {
    type: ButtonType.Favorite,
    image: '/icon_collection_passive.svg',
    hightlight: '/icon_collection_active.svg',
    text: 'お気に入り',
    cssParams: navbarCss,
    active: false,
    disableImage: '/icon_collection_inactive.svg',
    disable: false,
  },
  {
    type: ButtonType.History,
    image: '/icon_truck_active.svg',
    hightlight: '/icon_truck_passive.svg',
    text: '購入履歴',
    cssParams: navbarCss,
    active: false,
    disableImage: '/icon_truck_inactive.svg',
    disable: false,
  },
  {
    type: ButtonType.Cart,
    image: '/icon_cart_active.svg',
    hightlight: '/icon_cart_passive.svg',
    text: 'カート',
    cssParams: navbarCss,
    active: false,
    disableImage: '/icon_cart_inactive.svg',
    disable: false,
  },
  {
    type: ButtonType.Setting,
    image: '/icon_account_active.svg',
    hightlight: '/icon_account_passive.svg',
    text: '設定',
    cssParams: navbarCss,
    active: false,
    disableImage: '/icon_account_inactive.svg',
    disable: false,
  },
  {
    type: ButtonType.Coupon,
    image: '/icon_coupon_active.svg',
    hightlight: '/icon_coupon_passive.svg',
    text: 'クーポン',
    cssParams: navbarCss,
    active: false,
    disableImage: '/icon_coupon_inactive.svg',
    disable: false,
  },
  {
    type: ButtonType.Inquiry,
    image: '/icon_inquiry.svg',
    hightlight: '/icon_inquiry.svg',
    text: 'お問い合わせ',
    cssParams: [tw`min-w-44px h-full`],
    active: false,
    disableImage: '/icon_inquiry_inactive.svg',
    disable: false,
  },
]

const mobileLayoutCss: TwStyle[] = [tw`w-1/5`, tw`h-full`]
export const MOBILE_NAV_MENU: TabBarItemProps[] = [
  {
    type: ButtonType.Menu,
    image: '/icon_menu_passive.svg',
    hightlight: '/icon_menu_active.svg',
    text: 'メニュー',
    cssParams: mobileLayoutCss,
    active: false,
    disableImage: '/icon_menu_active.svg',
    disable: false,
  },
  {
    type: ButtonType.Home,
    image: '/icon_home_passive.svg',
    hightlight: '/icon_home_active.svg',
    text: 'ホーム',
    cssParams: mobileLayoutCss,
    active: false,
    disableImage: '/icon_home_active.svg',
    disable: false,
  },
  {
    type: ButtonType.Search,
    image: '/icon_search_passive.svg',
    hightlight: '/icon_search_active.svg',
    text: '探す',
    cssParams: mobileLayoutCss,
    active: false,
    disableImage: '/icon_search_active.svg',
    disable: false,
  },
  {
    type: ButtonType.Favorite,
    image: '/icon_collection_passive.svg',
    hightlight: '/icon_collection_active.svg',
    text: 'お気に入り',
    cssParams: mobileLayoutCss,
    active: false,
    disableImage: '/icon_collection_inactive.svg',
    disable: false,
  },
  {
    type: ButtonType.Cart,
    image: '/icon_cart_active.svg',
    hightlight: '/icon_cart_passive.svg',
    text: 'カート',
    cssParams: mobileLayoutCss,
    active: false,
    disableImage: '/icon_cart_inactive.svg',
    disable: false,
  },
]
