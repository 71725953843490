import tw from 'twin.macro'
import { Button } from 'src/components/Button/Button'
import { Portal } from 'src/components/Portal/Portal'

export interface Props {
  message?: string
  title?: string
  buttonTitle?: string
  onButtonClick: () => void
  img?: string
}
export function ImageAlertModal({ message, title, buttonTitle, onButtonClick, img }: Props) {
  return (
    <Portal>
      <Overlay>
        <div tw="relative flex flex-col justify-between items-center w-11/12 max-w-lg bg-white rounded-lg">
          {img && <img tw="w-16 h-16 mt-8" src={img} />}
          <p tw="text-center text-18px font-bold text-green-deep whitespace-pre-wrap line-height[25px] my-6">{title}</p>
          <p tw="text-center text-14px line-height[22px] text-black whitespace-pre">{message}</p>
          <Button
            type="button"
            tw="bg-white text-black border border-black w-4/5 flex-shrink-0 my-8"
            onClick={onButtonClick}
          >
            <span tw="text-14px text-black leading-4">{buttonTitle}</span>
          </Button>
        </div>
      </Overlay>
    </Portal>
  )
}
const Overlay = tw.div`fixed top-0 left-0 flex justify-center items-center w-full h-full z-10 bg-black bg-opacity-75 px-2`
