import { useEffect, useState } from 'react'

export const START_DATETIME = new Date('2023-12-29T17:00:00+09:00')
export const END_DATETIME = new Date('2024-01-04T13:00:00+09:00')

export const useBuyable = () => {
  //年末年始対応　指定の期間は購入できない
  const [buyable, setBuyable] = useState(true)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date()
      if (START_DATETIME <= now && now < END_DATETIME) {
        setBuyable(false)
      } else {
        setBuyable(true)
      }
    }, 1000)
    return () => clearInterval(intervalId)
  }, [buyable])

  return buyable
}
