import { createGlobalStyle } from 'styled-components'

export const AppGlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  } 
  body {
    height: 100%;
    background-color: white;    
  }
  #__next {
    height: 100%;
  }
`
