import tw from 'twin.macro'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { ParsedUrlQuery } from 'querystring'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { Route, RouteValue } from 'src/consts/route'
import { useCurrentUser } from 'src/contexts/currentUser'
import {
  AllCategoriesWithCountsDocument,
  AllCategoriesWithCountsQuery,
  ProductDetailsQuery,
} from 'src/generated/graphql'
import { useQuery } from 'urql'
import { SPECIAL_FEATURE } from 'src/consts/specialFeature'
import { SpecialType } from 'src/consts/specialType'
import { PRODUCT_CATEGORY_MAP } from 'src/consts/breadcrumbPathMap'
import { toDateInJapanese } from 'src/util/date'
import { ProductInfoContext } from 'src/contexts/commonContext'
import { ExcludeSpecialTag } from 'src/consts/specialTag'

interface BreadcrumbHistory {
  pathname: string
  query: ParsedUrlQuery
  asPath: string
}
type BreadcrumbItem = {
  name: string
  path?: string
}
type IBreadcrumb = {
  [key in RouteValue]: BreadcrumbItem[]
}

export function BreadcrumbNew({ setIsHasBreadcrumb }: { setIsHasBreadcrumb: (has: boolean) => void }) {
  const router = useRouter()

  const { isOnboarded } = useCurrentUser()

  const [currentRouter, setCurrentRouter] = useState<BreadcrumbHistory | null>(null)
  const [prevRouter, setPrevRouter] = useState<BreadcrumbHistory | null>(null)
  const [productInfo] = useContext(ProductInfoContext)

  const [AllCategoriesWithCounts] = useQuery({
    query: AllCategoriesWithCountsDocument,
    pause: !isOnboarded,
  })

  const breadcrumb = useMemo(() => {
    return _breadcrumb(
      router.pathname as RouteValue,
      router.query,
      prevRouter,
      productInfo,
      AllCategoriesWithCounts.data
    )
  }, [AllCategoriesWithCounts.data, router.pathname, router.query, prevRouter, productInfo])

  useEffect(() => {
    if (router.asPath !== currentRouter?.asPath) {
      if (currentRouter) {
        setPrevRouter({
          pathname: currentRouter.pathname,
          query: currentRouter.query,
          asPath: currentRouter.asPath,
        })
      }
      setCurrentRouter({
        pathname: router.pathname,
        query: router.query,
        asPath: router.asPath,
      })
    }
  }, [router, currentRouter, breadcrumb, prevRouter, AllCategoriesWithCounts.data])
  useEffect(() => {
    if (!breadcrumb || (breadcrumb && breadcrumb.length === 0)) setIsHasBreadcrumb(false)
  }, [breadcrumb, setIsHasBreadcrumb])
  if (!breadcrumb || (breadcrumb && breadcrumb.length === 0)) return null

  setIsHasBreadcrumb(true)
  return (
    <div tw="w-full bg-white" id="buyer_breadcrumb">
      <div tw="w-full landing-pc:max-w-1200px mx-auto landing-pc:px-0 px-4 py-14px leading-5 break-all">
        {breadcrumb?.map((b, index) => (
          <Fragment key={index}>
            <Link href={b.path || router.asPath}>
              <span
                tw="text-blackDarkest text-14px mr-2"
                css={[breadcrumb[index + 1] ? tw`font-medium cursor-pointer` : tw`font-bold cursor-default`]}
              >
                {b.name}
              </span>
            </Link>
            {breadcrumb[index + 1] && <img src="/overallUI/icon_arrow_right.svg" tw="mr-2 inline-block" />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

const _breadcrumb = (
  pathname: RouteValue,
  query: ParsedUrlQuery,
  preRouterHistory: BreadcrumbHistory | null | undefined,
  productInfo: ProductDetailsQuery['productDetails'] | null | undefined,
  AllCategories?: AllCategoriesWithCountsQuery
) => {
  const specialType: SpecialType = (query.type || query.source) as SpecialType
  const BREADCRUMB: IBreadcrumb = {
    '/order/history': [{ name: 'ホーム', path: Route.productSearch }, { name: '購入履歴' }],
    '/order/history/canceled': [
      { name: 'ホーム', path: Route.productSearch },
      { name: '購入履歴', path: Route.orderHistory },
      { name: 'キャンセル済み' },
    ],
    // @ts-expect-error TS2322
    '/product/vegetables': [{ name: 'ホーム', path: Route.productSearch }, { name: '今朝採れ野菜' }],
    '/product/meat': [{ name: 'ホーム', path: Route.productSearch }, { name: '特集『🍖精肉🥩』' }],
    '/product/asparagus': [{ name: 'ホーム', path: Route.productSearch }, { name: '特集『アスパラ』' }],
    '/product/chicken': [{ name: 'ホーム', path: Route.productSearch }, { name: '特集『地鶏』' }],
    '/product/mushroom': [{ name: 'ホーム', path: Route.productSearch }, { name: '特集『🍄季節到来！きのこ特集🍄』' }],
    '/product/special/freighter': [
      { name: 'ホーム', path: Route.productSearch },
      { name: '鮮度直行 “産直エア”サービスがスタート' },
    ],
    '/product/special/[type]': [
      { name: 'ホーム', path: Route.productSearch },
      { name: SPECIAL_FEATURE[specialType]?.breadcrumbName },
    ],
    '/cart': [{ name: 'ホーム', path: Route.productSearch }, { name: 'カート' }],
    '/product/favorite': [{ name: 'ホーム', path: Route.productSearch }, { name: 'お気に入り' }],
    '/setting': [{ name: 'ホーム', path: Route.productSearch }, { name: '設定' }],
    '/terms-and-conditions': [{ name: '利用規約' }],
    '/scta': [{ name: '特定商品取引法に基づく表記' }],
  }
  if (pathname === Route.productSearchResult && AllCategories) {
    //検索結果画面
    const productSearch: BreadcrumbItem[] = [{ name: 'ホーム', path: Route.productSearch }]
    productSearchBreadcrumb(query, productSearch, AllCategories)
    return productSearch
  } else if (pathname === Route.productDetail && AllCategories) {
    let productDetail: BreadcrumbItem[] = [{ name: 'ホーム', path: Route.productSearch }]
    if (preRouterHistory && preRouterHistory.pathname === Route.productSearch) {
    } else if (preRouterHistory && preRouterHistory.pathname === Route.productSearchResult) {
      productSearchBreadcrumb(preRouterHistory?.query, productDetail, AllCategories)
      productDetail[productDetail.length - 1].path = preRouterHistory?.asPath
    } else if (preRouterHistory && preRouterHistory.pathname === Route.productSearchResultOld) {
      productSearchResultOldBreadcrumb(preRouterHistory?.query, productDetail)
      productDetail[productDetail.length - 1].path = preRouterHistory?.asPath
    } else if (preRouterHistory && BREADCRUMB.hasOwnProperty(preRouterHistory.pathname)) {
      productDetail = BREADCRUMB[preRouterHistory.pathname as RouteValue]
      productDetail[productDetail.length - 1].path = preRouterHistory?.asPath
    } else {
      productInfo?.category &&
        productDetail.push({
          name: productInfo?.category?.name,
          path: `${Route.productSearchResult}/?categoryId=${productInfo?.category?.id}`,
        })
      productInfo?.subCategories &&
        productDetail.push({
          name: productInfo?.subCategories[0]?.name,
          path: `${Route.productSearchResult}/?subcategoryId=${productInfo?.subCategories[0]?.id}`,
        })
      productInfo?.miniCategories &&
        productDetail.push({
          name: productInfo?.miniCategories[0]?.name,
          path: `${Route.productSearchResult}/?minicategoryId=${productInfo?.miniCategories[0]?.id}`,
        })
    }
    productDetail.push({ name: '商品詳細' })
    return productDetail
  } else if (pathname === Route.productSearchResultOld) {
    const productSearch: BreadcrumbItem[] = [{ name: 'ホーム', path: Route.productSearch }]
    productSearchResultOldBreadcrumb(query, productSearch)
    return productSearch
  } else {
    return BREADCRUMB[pathname]
  }
}

const productSearchBreadcrumb = (
  query: ParsedUrlQuery,
  productSearch: BreadcrumbItem[],
  AllCategories: AllCategoriesWithCountsQuery
) => {
  const category = AllCategories?.categoriesWithCounts
  const subCategory = AllCategories?.subCategoriesWithCounts
  const miniCategory = AllCategories?.miniCategoriesWithCounts
  const hasCategory =
    query.hasOwnProperty('categoryId') ||
    query.hasOwnProperty('subcategoryId') ||
    query.hasOwnProperty('minicategoryId')
  if (!query.hasOwnProperty('productCategory')) {
    //特集・タグを含まない
    if (query.hasOwnProperty('categoryId')) {
      //カテゴリ
      const categoryName =
        query.categoryId === '999'
          ? '全ての食材（カテゴリ順）'
          : category.find((cate) => cate.id === query.categoryId)?.name
      categoryName &&
        productSearch.push({
          name: categoryName,
          path: `${Route.productSearchResult}/?categoryId=${query.categoryId}`,
        })
    } else if (query.hasOwnProperty('subcategoryId')) {
      //中カテゴリ
      const subItem = subCategory.find((sub) => sub.id === query.subcategoryId)
      if (subItem && subItem.parentCategoryId) {
        const categoryItem = subItem && category.find((cate) => cate.id === subItem.parentCategoryId?.toString())
        categoryItem &&
          productSearch.push({
            name: categoryItem.name,
            path: `${Route.productSearchResult}/?categoryId=${categoryItem.id}`,
          })
        productSearch.push({
          name: subItem.name,
          path: `${Route.productSearchResult}/?subcategoryId=${query.subcategoryId}`,
        })
      }
    } else if (query.hasOwnProperty('minicategoryId')) {
      //小カテゴリ
      const miniItem = miniCategory.find((mini) => mini.id === query.minicategoryId)
      if (miniItem && miniItem.subCategoryId) {
        const subItem = subCategory.find((sub) => sub.id === miniItem.subCategoryId?.toString())
        const categoryItem = subItem && category.find((cate) => cate.id === subItem.parentCategoryId?.toString())
        categoryItem &&
          productSearch.push({
            name: categoryItem.name,
            path: `${Route.productSearchResult}/?categoryId=${categoryItem.id}`,
          })
        subItem &&
          productSearch.push({
            name: subItem?.name,
            path: `${Route.productSearchResult}/?subcategoryId=${subItem.id}`,
          })
        productSearch.push({
          name: miniItem.name,
          path: `${Route.productSearchResult}/?minicategoryId=${query.minicategoryId}`,
        })
      }
    }
  } else if (query.hasOwnProperty('productCategory') && !hasCategory) {
    //特集・タグ
    const productCategory =
      query.productCategory && typeof query.productCategory === 'string' ? query.productCategory.split('=') : []
    const tagNames = productCategory.map(
      (tag) => PRODUCT_CATEGORY_MAP[tag] || (ExcludeSpecialTag.includes(tag) ? '' : tag)
    )
    productSearch.push({
      name: tagNames.join('、'),
      path: `${Route.productSearchResult}/?productCategory=${query.productCategory}`,
    })
  } else if (query.hasOwnProperty('productCategory') && hasCategory && !query.hasOwnProperty('deliveryDates')) {
    productSearch.push({
      name: '検索結果',
    })
  }
  if (Object.keys(query).length === 0) {
    productSearch.push({
      name: PRODUCT_CATEGORY_MAP.Latest,
    })
  }
  if (query.hasOwnProperty('deliveryDates')) {
    const routePath = []
    for (const key in query) {
      if (key !== 'singleDeliveryDate') routePath.push(key + '=' + query[key])
    }
    const preRouterPath = `${Route.productSearchResult}/?${routePath.join('&')}`
    productSearch.push({
      name: '検索結果',
      path: preRouterPath,
    })
  }
  if (!!query.singleDeliveryDate && typeof query.singleDeliveryDate === 'string') {
    productSearch.push({
      name: `${toDateInJapanese(query.singleDeliveryDate)}にお届け可能な食材`,
    })
  }
  if (productSearch.length === 1) {
    productSearch.push({
      name: '検索結果',
    })
  }
}
const productSearchResultOldBreadcrumb = (query: ParsedUrlQuery, productSearch: BreadcrumbItem[]) => {
  if (query.productCategory) {
    productSearch.push({
      name: PRODUCT_CATEGORY_MAP[query.productCategory as string],
    })
  }
}
