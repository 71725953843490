import tw from 'twin.macro'
import React, { useEffect, useRef } from 'react'

import { Portal } from 'src/components/Portal/Portal'

export interface Props {
  onModalClose?: () => void
}
export function YcfJudgingStatusModal({ onModalClose }: Props) {
  const rootDivRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const refDiv = rootDivRef?.current
    const eventListener = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
    }
    refDiv?.addEventListener('touchmove', eventListener)

    refDiv?.addEventListener('wheel', eventListener)
    return () => {
      refDiv?.removeEventListener('touchmove', eventListener)
      refDiv?.removeEventListener('wheel', eventListener)
    }
  }, [])

  return (
    <Portal>
      <Overlay ref={rootDivRef}>
        <div tw="relative flex flex-col max-w-lg rounded-ml bg-white w-full">
          <div tw="mt-14 text-14px line-height[22px] my-8 mx-6 text-black">
            <div tw="flex flex-col justify-center items-center">
              <img src="/icon_ycf_modal_Judging.svg" />
              <p tw="text-18px line-height[25px] font-bold text-orange mt-1">利用審査中です</p>
            </div>
            <div tw="flex flex-row items-center justify-center">
              <img tw="mt-6 mx-10" src="/icon_judging_modal_kurokake.svg" />
            </div>

            <div tw="mt-20px mb-8 text-justify font-normal">
              <p>お申し込みいただいた決済サービス「クロネコ掛け払い」の審査が完了しておりません。</p>
              <p tw="mt-2">
                審査には通常30分～数日ほどお時間をいただいております。審査完了次第、商品のご購入が可能になりますので、もうしばらくお待ちください。
              </p>
            </div>
            <div
              onClick={onModalClose}
              tw="h-11 flex flex-auto justify-center items-center border border-solid border-black rounded-full mx-28px leading-4 cursor-pointer"
            >
              <p tw="">閉じる</p>
            </div>
          </div>
        </div>
      </Overlay>
    </Portal>
  )
}

const Overlay = tw.div`fixed top-0 left-0 flex justify-center items-center w-full h-full z-index[11] bg-black bg-opacity-75 px-6`
