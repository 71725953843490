import tw, { TwStyle } from 'twin.macro'

interface Props {
  children: React.ReactNode
  type?: 'submit' | 'reset' | 'button'
  className?: string
  onClick?: () => void
  primary?: boolean
  secondary?: boolean
  disabled?: boolean
  css?: (string | TwStyle)[]
  outline?: boolean
}

export function Button({
  type = 'button',
  children,
  className,
  onClick,
  primary,
  secondary,
  disabled = false,
  outline,
}: Props) {
  return (
    <button
      type={type}
      className={className}
      disabled={disabled}
      css={[
        tw`w-full rounded-full h-44px`,
        primary && tw`text-white bg-orange`,
        secondary && tw`text-orange-yfm bg-white border border-orange`,
        disabled && tw`cursor-default`,
        outline && tw`bg-white border border-green text-green focus:outline-none`,
      ]}
      onClick={onClick}
      tw="disabled:bg-placeholder"
    >
      {children}
    </button>
  )
}
