import tw from 'twin.macro'
import NavBar from 'src/components/NavBar/NavBar'
import { Breadcrumb } from 'src/components/Breadcrumb/Breadcrumb'
import { UserType } from 'src/generated/graphql'
import { useCurrentUser } from 'src/contexts/currentUser'

export function SellerHeader({}) {
  const { showRole, role } = useCurrentUser()
  const showSeller = showRole === UserType.Buyer
  const isBuyer = role === UserType.Buyer

  return (
    <div css={[isBuyer || showSeller ? tw`hidden` : tw`block`]}>
      <NavBar />
      <Breadcrumb />
    </div>
  )
}
