import tw from 'twin.macro'
import React, { useEffect, useRef } from 'react'

import { Portal } from 'src/components/Portal/Portal'
import { URL } from 'src/consts/URLs'
import { STATUS_SUPPORT_TEL } from 'src/consts/service'

export interface Props {
  onModalClose?: () => void
}
export function YcfStopStatusModal({ onModalClose }: Props) {
  const rootDivRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const refDiv = rootDivRef?.current
    const eventListener = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
    }
    refDiv?.addEventListener('touchmove', eventListener)

    refDiv?.addEventListener('wheel', eventListener)
    return () => {
      refDiv?.removeEventListener('touchmove', eventListener)
      refDiv?.removeEventListener('wheel', eventListener)
    }
  }, [])

  return (
    <Portal>
      <Overlay ref={rootDivRef}>
        <div tw="relative flex flex-col max-w-lg rounded-ml bg-white w-full">
          <div tw="mt-14 text-14px line-height[22.4px] my-8 mx-6 text-black">
            <div tw="flex flex-col justify-center items-center">
              <img src="/icon_ycf_modal_Unavailable.svg" />
              <p tw="text-18px line-height[25px] font-bold text-red mt-1">サービス利用停止中です</p>
            </div>
            <div tw="mt-6 mb-8 text-justify font-normal">
              <p>過去の請求分についてのお支払いが確認できないため、ご利用を制限しております。</p>
              <p tw="mt-2">
                お支払いが確認でき次第、ご注文が可能になりますので、お手数ですがお支払い状況をご確認下さい。
              </p>
              <p tw="mt-2 mb-2">【請求についてのお問合せはこちら】</p>
              <p tw="mt-2">ヤマトクレジットファイナンス：</p>
              <a href={URL.STATUS_CONTACT_WITH_PHONE}>
                <span tw="underline cursor-pointer">{STATUS_SUPPORT_TEL}</span>
              </a>
            </div>
            <div
              onClick={onModalClose}
              tw="h-11 flex flex-auto justify-center items-center border border-solid border-black rounded-full mx-28px leading-4 cursor-pointer"
            >
              <p tw="">閉じる</p>
            </div>
          </div>
        </div>
      </Overlay>
    </Portal>
  )
}

const Overlay = tw.div`fixed top-0 left-0 flex justify-center items-center w-full h-full z-index[11] bg-black bg-opacity-75 px-6`
