import tw from 'twin.macro'
import { TabBar } from 'src/components/TabBar/TabBar'
import { useEffect, useMemo, useState, useContext } from 'react'
import { Drawer } from 'src/components/Drawer/Drawer'
import { useRouter } from 'next/router'
import { Route } from 'src/consts/route'
import { useCurrentUser } from 'src/contexts/currentUser'
import { UserType } from 'src/generated/graphql'
import { useCartState } from 'src/contexts/cart'
import { useImmer } from 'use-immer'
import { throttle } from 'lodash'
import { ButtonType, MOBILE_NAV_MENU } from 'src/consts/navMenuItems'
import { useAuth0 } from '@auth0/auth0-react'
import { leftMemuItems } from 'src/consts/leftMemuItems'
import { ProductInfoContext } from 'src/contexts/commonContext'

export function MobileMenu({}) {
  const [activeTab, setActiveTab] = useState<ButtonType>(ButtonType.None)
  const [lastActiveTab, setLastActiveTab] = useState<ButtonType>(activeTab)
  const [showDrawer, setShowDrawer] = useState(false)
  const { pathname } = useRouter()
  const { isAuthenticated } = useAuth0()
  const { role, isOnboarded } = useCurrentUser()
  const isBuyer = role === UserType.Buyer
  const { showRole } = useCurrentUser()
  const showSeller = showRole === UserType.Buyer
  const { currentCartItems } = useCartState()
  const [, , , , , , , setDisplayBackTop] = useContext(ProductInfoContext)
  const router = useRouter()
  useEffect(() => {
    setDisplayBackTop(!showDrawer)
  }, [setDisplayBackTop, showDrawer])

  const btnClicked = (type: ButtonType, isActive: boolean) => {
    setShowDrawer(false)
    switch (type) {
      case ButtonType.Menu:
        if (!isActive) setLastActiveTab(activeTab)
        isActive ? setActiveTab(lastActiveTab) : setActiveTab(type)
        setShowDrawer(!isActive)
        break
      case ButtonType.Home:
        router.push(Route.productSearch)
        setActiveTab(type)
        break
      case ButtonType.Favorite:
        router.push(Route.favorite)
        setActiveTab(type)
        break
      case ButtonType.Cart:
        router.push(Route.cart)
        setActiveTab(type)
        break
      case ButtonType.Search:
        router.push({ pathname: Route.mobileSearch })
        setActiveTab(type)
        break
      default:
        break
    }
  }

  const btnClickedThrottle = throttle(btnClicked, 500, {
    leading: true,
    trailing: false,
  })

  const [btnArrsImmer, setBtnArrsImmer] = useImmer(MOBILE_NAV_MENU)

  useEffect(() => {
    setBtnArrsImmer((draft) => {
      draft.map((o) => {
        if (o.type === ButtonType.Cart) {
          o.cartCount = currentCartItems.length
        }
      })
    })
  }, [currentCartItems.length, setBtnArrsImmer])

  useEffect(() => {
    setBtnArrsImmer((draft) => {
      draft.map((o) => {
        if (showSeller && (o.type === ButtonType.Cart || o.type === ButtonType.Favorite)) {
          o.disable = true
        }
      })
    })
  }, [showSeller, setBtnArrsImmer])

  useEffect(() => {
    //タブボタンの状態を切り替える
    setBtnArrsImmer((draft) => {
      draft.map((o) => {
        if (o.type === activeTab) {
          o.active = true
        } else {
          o.active = false
        }
      })
    })
  }, [activeTab, setBtnArrsImmer])

  const [mobileleftMemuItems, setMobileLeftMemuItems] = useImmer(leftMemuItems)

  useEffect(() => {
    setMobileLeftMemuItems((draft) => {
      draft.map((o) => {
        if (showSeller && (o.title === '購入履歴' || o.title === '設定' || o.title === 'クーポン')) {
          o.disable = true
        }
      })
    })
  }, [showSeller, setMobileLeftMemuItems])

  const hideNavMenu = useMemo(() => {
    const isLandingPage = pathname === Route.landing
    const isCampaignLanding = pathname === Route.campaignLanding
    if (isLandingPage || isCampaignLanding) return false

    if (!isAuthenticated) return false

    if (
      Route.onboarding === pathname ||
      Route.verifyEmail === pathname ||
      Route.sendVerificationEmail === pathname ||
      Route.ycfJudge === pathname
    ) {
      return false
    }

    const hideMenuIsOnboardedPathname: string[] = [Route.scta, Route.termsAndConitions]

    if (!isOnboarded && hideMenuIsOnboardedPathname.includes(pathname)) {
      return false
    }

    return true
  }, [isAuthenticated, isOnboarded, pathname])

  useEffect(() => {
    setShowDrawer(false)
    switch (pathname) {
      case Route.productSearch:
        setActiveTab(ButtonType.Home)
        break
      case Route.mobileSearch:
        setActiveTab(ButtonType.Search)
        break
      case Route.cart:
        setActiveTab(ButtonType.Cart)
        break
      case Route.favorite:
        setActiveTab(ButtonType.Favorite)
        break
      default:
        setActiveTab(ButtonType.None)
        break
    }
  }, [pathname])

  const closeDrawer = () => {
    setShowDrawer(false)
    setActiveTab(lastActiveTab)
  }

  return (
    <div tw="w-full p-0 m-0">
      {hideNavMenu && (showSeller || isBuyer) && (
        <div
          tw="landing-pc:hidden"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div tw="w-full h-16" />
          <Drawer showDrawer={showDrawer} leftMemuItems={mobileleftMemuItems} closeDrawer={closeDrawer}></Drawer>
          <div tw="fixed left-0 bottom-0 h-16 w-screen bg-gray-alabaster z-10">
            <TabBar buttons={btnArrsImmer} clickAction={btnClickedThrottle} cssParams={[tw`pt-2`]} />
          </div>
        </div>
      )}
    </div>
  )
}
