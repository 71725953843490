import { SpecialParams } from 'src/pages/product/special/[type]'
import { ItemListType } from './ItemListType'
import { SpecialType } from './specialType'
import { SERVICE_FEE_NEXTDAY_DELIVERY_NOTES } from './service'

export const SPECIAL_FEATURE: {
  [key in keyof typeof SpecialType]: SpecialParams
} = {
  [SpecialType.FreshFish]: {
    topImage: `https://${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/assets/product/top_fish.jpg`,
    breadcrumbName: '特集『🐟魚介🐟』',
    title: 'こだわりの新鮮魚介をお届けします',
    description:
      'お待たせ致しました！高知県や宮城県の豊かな海で育った新鮮魚介の取り扱いを開始致しました！商品は順次追加予定となりますので、ご期待下さい！',
    specialItemListType: 'FreshFish',
    itemListName: ItemListType.FreshFish,
    itemListId: 'freshFish',
  },
  [SpecialType.Mushroom]: {
    topImage: `https://${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/assets/product/banner_mushroom.jpg`,
    breadcrumbName: '特集『🍄季節到来！きのこ特集🍄』',
    title: '🍄季節到来！きのこ特集🍄',
    description:
      'たもぎ茸やトキイロヒラタケ、柳松茸など、色や形も個性的なきのこが勢揃い！天ぷらやソテーは勿論、ジャンボなめこの素揚げや、農家イチオシの琥珀茸のペペロンチーノなど、味わい方も多種多様です！',
    specialItemListType: 'Mushroom',
    itemListName: ItemListType.Mushroom,
    itemListId: 'mushroom',
  },
  [SpecialType.NextdayDelivery]: {
    topImage: `https://${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/assets/product/banner_nextdayDelivery.jpg`,
    breadcrumbName: '特集『こだわりの青果を明日納品！』',
    title: 'こだわりの青果を明日納品！',
    boldDescription: '『明日納品』商品の注文締切時間延長！納品希望日の当日AM4:00まで！',
    description: `生産者様こだわりの青果をヤマトフードマーケットにて大切に保管し、お届けいたします！\n${SERVICE_FEE_NEXTDAY_DELIVERY_NOTES}\n※同じ配送希望日に、「明日納品」商品と通常商品の納品がある場合、別便でのお届けになります。`,
    redDescription: '※納品希望日の当日AM4:00までご注文を受け付けております。',
    specialItemListType: 'NextdayDelivery',
    itemListName: ItemListType.NextdayDelivery,
    itemListId: 'nextdayDelivery',
  },
  [SpecialType.Unknow]: {
    topImage: '',
    breadcrumbName: '',
    title: '',
    description: '',
    specialItemListType: '',
    itemListName: '',
    itemListId: '',
  },
}
