export enum SpecialType {
  FreshFish = 'FreshFish',
  Mushroom = 'Mushroom',
  NextdayDelivery = 'NextdayDelivery',
  Unknow = 'Unknow',
}

export const convertToSpecialType = (input: string) => {
  let result = SpecialType.Unknow
  switch (input) {
    case SpecialType.FreshFish:
      result = SpecialType.FreshFish
      break
    case SpecialType.Mushroom:
      result = SpecialType.Mushroom
      break
    case SpecialType.NextdayDelivery:
      result = SpecialType.NextdayDelivery
      break
    default:
      result = SpecialType.Unknow
      break
  }
  return result
}
