import React, { createContext, useState } from 'react'
import { ProductDetailsQuery } from 'src/generated/graphql'

type ProductDetails = ProductDetailsQuery['productDetails']
type PurchaseStatus = boolean
type YcfJudgeStatus = string
type DisplayBackTop = boolean
export const ProductInfoContext = createContext<
  [
    ProductDetails,
    React.Dispatch<ProductDetails>,
    PurchaseStatus,
    React.Dispatch<boolean>,
    YcfJudgeStatus,
    React.Dispatch<string>,
    DisplayBackTop,
    React.Dispatch<boolean>
  ]
>([null, () => {}, false, () => {}, '', () => {}, true, () => {}])

export function CommonContextProvider({ children }: { children: React.ReactNode }) {
  const [productInfo, setProductInfo] = useState<ProductDetails | null>(null)
  const [purchaseStatus, setPurchaseStatus] = useState<boolean>(false)
  const [ycfJudgeStatus, setYcfJudgeStatus] = useState<string>('')
  const [displayBackTop, setDisplayBackTop] = useState<boolean>(true)
  return (
    <ProductInfoContext.Provider
      value={[
        productInfo,
        setProductInfo,
        purchaseStatus,
        setPurchaseStatus,
        ycfJudgeStatus,
        setYcfJudgeStatus,
        displayBackTop,
        setDisplayBackTop,
      ]}
    >
      {children}
    </ProductInfoContext.Provider>
  )
}
