import { TwStyle } from 'twin.macro'
import { TabBarItem, TabBarItemProps } from 'src/components/TabBar/TabBarItem'
import { ButtonType } from 'src/consts/navMenuItems'
import { useEffect, useRef } from 'react'

export interface TabBarProps {
  buttons: TabBarItemProps[]
  cssParams?: (string | TwStyle)[]
  clickAction: (key: ButtonType, isActive: boolean) => void
}

export function TabBar(inputProps: TabBarProps) {
  const tabBarRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const refDiv = tabBarRef?.current
    const eventListener = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
    }
    refDiv?.addEventListener('touchmove', eventListener)

    refDiv?.addEventListener('wheel', eventListener)
    return () => {
      refDiv?.removeEventListener('touchmove', eventListener)
      refDiv?.removeEventListener('wheel', eventListener)
    }
  }, [])
  return (
    <div
      ref={tabBarRef}
      tw="flex flex-row"
      css={[inputProps.cssParams]}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {inputProps.buttons.map((config, index) => (
        <TabBarItem
          key={index}
          type={config.type}
          image={config.image}
          hightlight={config.hightlight}
          text={config.text}
          active={config.active}
          cssParams={config.cssParams}
          cartCount={config.cartCount}
          clickAction={inputProps.clickAction}
          disable={config.disable}
          disableImage={config.disableImage}
        />
      ))}
    </div>
  )
}
