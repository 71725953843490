// @ts-expect-error TS6133
import tw, { TwStyle } from 'twin.macro'
import { Button } from 'src/components/Button/Button'
import { Portal } from 'src/components/Portal/Portal'
import { Overlay } from 'src/components/Overlay/Overlay'

export interface Props {
  OverlayCss?: (string | TwStyle)[]
  message?: string
  buttonTitle?: string
  onButtonClick: () => void
}

export function AlertModal({ OverlayCss, message, buttonTitle, onButtonClick }: Props) {
  return (
    <Portal>
      <Overlay css={OverlayCss}>
        <div tw="relative flex flex-col justify-between items-center w-11/12 max-w-lg bg-white rounded-lg p-24px">
          <p tw="text-center text-18px font-bold text-green-deep whitespace-pre-wrap">{message}</p>
          <Button type="button" tw="bg-orange text-white w-4/5 flex-shrink-0 mt-16px" onClick={onButtonClick}>
            <span tw="text-14px">{buttonTitle}</span>
          </Button>
        </div>
      </Overlay>
    </Portal>
  )
}
