export const URL = {
  DEAL_WITH_PERSONAL_INFORMATION: 'https://www.kuronekoyamato.co.jp/ytc/privacy/',

  TAP_CONTACT_WITH_PHONE: 'tel:0345642161',

  TAP_CONTACT_WITH_EMAIL: 'mailto:yfm0301@kuronekoyamato.co.jp',

  MAILER_INQUIRY_FORM: 'https://business.form-mailer.jp/lp/7b586e9f179574',

  TERMS_KAKEBARAI_CUSTOMER: 'https://www.yamatofinancial.jp/document/n4038q0000002asq-att/terms_kakebarai_customer.pdf',

  FREQUENTLY_ASKED_QUESTIONS:
    'https://lily-pear-58c.notion.site/Yamato-Food-Market-FAQ-4712e866fd4c4374ba55659b5b6df55f',

  FREQUENTLY_ASKED_QUESTIONS_NO_LOG:
    'https://lily-pear-58c.notion.site/Yamato-Food-Market-FAQ-8c3ef7f39f444ff3b8c79ad3c96b57f6',

  STATUS_CONTACT_WITH_PHONE: 'tel:0359561475',

  MORE_STORAGE_INFO: 'https://www.notion.so/6adb02b18544430f911749aaebc5d1ce',

  SETTING_DETAILS_PRODUCT: 'https://www.notion.so/34ce52136de64815889676b400c7c16a',

  INVOICE_NO_PRODUCERS: 'https://www.notion.so/c58b3a7eed334401b3afaac88a86a6cd',
}

export const QUESTIONNAIRE_FORM_URL = {
  SELLER_SIGNUP: 'https://business.form-mailer.jp/lp/40d47bb9171667',
}
