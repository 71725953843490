import hotjar from '@hotjar/browser'
import { UserType } from 'src/generated/graphql'

// https://hotjar.github.io/hotjar-js/

export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID
export const HOTJAR_VERSION = 6

export const initHotjar = () => {
  hotjar.init(HOTJAR_ID, HOTJAR_VERSION)
}

export const identifyByHotjar = (userId, userType, shopName, productId) => {

  // 飲食店のみ、userIdを連携する
  if (userType !== UserType.Buyer) {
    return
  }
  hotjar.identify(userId, {
    user_type: userType,
    shop_name: shopName,
    product_id: productId, // 最後に購入した商品のID
  })
}