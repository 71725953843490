import {
  toViewItemEventObject,
  toViewDetailItemEventObject,
  toAddToCartEventObject,
  toRemoveFromCartEventObject,
  toViewItemListEventObject,
  toViewItemPageListEventObject,
  toBeginCheckoutEventObject,
  toPurchseSuccessEventObject,
  toAddToWishlistEventObject,
} from 'src/util/gtagEventObjectFactory'

import { UserType } from 'src/generated/graphql'

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID
export const GA_DEBUG_MODE = process.env.NEXT_PUBLIC_GA_DEBUG_MODE

export const GA_EVENT_CATEGORY = {
  User: 'User',
  Order: 'Order',
  News: 'News',
  Search: 'Search',
  ChangeEmail: 'ChangeEmail',
  Campaign: 'Campaign',
  RestockNotice: 'RestockNotice',
}

export const GA_EVENT_ACTION = {
  Registration: 'Registration',
  UpdatePrInfo: 'UpdatePrInfo',
  UpdateCorporateInfo: 'UpdateCorporateInfo',
  UpdateBankInfo: 'UpdateBankInfo',
  BusinessClassification: 'BusinessClassification',
  Confirmation: 'Confirmation',
  Attempt: 'Attempt',
  Complete: 'Complete',
  Failure: 'Failure',
  Click: 'Click',
  Keyword: 'Keyword',
  CancelRegistration: 'CancelRegistration',
  RestockRegister: 'RestockRegister', //「再入荷の通知を受け取る」リンク
  RestockCancel: 'RestockCancel', //「通知設定を解除する」リンク
}

export const GA_EVENT_LABEL = {
  Seller: 'Seller',
  Buyer: 'Buyer',
  Product: 'Product',
  Caution: 'Caution',
  Warning: 'Warning',
  Info: 'Info',
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const logGtagPageview = (path, title) => {
  window.gtag('event', 'page_view', {
    page_title: title,
    page_path: path,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const logGtagEvent = ({ action, category, label, value = 0 }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

//https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce
export const logECEventPurchase = (
  cartItems,
  orderId,
  couponcode,
  couponDiscount
) => {
  window.gtag(
    'event',
    'purchase',
    toPurchseSuccessEventObject(cartItems, orderId, couponcode, couponDiscount)
  )
}

//https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#view_item_list
export const logECEventViewItemList = (itemList) => {
  window.gtag('event', 'view_item_list', toViewItemListEventObject(itemList))
}

//https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#view_item_list
export const logECEventViewItemPageList = (
  itemList,
  itemListName,
  itemListId
) => {
  window.gtag(
    'event',
    'view_item_list',
    toViewItemPageListEventObject(itemList, itemListName, itemListId)
  )
}
//https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?hl=ja#view_item
export const logECEventViewItem = (item) => {
  window.gtag('event', 'view_item', toViewItemEventObject(item))
}

//https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?hl=ja#view_item
export const logECEventViewDetailItem = (item, itemListName, itemListId) => {
  window.gtag(
    'event',
    'view_item',
    toViewDetailItemEventObject(item, itemListName, itemListId)
  )
}

//https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?hl=ja#add_to_cart
export const logECEventAddToCart = (
  item,
  selectedQuantity,
  itemListTypes,
  seller
) => {
  window.gtag(
    'event',
    'add_to_cart',
    toAddToCartEventObject(item, selectedQuantity, itemListTypes, seller)
  )
}

//https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?hl=ja#remove_from_cart
export const logECEventRemoveFromCart = (item) => {
  window.gtag('event', 'remove_from_cart', toRemoveFromCartEventObject(item))
}

//https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?hl=ja#begin_checkout
export const logECEventBeginCheckout = (cartItems) => {
  window.gtag('event', 'begin_checkout', toBeginCheckoutEventObject(cartItems))
}

export const setGtagUserProperties = (userId, userType, shopName) => {
  // Mask seller id to avoid override buyer data
  if (userType === UserType.Seller) {
    userId = 'S' + userId
  }

  // https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id#set_user_id
  window.gtag('config', GA_TRACKING_ID, {
    user_id: userId,
  })
  //https://developers.google.com/analytics/devguides/collection/ga4/user-properties
  window.gtag('set', 'user_properties', {
    user_type: userType,
    shop_name: shopName,
  })
}

//https://developers.google.com/gtagjs/reference/ga4-events?hl=ja#add_to_wishlist
export const logECEventAddToWishlist = (item, itemListTypes, seller) => {
  window.gtag(
    'event',
    'add_to_wishlist',
    toAddToWishlistEventObject(item, itemListTypes, seller)
  )
}
