import { css } from 'styled-components'

export const Style = css`
  html {
    font-size: 62.5%;
    width: 100%;
  }
  body {
    font-family: 'Zen Kaku Gothic New', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Century Gothic',
      Helvetica, Arial, 'Droid Sans', sans-serif;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    color: #000;
    background: #fff;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    font-feature-settings: 'palt';
    letter-spacing: 0.1rem;
  }
  button {
    font-family: 'Zen Kaku Gothic New';
    cursor: pointer;
    outline: none;
  }
  button:focus {
    outline: none;
  }
  button:hover {
    outline: none;
  }
  main h1,
  main h2,
  main h3,
  main h4,
  main h5,
  main h6,
  main form,
  main p {
    line-height: 1.6;
  }
  a,
  a:visited {
    text-decoration: none;
  }
  img {
    vertical-align: bottom;
  }

  section {
    position: relative;
  }
  main {
    overflow: hidden;
  }

  .btn_resist {
    border: 8px solid #ffb480;
    box-shadow: 0px 4px 0px 0px rgba(202, 23, 23, 0.25);
    width: 100%;
    max-width: 478px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    text-align: center;
    border-radius: 50px;
    font-size: 24px;
    font-weight: 700;
    box-sizing: border-box;
    letter-spacing: 0.2em;
    transition: all 0.4s;
  }
  .btn_resist img {
    width: 10%;
    max-width: 20px;
    min-width: 16px;
    padding-right: 14px;
    box-sizing: content-box;
    transition: 0.3s all;
  }
  .btn_resist:hover {
    box-shadow: 0px 2px 0px 0px rgba(202, 23, 23, 0.25);
    border-color: #555;
    background: #000;
    color: #fff;
  }
  .btn_resist:hover img {
    -webkit-filter: invert(1);
    filter: invert(1);
  }
  .inquiry {
    background: #fff;
    border-radius: 20px;
    padding: 4% 6%;
    width: 100%;
  }
  .inquiry h3 {
    text-align: center;
    font-size: 25px;
    font-size: clamp(1.8rem, 2.4vw, 2.5rem);
    letter-spacing: 0.15em;
  }
  .inquiry h3 span {
    display: inline-block;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .inquiry > p {
    font-size: 14px;
    font-size: clamp(1.3rem, 1.4vw, 1.4rem);
    margin-bottom: 40px;
    text-align: center;
  }
  .inquiry ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 730px;
    margin: 0 auto;
  }
  .inquiry ul li {
    width: 49%;
    text-align: center;
  }
  .inquiry ul li dl {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .inquiry ul li dl dt {
    font-size: 12px;
    font-size: 1.2rem;
    padding-right: 10px;
    border-right: 1px dotted #000;
    margin-right: 10px;
  }
  .inquiry ul li dl dd {
    font-size: 14px;
    font-size: 1.4rem;
  }
  .inquiry ul li dl dd .note {
    font-size: 80%;
    letter-spacing: 0em;
  }
  .inquiry ul li a {
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    transition: all 0.3s;
    border: 2px solid #000;
    height: 60px;
    border-radius: 30px;
    box-sizing: border-box;
    letter-spacing: 0.1em;
    max-width: 350px;
  }
  .inquiry ul li a:hover {
    background: #fff;
    color: #000;
  }
  .inquiry ul li a:hover img {
    mix-blend-mode: difference;
  }
  .inquiry ul li a img {
    width: 10%;
    max-width: 20px;
    min-width: 14px;
    margin-right: 10px;
  }
  .inquiry ul li a.tel {
    font-size: 22px;
    font-size: clamp(1.8rem, 1.8vw, 2.2rem);
  }
  .inquiry ul li a.mail {
    font-size: 20px;
    font-size: clamp(1.5rem, 1.6vw, 2rem);
  }
  @media screen and (max-width: 650px) {
    .btn_resist {
      border-width: 6px;
      height: 70px;
      font-size: 18px;
    }
    .inquiry {
      padding: 8% 6%;
    }
    .inquiry h3 span {
      width: 80%;
    }
    .inquiry > p {
      margin-bottom: 30px;
    }
    .inquiry ul li {
      width: 100%;
      max-width: 255px;
      margin: 0 auto;
    }
    .inquiry ul li a {
      height: 50px;
    }
    .inquiry ul li a img {
      width: 14px;
      margin-right: 8px;
    }
    .inquiry ul li dl {
      margin-bottom: 30px;
    }
  }
`
