import tw from 'twin.macro'
import { Button } from 'src/components/Button/Button'
import { Portal } from 'src/components/Portal/Portal'

export interface Props {
  message: string | undefined
  buttonTitle: string | undefined
  onButtonClick?: () => void
}

export function SuccessModal({ message, buttonTitle, onButtonClick }: Props) {
  return (
    <Portal>
      <Overlay>
        <div tw="relative flex flex-col justify-between items-center w-11/12 h-3/5 max-h-96 max-w-lg bg-white rounded-lg">
          <div tw="flex justify-center mt-44px">
            <img src="/check-circle.svg" tw="my-auto" />
          </div>
          <p tw="text-center text-18px font-bold text-green-deep">{message}</p>
          <Button
            type="button"
            tw="mb-24px bg-orange text-white w-4/5 flex-shrink-0"
            onClick={() => onButtonClick && onButtonClick()}
          >
            <span tw="text-14px">{buttonTitle}</span>
          </Button>
        </div>
      </Overlay>
    </Portal>
  )
}

const Overlay = tw.div`fixed top-0 left-0 flex justify-center items-center w-full h-full z-10 bg-black bg-opacity-75 px-4`
