import { useInterval } from './useInterval'
import getConfig from 'next/config'
import { useUiDispatch } from 'src/contexts/ui'
import tw from 'twin.macro'

const { publicRuntimeConfig } = getConfig()

export function useDeployManager() {
  const dispatch = useUiDispatch()
  useInterval(async () => {
    try {
      const res = await fetch('/api/build-id/')
      const { buildId } = await res.json()
      if (buildId && buildId != publicRuntimeConfig.BUILD_ID) {
        dispatch({
          type: 'showConfirmModal',
          payload: {
            OverlayCss: [tw`z-index[100]`],
            buttonYesTitle: '再読み込み',
            buttonNoTitle: 'キャンセル',
            message: 'システム更新を行いました。お手数をおかけしますが、画面の再読み込みをお願い致します。',
            onButtonYesClick: () => {
              window.location.reload()
            },
          },
        })
      }
    } catch (error) {
      console.log('failed to fetch buildId', error)
    }
  }, 300000) // 5分おき

  return null
}
