import { css } from 'styled-components'

export const Style = css`
  html {
    font-size: 62.5%;
    width: 100%;
  }
  body {
    font-family: 'Zen Kaku Gothic New', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Century Gothic',
      Helvetica, Arial, 'Droid Sans', sans-serif;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    color: #000;
    background: #fff;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    font-feature-settings: 'palt';
    letter-spacing: 0.1rem;
  }
  button {
    font-family: 'Zen Kaku Gothic New';
    cursor: pointer;
    outline: none;
  }
  button:focus {
    outline: none;
  }
  button:hover {
    outline: none;
  }
  main h1,
  main h2,
  main h3,
  main h4,
  main h5,
  main h6,
  main form,
  main p {
    line-height: 1.6;
  }
  a,
  a:visited {
    text-decoration: none;
    color: #000;
  }
  img {
    vertical-align: bottom;
  }
  header nav > div > ul > li > a,
  #section_message p {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
  }
  #section_message h2,
  #section_message p strong,
  section h2.serif strong,
  .voicecont > div > .detail h3 {
    font-family: 'Noto Serif JP', serif;
    font-weight: 600;
  }
  section h2.serif span,
  #section_voice .tab li .num {
    font-family: 'Edu TAS Beginner', cursive;
    font-weight: 500;
  }
  header .resist a .txt .en,
  header .nav-button span:nth-of-type(3),
  header nav > div .insta span,
  #hero .scroll,
  #section_feature .point li > .num,
  #section_voice .tab li.comingsoon .chef,
  #section_regist .inquiry ul li dl dd .time,
  #section_regist .inquiry ul li a.tel,
  footer .inquiry a.tel,
  footer .inquiry dl dd .time,
  footer .copyright {
    font-family: 'Jost', sans-serif;
    font-weight: 500;
  }
  header {
    position: relative;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
    transition: top 0.6s ease-in-out, background 0.6s ease-in-out, opacity 1s ease-in-out, padding 0.3s ease-in-out;
  }
  header .resist {
    position: absolute;
    z-index: 20;
    display: flex;
    top: 24px;
    right: 114px;
    justify-content: flex-end;
  }
  header .resist li {
    min-width: 160px;
  }
  header .resist li.black {
    margin-right: 6px;
  }
  header .resist li.black a {
    background: #000;
    border: 2px solid #000;
  }
  header .resist li.black a:hover {
    background: #fff;
    color: #000;
  }
  header .resist li.black a:hover img {
    mix-blend-mode: difference;
  }
  header .resist li.orange a {
    background: #fc771c;
    border: 2px solid #fc771c;
  }
  header .resist li.orange a:hover {
    background: #fff;
    color: #fc771c;
  }
  header .resist li.orange a:hover svg {
    fill: #fc771c;
  }
  header .resist a {
    border-radius: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    box-sizing: border-box;
    color: #fff;
  }
  header .resist a .img {
    margin-right: 10px;
  }
  header .resist a .img img {
    width: 16px;
  }
  header .resist a .img svg {
    width: 16px;
    fill: #fff;
  }
  header .resist a .txt span {
    display: block;
    text-align: center;
    letter-spacing: 0.02em;
  }
  header .resist a .txt .jp {
    font-weight: 700;
  }
  header .resist a .txt .en {
    font-size: 10px;
    font-size: 1rem;
  }
  header .nav-button,
  header .nav-button span {
    display: inline-block;
    transition: all 0.3s;
    box-sizing: border-box;
  }
  header .navbtnCont {
    z-index: 20;
    position: fixed;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
  header .nav-button {
    z-index: 3;
    width: 70px;
    height: 70px;
    background: #000;
    border: 2px solid #000;
    box-sizing: border-box;
    border-radius: 100%;
    cursor: pointer;
    color: #fff;
  }
  header .nav-button:hover {
    background: #fff;
    color: #000;
  }
  header .nav-button:hover span {
    background-color: #000;
  }
  header .nav-button:focus {
    outline: none;
  }
  header .nav-button span {
    position: absolute;
    left: 50%;
    width: 20%;
    height: 2px;
    background-color: #fff;
    transform: translateX(-50%);
  }
  header .nav-button span:nth-of-type(1) {
    top: 32%;
  }
  header .nav-button span:nth-of-type(2) {
    bottom: 55%;
  }
  header .nav-button span:nth-of-type(3) {
    font-size: 10px;
    font-size: 1rem;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 6px;
    text-align: center;
    background: none;
    bottom: 46%;
  }
  header .nav-button.active span:nth-of-type(1) {
    -webkit-transform: translateX(-50%) translateY(4px) rotate(-45deg);
    transform: translateX(-50%) translateY(4px) rotate(-45deg);
  }
  header .nav-button.active span:nth-of-type(2) {
    -webkit-transform: translateX(-50%) translateY(-4px) rotate(45deg);
    transform: translateX(-50%) translateY(-4px) rotate(45deg);
  }
  header nav {
    transition: top 0.3s ease-in-out;
  }
  header nav ul li {
    font-size: 18px;
    font-size: 1.8rem;
  }
  header h1 {
    transition: width 0.3s ease-in-out;
  }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: backdrop-filter 0.4s ease-in;
  }
  header nav:after {
    position: fixed;
    content: '';
    display: block;
    z-index: 1;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgba(246, 246, 246, 0.8);
    transform: scale(0);
    right: -50px;
    top: -50px;
    transition: all 0.6s;
  }
  header nav > div {
    max-width: 500px;
    max-height: 100vh;
    max-height: 100dvh;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    opacity: 0;
    backface-visibility: hidden;
    overflow-y: auto;
    z-index: 2;
    position: relative;
    transition: opacity 0.8s;
  }
  header nav > div .logo {
    max-width: 260px;
    max-height: 20vh;
    margin: 0 auto;
  }
  header nav > div .logo img {
    width: 100%;
  }
  header nav > div .insta {
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    transition: all 0.3s;
    border: 2px solid #000;
    height: 40px;
    border-radius: 30px;
    box-sizing: border-box;
    letter-spacing: 0.1em;
    max-width: 200px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
  }
  header nav > div .insta img {
    margin-right: 5px;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }
  header nav > div .insta span {
    font-size: 110%;
  }
  header nav > div .insta:hover {
    background: #fff;
    color: #000;
  }
  header nav > div .insta:hover img {
    filter: none;
  }
  header nav > div > ul {
    list-style: none;
    margin: 0 auto;
    padding: 12% 20px;
    max-width: 500px;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    width: 100%;
    box-sizing: border-box;
  }
  header nav > div > ul > li {
    padding: 12% 10%;
    border-right: 1px solid #000;
  }
  header nav > div > ul > li:last-child {
    border-left: 1px solid #000;
  }
  header nav > div > ul > li > a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    transition: color 0.4s ease;
    font-size: 18px;
    font-size: 1.8rem;
  }
  header nav > div > ul > li > a span {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
  header nav > div > ul > li > a img {
    transform: rotate(90deg);
    margin-bottom: 5px;
    vertical-align: baseline;
  }
  header nav > div > ul > li > a:hover {
    color: #fc771c;
  }
  header nav.open {
    pointer-events: auto;
    visibility: visible;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  }
  header nav.open:after {
    transform: scale(50);
  }
  header nav.open > div {
    opacity: 1;
  }
  header nav ul {
    display: none;
  }
  header nav.open ul {
    display: flex;
  }
  #hero {
    position: relative;
    height: 100vh;
    height: 100dvh;
    overflow: hidden;
  }
  #hero .movie {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
  #hero .movie video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  #hero .campaign {
    position: absolute;
    z-index: 1;
    right: 34px;
    bottom: 40px;
    width: 185px;
  }
  #hero .campaign a {
    display: block;
    cursor: pointer;
    transform: scale(100%);
    transition: all 0.5s;
  }
  #hero .campaign a:hover {
    transform: scale(103%);
  }
  #hero .campaign img {
    width: 100%;
  }
  #hero .txt {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    align-content: center;
  }
  @-webkit-keyframes load {
    0% {
      display: block;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }
  @keyframes load {
    0% {
      display: block;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }
  #hero .txt.loaded {
    display: block;
    opacity: 1;
    -webkit-animation: load 2s 1 ease-in-out;
    animation: load 2s 1 ease-in-out;
  }
  #hero .txt .logo {
    width: 30%;
    max-width: 380px;
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  #hero .txt .logo img {
    width: 100%;
  }
  #hero .txt .read {
    width: 100%;
    padding: 20px 20px 30px;
    color: #fff;
    font-size: 16px;
    font-size: 1.6rem;
    text-shadow: 0 0 10px #000;
  }
  #hero .txt .read strong {
    display: block;
    font-size: 120%;
    margin-top: 6px;
  }
  #hero .scroll {
    z-index: 1;
    position: absolute;
    left: 20px;
    bottom: 20px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-size: 1.4rem;
    display: none;
  }
  #hero .scroll a {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: #fff;
  }
  #hero .scroll img {
    margin-top: 4px;
    vertical-align: baseline;
  }
  section {
    position: relative;
  }
  main {
    overflow: hidden;
  }
  #section_message .txtcont {
    margin: 0 auto;
    padding: 90px 20px;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
  }
  #section_message .txt {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  #section_message h2 {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 30px;
    font-size: 3rem;
    margin-left: 3.6vw;
    letter-spacing: 0.1em;
  }
  #section_message p {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 2em;
    margin-left: 2vw;
  }
  #section_message p strong {
    font-size: 120%;
  }
  #section_message p:nth-of-type(5) {
    margin-left: 0;
  }
  #section_message .sign {
    display: flex;
    align-items: flex-end;
  }
  #section_message .sp {
    display: none;
  }
  .message_slide {
    overflow: hidden;
  }
  .message_slide li img {
    width: 100%;
  }
  .message_slide .slick-slide div {
    transition: none;
  }
  section h2.serif {
    text-align: center;
  }
  section h2.serif span {
    font-size: 18px;
    font-size: clamp(1.4rem, 2.2vw, 1.8rem);
    background: #000;
    color: #fff;
    padding: 2px 6px;
    min-width: 150px;
    display: inline-block;
  }
  section h2.serif strong {
    display: block;
    font-size: 40px;
    font-size: clamp(2.6rem, 3.6vw, 4rem);
    line-height: 1.6em;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
  }
  #section_feature {
    padding-top: 8%;
  }
  #section_feature .point {
    max-width: 1480px;
    margin: 4% auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #section_feature .point li {
    position: relative;
    width: calc(100% / 3);
    box-sizing: border-box;
    padding: 0 2.5%;
    border-right: 1px dotted #a1a1a1;
  }
  #section_feature .point li > .num {
    color: #d6d6d6;
    font-size: 100px;
    font-size: clamp(8rem, 8vw, 10rem);
    position: absolute;
    top: 0;
    left: 11%;
    line-height: 1em;
  }
  #section_feature .point li > .img {
    width: 90%;
    padding-top: 90%;
    margin: 0 auto;
    border-radius: 100%;
  }
  #section_feature .point li:nth-child(2) .img {
    background: url(landing_renewal/img/feature_img1.jpg);
    background-size: 240% auto;
    background-position: 44% 38%;
  }
  #section_feature .point li:nth-child(1) .img {
    background: url(landing_renewal/img/feature_img2.jpg);
    background-size: cover;
    background-position: center;
  }
  #section_feature .point li:nth-child(3) .img {
    background: url(landing_renewal/img/feature_img3.jpg);
    background-size: cover;
    background-position: center;
  }
  #section_feature .point li:last-child {
    border-right: none;
  }
  #section_feature .point li h3 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 22px;
    font-size: clamp(1.6rem, 1.4vw, 2.2rem);
    line-height: 1.8em;
  }
  #section_feature .point li h3 strong {
    font-size: 30px;
    font-size: clamp(2.2rem, 2vw, 3rem);
    color: #fc771c;
  }
  #section_feature .point li h3 .price {
    font-size: 80px;
    font-size: clamp(5rem, 6vw, 8rem);
    position: relative;
    bottom: -8px;
    line-height: 0.6em;
    letter-spacing: -0.5px;
  }
  #section_feature .point li h3 .comma {
    font-size: 40px;
    font-size: clamp(2.5rem, 3vw, 4rem);
  }
  #section_feature .point li h3 .underline {
    background: linear-gradient(transparent 70%, #ffdd29 70%);
  }
  #section_feature .point li h3 .yen {
    font-size: 30px;
    font-size: clamp(2.2rem, 4vw, 3rem);
  }
  #section_feature .point li h3 .tax {
    font-size: 11px;
    font-size: clamp(1rem, 1.2vw, 1.1rem);
    font-weight: 300;
  }
  #section_feature .point li p {
    font-size: 16px;
    font-size: clamp(1.3rem, 1.6vw, 1.6rem);
  }
  br.sp {
    display: none;
  }
  @media screen and (min-width: 1051px) {
    // white-spaceをうまく利用できないため、相殺用のマイナスマージンをコメントアウト
    /* #section_feature .point li h3 .bgW {
    margin-right: -5px;
  } */
    #section_feature .point li:nth-child(2) h3 {
      margin-top: 10px;
      margin-bottom: 25px;
    }
  }
  @media screen and (max-width: 1050px) {
    #section_feature .point li h3 {
      font-size: clamp(1.6rem, 2.4vw, 2.2rem);
    }
    #section_feature .point li h3.price {
      font-size: clamp(5rem, 8vw, 8rem);
    }
    #section_feature .point li h3 strong {
      font-size: clamp(2.2rem, 2.9vw, 3rem);
    }
    #section_feature .point li h3 br.sp {
      display: block;
    }
  }
  #section_recommend {
    padding-top: 8%;
  }
  #section_recommend .read {
    padding-top: 2%;
    text-align: center;
    font-size: 22px;
    font-size: clamp(1.4rem, 2vw, 2.2rem);
  }
  #section_recommend .read strong {
    font-size: 120%;
    border-bottom: 4px solid #ffdd29;
    padding-bottom: 3px;
  }
  #section_recommend .foodlist {
    max-width: 1230px;
    margin: 4% auto 0;
    display: -ms-grid;
    display: grid;
    padding: 20px 20px 50px;
    box-sizing: content-box;
    gap: 7px;
    -ms-grid-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  #section_recommend .foodlist li {
    position: relative;
    margin-bottom: 15%;
  }
  #section_recommend .foodlist .imgcont {
    overflow: hidden;
    height: 20vw;
    min-height: 168px;
    max-height: 240px;
    position: relative;
    margin-bottom: 10px;
  }
  #section_recommend .foodlist .imgcont:after {
    position: absolute;
    z-index: 1;
    content: '';
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
    background: #000;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.65) 100%);
  }
  #section_recommend .foodlist .imgcont .ttl {
    position: relative;
    z-index: 2;
    color: #fff;
    font-size: 26px;
    font-size: clamp(1.6rem, 1.8vw, 2.6rem);
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 3%;
    transition: all 0.3s;
  }
  #section_recommend .foodlist .imgcont .ttl .small {
    font-size: 60%;
    position: relative;
    bottom: 3px;
  }
  #section_recommend .foodlist .imgcont .img {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-position: center;
    transition: all 0.5s;
  }
  #section_recommend .foodlist .place {
    position: absolute;
    right: 2%;
    top: -3%;
    background: #000;
    color: #fff;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: upright;
    height: 90px;
    padding: 3px;
    box-sizing: border-box;
    z-index: 3;
  }
  #section_recommend .foodlist .place span {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    border-left: 1px solid #a1a1a1;
    border-right: 1px solid #a1a1a1;
    padding: 0 6px;
  }
  #section_recommend .foodlist .farmer {
    display: flex;
    align-items: center;
  }
  #section_recommend .foodlist .farmer .icon {
    border-radius: 100%;
    margin-right: 4%;
    width: 60px;
  }
  #section_recommend .foodlist .farmer .icon img {
    width: 60px;
  }
  #section_recommend .foodlist .farmer .name {
    font-size: 18px;
    font-size: clamp(1.3rem, 2.3vw, 1.8rem);
    font-weight: 700;
    position: relative;
  }
  #section_recommend .foodlist .farmer .name:after {
    transition: all 0.4s;
    position: absolute;
    content: '';
    height: 5px;
    background: #ffdd29;
    width: 0;
    right: 0;
    bottom: -2px;
    z-index: 0;
  }
  #section_recommend .foodlist .farmer .name span {
    font-size: 70%;
    font-weight: 400;
  }
  #section_voice {
    max-width: 1480px;
    margin: 0 auto;
    padding: 0 20px;
  }
  #section_voice .tab {
    padding-top: 4%;
    display: flex;
    justify-content: space-between;
  }
  #section_voice .tab#bottomTab {
    display: none;
  }
  #section_voice .tab li {
    width: 33%;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
  }
  #section_voice .tab li:hover button {
    background: #f8f8f8;
  }
  #section_voice .tab li:hover .name {
    color: #000;
  }
  #section_voice .tab li:hover .num {
    background: #000;
    color: #fff;
  }
  #section_voice .tab li.comingsoon {
    pointer-events: none;
    opacity: 0.75;
  }
  #section_voice .tab li.comingsoon .img {
    background-size: 100% auto;
  }
  #section_voice .tab li button {
    position: relative;
    width: 100%;
    height: 100px;
    background: #000;
    color: #fff;
    border: none;
    padding: 0;
    transition: all 0.4s;
  }
  #section_voice .tab li button.current {
    background: #f8f8f8;
  }
  #section_voice .tab li button.current .name {
    color: #000;
  }
  #section_voice .tab li button.current .num {
    background: #000;
    color: #fff;
  }
  #section_voice .tab li .img {
    width: 100px;
    height: 100%;
    background-size: auto 150%;
    background-position: center top;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  #section_voice .tab li .num {
    background: #fff;
    color: #000;
    padding: 0 2%;
    font-size: 14px;
    font-size: clamp(1.3rem, 1.2vw, 1.4rem);
    position: absolute;
    left: 90px;
    top: 8px;
    z-index: 2;
    transition: all 0.4s;
    letter-spacing: 0.05em;
  }
  #section_voice .tab li .num strong {
    font-size: 150%;
  }
  #section_voice .tab li .name {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    flex-wrap: wrap;
    padding-right: 5%;
    padding-bottom: 2%;
    color: #fff;
    text-align: right;
    padding-left: 116px;
    height: 100%;
    font-weight: 600;
    transition: all 0.4s;
  }
  #section_voice .tab li .name .restaurant {
    font-size: 14px;
    font-size: 1.4rem;
    width: 100%;
  }
  #section_voice .tab li .name .chef {
    font-size: 16px;
    font-size: 1.6rem;
    width: 100%;
  }
  #section_voice .tab li .name .chef span {
    font-size: 11px;
    font-weight: 300;
  }
  .voicecont {
    background: #f8f8f8;
    padding: 6%;
    display: none;
  }
  .voicecont.current {
    display: block;
  }
  .voicecont > div {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 45% 1fr;
    grid-template-columns: 45% 1fr;
    -ms-grid-rows: 1fr auto;
    grid-template-rows: 1fr auto;
    grid-column-gap: 6%;
    grid-row-gap: 6%;
  }
  @media (max-width: 767px) {
    .voicecont > div {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }
  }
  .voicecont > div > .detail {
    grid-area: 1 / 2 / 3 / 3;
  }
  .voicecont > div > .detail h3 {
    font-size: 30px;
    font-size: clamp(2.2rem, 2.4vw, 3rem);
    padding-bottom: 5%;
  }
  .voicecont > div > .detail p {
    font-size: 14px;
    font-size: clamp(1.3rem, 1.2vw, 1.4rem);
  }
  .voicecont > div > .detail p button {
    border: none;
    border-bottom: 1px solid #000;
    font-weight: 700;
    background: none;
    padding: 0;
    padding-bottom: 4px;
    display: none;
    vertical-align: middle;
    cursor: pointer;
    margin: 0 auto;
  }
  .voicecont > div > .detail p button img {
    display: inline-block;
    margin-left: 3px;
    position: relative;
    top: -2px;
  }
  .voicecont > div > .detail .name {
    text-align: right;
    padding: 8% 0;
    font-weight: 700;
    font-size: 18px;
    font-size: clamp(1.4rem, 1.4vw, 1.8rem);
  }
  .voicecont > div > .detail .name span {
    font-size: 14px;
    font-size: clamp(1.2rem, 1.1vw, 1.4rem);
  }
  .voicecont > div > .detail .foodcont {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 25% auto;
    grid-template-columns: 25% auto;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    background: #fff;
  }
  .voicecont > div > .detail .foodcont .product_img {
    max-width: 140px;
  }
  .voicecont > div > .detail .foodcont .product_img img {
    width: 100%;
  }
  .voicecont > div > .detail .foodcont .detail {
    padding: 2.5% 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .voicecont > div > .detail .foodcont .detail .txt {
    width: 70%;
  }
  .voicecont > div > .detail .foodcont .detail .txt .food_name {
    font-size: 16px;
    font-size: clamp(1.2rem, 1.3vw, 1.6rem);
    padding-bottom: 14px;
    border-bottom: 1px dashed #666;
    margin-bottom: 14px;
  }
  .voicecont > div > .detail .foodcont .detail .txt .food_name h4 {
    font-weight: 700;
    display: inline-block;
    margin-right: 10px;
  }
  .voicecont > div > .detail .foodcont .detail .txt .producer_name {
    font-size: 14px;
    font-size: clamp(1.2rem, 1.2vw, 1.4rem);
    font-weight: 700;
  }
  .voicecont > div > .detail .foodcont .detail .txt .producer_name .place {
    border: 1px solid #000;
    padding: 2px 4px;
    font-size: 12px;
    font-size: clamp(1rem, 1vw, 1.2rem);
    font-weight: 400;
    display: inline-block;
    margin-right: 6px;
  }
  .voicecont > div > .detail .foodcont .detail .txt .producer_name span {
    font-weight: 400;
    font-size: 12px;
    font-size: clamp(1rem, 1vw, 1.2rem);
  }
  .voicecont > div > .detail .foodcont .detail .producer_img {
    width: 25%;
    border-radius: 100%;
    overflow: hidden;
  }
  .voicecont > div > .detail .foodcont .detail .producer_img img {
    width: 100%;
  }
  .voicecont .sliderCont {
    grid-area: 1 / 1 / 2 / 2;
    height: 100%;
  }
  .voicecont .slider_voice {
    height: 100%;
    width: 100%;
  }
  .voicecont .slider_voice .slick-slide li {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  .voicecont .slider_voice .slick-track,
  .voicecont .slider_voice .slick-list,
  .voicecont .slider_voice .slick-slide > div {
    height: 100%;
    width: 100%;
  }
  .voicecont .movie_th {
    grid-area: 2 / 1 / 3 / 2;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
  .voicecont .movie_th .play {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    padding: 0;
    border: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 0.4s;
  }
  .voicecont .movie_th .play .icon {
    width: 100%;
  }
  .voicecont .movie_th .play .icon img,
  .voicecont .movie_th .play .icon svg {
    width: 48px;
    fill: #fff;
  }
  .voicecont .movie_th .play .txt {
    width: 100%;
    font-weight: 700;
    letter-spacing: 0.2em;
    margin-top: 10px;
    color: #fff;
  }
  .voicecont .movie_th video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  @media screen and (max-width: 950px) {
    #section_voice .tab li button.current .img:after {
      display: none;
    }
    #section_voice .tab li button .img {
      width: 100%;
    }
    #section_voice .tab li button .img:after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 2;
    }
    #section_voice .tab li button .num {
      left: auto;
      right: 0;
      top: auto;
      bottom: 10%;
    }
    #section_voice .tab li button .name {
      display: none;
    }
    .voicecont .sliderCont {
      height: 400px;
      width: 100%;
      order: 1;
    }
    .voicecont > div {
      display: flex;
      flex-wrap: wrap;
    }
    .voicecont > div > .detail {
      order: 2;
      margin-top: 8%;
      display: flex;
      flex-wrap: wrap;
    }
    .voicecont > div > .detail h3 {
      order: 1;
      width: 100%;
      padding-bottom: 3%;
    }
    .voicecont > div > .detail .name {
      order: 2;
      padding: 0 0 3%;
      width: 100%;
    }
    .voicecont > div > .detail p {
      order: 3;
      width: 100%;
    }
    .voicecont > div > .detail .foodcont {
      order: 4;
      margin: 6% auto 0;
    }
    .voicecont .movie_th {
      order: 3;
      width: 100%;
      margin-top: 6%;
    }
    #section_voice .tab#bottomTab {
      display: flex;
      padding-top: 0;
    }
    #section_voice .tab#bottomTab li {
      border-radius: 0 0 20px 20px;
    }
  }
  #section_regist {
    background: #ff8e25;
  }
  #section_regist > div {
    max-width: 928px;
    padding: 6%;
    margin: 30px auto 0;
    box-sizing: content-box;
  }
  #section_regist h2 {
    color: #fff;
    font-size: 32px;
    font-size: clamp(2.4rem, 3.2vw, 3.2rem);
    margin-bottom: 20px;
    text-align: center;
  }
  #section_regist h2 span {
    background: #fff;
    padding: 2px 10px;
    color: #fc771c;
    font-size: 14px;
    font-size: clamp(1.2rem, 1.2vw, 1.4rem);
    margin-right: 10px;
    display: inline-block;
    position: relative;
    top: -5px;
  }
  #section_regist h2 strong {
    font-size: 110%;
  }
  #section_regist .btn_resist {
    border: 8px solid #ffb480;
    box-shadow: 0px 8px 0px 0px rgba(202, 23, 23, 0.25);
    max-width: 478px;
    max-height: 100px;
    margin: 0 auto 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    text-align: center;
    border-radius: 50px;
    padding: 3%;
    font-size: 26px;
    font-size: clamp(2rem, 2.8vw, 2.6rem);
    font-weight: 700;
    box-sizing: border-box;
    letter-spacing: 0.2em;
    transition: all 0.4s;
  }
  #section_regist .btn_resist img {
    width: 10%;
    max-width: 20px;
    min-width: 16px;
    padding-right: 14px;
    box-sizing: content-box;
    transition: 0.3s all;
  }
  #section_regist .btn_resist:hover {
    box-shadow: 0px 2px 0px 0px rgba(202, 23, 23, 0.25);
    border-color: #555;
    background: #000;
    color: #fff;
  }
  #section_regist .btn_resist:hover img {
    -webkit-filter: invert(1);
    filter: invert(1);
  }
  #section_regist .coupon {
    display: block;
    max-width: 425px;
    width: 80%;
    margin: 0 auto 6%;
  }
  #section_regist .inquiry {
    background: #fff;
    border-radius: 20px;
    padding: 4% 6%;
    width: 100%;
  }
  #section_regist .inquiry h3 {
    text-align: center;
    font-size: 25px;
    font-size: clamp(1.8rem, 2.4vw, 2.5rem);
    letter-spacing: 0.15em;
  }
  #section_regist .inquiry h3 span {
    display: inline-block;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  #section_regist .inquiry > p {
    font-size: 14px;
    font-size: clamp(1.3rem, 1.4vw, 1.4rem);
    margin-bottom: 40px;
    text-align: center;
  }
  #section_regist .inquiry ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 730px;
    margin: 0 auto;
  }
  #section_regist .inquiry ul li {
    width: 49%;
    text-align: center;
  }
  #section_regist .inquiry ul li dl {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  #section_regist .inquiry ul li dl dt {
    font-size: 12px;
    font-size: 1.2rem;
    padding-right: 10px;
    border-right: 1px dotted #000;
    margin-right: 10px;
  }
  #section_regist .inquiry ul li dl dd {
    font-size: 14px;
    font-size: 1.4rem;
  }
  #section_regist .inquiry ul li dl dd .note {
    font-size: 80%;
    letter-spacing: 0em;
  }
  #section_regist .inquiry ul li a {
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    transition: all 0.3s;
    border: 2px solid #000;
    height: 60px;
    border-radius: 30px;
    box-sizing: border-box;
    letter-spacing: 0.1em;
    max-width: 350px;
  }
  #section_regist .inquiry ul li a:hover {
    background: #fff;
    color: #000;
  }
  #section_regist .inquiry ul li a:hover img {
    mix-blend-mode: difference;
  }
  #section_regist .inquiry ul li a img {
    width: 10%;
    max-width: 20px;
    min-width: 14px;
    margin-right: 10px;
  }
  #section_regist .inquiry ul li a.tel {
    font-size: 22px;
    font-size: clamp(1.8rem, 1.8vw, 2.2rem);
  }
  #section_regist .inquiry ul li a.mail {
    font-size: 20px;
    font-size: clamp(1.5rem, 1.6vw, 2rem);
  }
  .follow {
    position: fixed;
    z-index: 3;
    right: 0;
    bottom: 20px;
    background: #fc771c;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s;
  }
  .follow li {
    border-bottom: 1px dotted #fff;
  }
  .follow li:first-child a {
    padding: 32px 23px 30px;
  }
  .follow li:last-child {
    border-bottom: none;
  }
  .follow a {
    font-size: 16px;
    font-size: 1.6rem;
    letter-spacing: 0.4em;
    font-weight: 700;
    text-align: center;
    padding: 24px 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all 0.3s;
  }
  .follow a span {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
  .follow a:hover {
    background: #ff6108;
  }
  .follow.show {
    pointer-events: auto;
    opacity: 1;
  }
  .slick-dots {
    position: relative;
    display: flex;
    justify-content: center;
    bottom: -10px;
  }
  .slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0 6px 10px;
  }
  .slick-dots li button:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 10px;
    border: 1px solid #000;
    opacity: 1;
  }
  .slick-dots li.slick-active button:before {
    background: #000;
  }
  footer {
    background: #f8f8f8;
    padding: 8% 6%;
  }
  footer .footCont {
    max-width: 1230px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  footer .logo {
    width: 22%;
    max-width: 300px;
    transition: all 0.3s;
  }
  footer .logo img {
    width: 100%;
  }
  footer .logo:hover {
    opacity: 0.75;
  }
  footer .inquiry {
    max-width: 220px;
    padding: 0 16px;
    box-sizing: content-box;
  }
  footer .inquiry h3 {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 14px;
  }
  footer .inquiry li {
    margin-bottom: 24px;
  }
  footer .inquiry a {
    background: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    transition: all 0.3s;
    border: 2px solid #fc771c;
    height: 36px;
    border-radius: 30px;
    box-sizing: border-box;
    letter-spacing: 0.1em;
    max-width: 350px;
  }
  footer .inquiry a:hover {
    background: #fc771c;
    color: #fff;
  }
  footer .inquiry a:hover img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }
  footer .inquiry a img {
    width: 10%;
    max-width: 20px;
    min-width: 14px;
    margin-right: 10px;
  }
  footer .inquiry a.tel {
    font-size: 14px;
    font-size: 1.4rem;
  }
  footer .inquiry a.mail {
    font-size: 12px;
    font-size: 1.2rem;
  }
  footer .inquiry dl {
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: #444;
  }
  footer .inquiry dl dt {
    font-size: 11px;
    font-size: 1.1rem;
    padding-right: 6px;
    border-right: 1px dotted #000;
    margin-right: 6px;
  }
  footer .inquiry dl dd {
    font-size: 12px;
    font-size: 1.2rem;
  }
  footer .inquiry dl dd .note {
    font-size: 80%;
    letter-spacing: 0em;
  }
  footer nav {
    min-width: 140px;
  }
  footer nav li {
    margin-bottom: 40px;
  }
  footer nav li a {
    font-size: 16px;
    font-size: clamp(1.3rem, 1.4vw, 1.6rem);
    font-weight: 700;
    display: flex;
    align-items: center;
    transition: all 0.2s;
  }
  footer nav li a img {
    max-width: 16px;
    min-width: 12px;
    width: 20%;
    margin-right: 6%;
  }
  footer nav li a:hover {
    color: #fc771c;
  }
  footer .policy {
    font-size: 13px;
    font-size: clamp(1rem, 1vw, 1.3rem);
    font-weight: 700;
  }
  footer .policy li {
    margin-bottom: 20px;
  }
  footer .policy .icon img,
  footer .policy .icon svg {
    width: 20%;
    max-width: 26px;
    min-width: 13px;
    transition: all 0.2s;
  }
  footer .policy a {
    transition: all 0.2s;
  }
  footer .policy a:hover {
    color: #fc771c;
  }
  footer .policy a:hover svg {
    fill: #fc771c;
  }
  footer .copyright {
    padding: 6% 6% 0;
    font-size: 10px;
    font-size: 1rem;
    text-align: center;
  }
  footer .copyright img {
    display: block;
    margin: 0 auto 20px;
  }
  .modal-overlay {
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }
  .modal-container {
    background: transparent;
    max-height: 90vh;
    overflow: visible;
    width: 80vw;
    position: relative;
  }
  .modal-content {
    width: 80vw;
    height: 45vw;
    max-width: 100%;
    max-height: calc(96vw * 1.77778);
    margin: auto;
  }
  .modal-content video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .modal-close {
    background: transparent;
    border: 0;
    position: absolute;
    top: -50px;
    right: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    width: 44px;
    color: #fff;
    z-index: 100;
  }
  .modal-close:hover::before {
    background-color: #fff;
    color: #000;
  }
  .modal-close::before {
    content: '✕';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    font-size: 18px;
    height: 40px;
    border: 2px solid #000;
    background: #000;
    border-radius: 100%;
    padding: 0 !important;
    color: #fff;
    transition: all 0.3s;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes slideIn {
    from {
      transform: translateY(15%);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes slideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-10%);
    }
  }
  .micromodal-slide {
    display: none;
  }
  .micromodal-slide.is-open {
    display: block;
  }
  .micromodal-slide[aria-hidden='false'] .modal-overlay {
    animation: fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  .micromodal-slide[aria-hidden='false'] .modal-container {
    animation: slideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  .micromodal-slide[aria-hidden='true'] .modal-overlay {
    animation: fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  .micromodal-slide[aria-hidden='true'] .modal-container {
    animation: slideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  .micromodal-slide .modal-container,
  .micromodal-slide .modal-overlay {
    will-change: transform;
  }
  @media screen and (max-width: 651px) {
    #section_recommend .foodlist a:hover .imgcont .img {
      height: 105%;
      width: 105%;
    }
    #section_recommend .foodlist a:hover .farmer .name:after {
      width: 100%;
      right: auto;
      left: 0;
    }
    .voicecont .movie_th .play:hover {
      background: rgba(255, 255, 255, 0.5);
    }
    .voicecont .movie_th .play:hover .icon svg {
      fill: #fc771c;
    }
    .voicecont .movie_th .play:hover .txt {
      color: #fc771c;
      text-shadow: 0 0 20px #fff;
    }
  }
  @media screen and (max-width: 650px) {
    header .resist {
      top: 16px;
      right: 58px;
      position: fixed;
    }
    header .resist li {
      min-width: 100px;
    }
    header .resist a {
      font-size: 1.2rem;
      height: 30px;
    }
    header .resist a .txt .en {
      display: none;
    }
    header .resist a .img {
      margin-right: 5px;
      position: relative;
      bottom: -2px;
    }
    header .resist a .img svg {
      width: 12px;
    }
    header .resist a .img img {
      width: 12px;
      vertical-align: text-top;
    }
    header .navbtnCont {
      top: 16px;
      right: 16px;
    }
    header .nav-button {
      width: 30px;
      height: 30px;
    }
    header .nav-button:hover {
      background: #000;
      color: #fff;
    }
    header .nav-button:hover span {
      background-color: #fff;
    }
    header .nav-button span {
      width: 29%;
      height: 1px;
    }
    header .nav-button span:nth-of-type(1) {
      top: 35%;
    }
    header .nav-button span:nth-of-type(2) {
      bottom: 49%;
    }
    header .nav-button span:nth-of-type(3) {
      display: none;
    }
    header .nav-button.active span:nth-of-type(1) {
      -webkit-transform: translateX(-50%) translateY(2.5px) rotate(45deg);
      transform: translateX(-50%) translateY(2.5px) rotate(45deg);
    }
    header .nav-button.active span:nth-of-type(2) {
      -webkit-transform: translateX(-50%) translateY(-2px) rotate(-45deg);
      transform: translateX(-50%) translateY(-2px) rotate(-45deg);
    }
    header nav > div .logo {
      max-width: 200px;
    }
    header nav > div .insta {
      height: 36px;
      max-width: 180px;
      font-size: 1.2rem;
    }
    header nav > div .insta img {
      width: 14px;
    }
    header nav > div > ul {
      padding: 10% 20px;
    }
    header nav > div > ul > li {
      padding: 8%;
    }
    header nav > div > ul > li > a {
      font-size: 1.6rem;
    }
    #hero .txt .read {
      font-size: 1.3rem;
    }
    #hero .txt .logo {
      width: 45%;
    }
    #hero .campaign {
      width: 130px;
      bottom: 10px;
      right: 10px;
    }
    #hero .scroll {
      display: flex;
    }
    #section_message .txtcont {
      display: block;
      padding: 50px 20px;
    }
    #section_message .txt {
      display: block;
      margin-left: 0;
    }
    #section_message h2 {
      font-size: 2.6rem;
      writing-mode: horizontal-tb;
      margin-left: 0;
      margin-bottom: 24px;
      line-height: 1.6em;
    }
    #section_message p {
      writing-mode: horizontal-tb;
      margin-left: 0;
      margin-bottom: 16px;
      font-size: 1.3rem;
      letter-spacing: 0.01em;
    }
    #section_message .sign {
      margin-top: 24px;
      justify-content: flex-end;
    }
    #section_message .pc {
      display: none;
    }
    #section_message .sp {
      display: block;
    }
    section h2.serif strong {
      margin-top: 6px;
      margin-bottom: 20px;
    }
    #section_feature {
      padding-top: 14%;
    }
    #section_feature .point li {
      padding: 0;
      width: 100%;
      margin-bottom: 40px;
      text-align: left;
    }
    #section_feature .point li > .img {
      border-radius: 0;
      height: 220px;
      padding-top: 0;
      width: 100%;
    }
    #section_feature .point li h3 {
      position: absolute;
      top: 78px;
      margin: 0;
      text-align: left;
      right: 20px;
    }
    #section_feature .point li h3 .bgW {
      background: #fff;
      padding: 1px 6px;
      display: inline-block;
      margin-top: 5px;
    }
    #section_feature .point li > .num {
      top: -40px;
      left: 10px;
      color: #fc771c;
      font-size: 9.5rem;
    }
    #section_feature .point li:nth-child(2) .img {
      background: url(landing_renewal/img/feature_img1.jpg);
      background-size: 140% auto;
      background-position: right 60%;
    }
    #section_feature .point li:nth-child(1) .img {
      background: url(landing_renewal/img/feature_img2.jpg);
      background-size: 130% auto;
      background-position: 60% 35%;
    }
    #section_feature .point li:nth-child(3) .img {
      background: url(landing_renewal/img/feature_img3.jpg);
      background-size: 130% auto;
      background-position: 100% 40%;
    }
    #section_feature .point li p {
      padding: 30px 20px 20px;
    }
    #section_recommend {
      padding-top: 0;
    }
    #section_recommend .read {
      padding-top: 0;
      line-height: 2em;
    }
    #section_recommend .foodlist {
      -ms-grid-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    #section_recommend .foodlist li {
      margin-bottom: 7%;
    }
    #section_recommend .foodlist .imgcont {
      min-height: 130px;
      height: 130px;
      margin-bottom: 6px;
    }
    #section_recommend .foodlist .place {
      height: 58px;
      font-size: 1rem;
      padding: 3px 2px;
    }
    #section_recommend .foodlist .place span {
      padding: 0px 2px;
    }
    #section_recommend .foodlist .farmer .icon {
      width: 37px;
      margin-right: 3%;
    }
    #section_recommend .foodlist .farmer .icon img {
      width: 37px;
    }
    #section_recommend .foodlist .farmer .name {
      letter-spacing: 0.05em;
    }
    br.sp {
      display: block;
    }
    #section_voice {
      padding: 0;
    }
    #section_voice .tab {
      padding-top: 0;
    }
    #section_voice .tab li {
      width: 32.8%;
      border-radius: 8px 8px 0 0;
    }
    #section_voice .tab li button {
      height: 60px;
    }
    #section_voice .tab li button .num {
      padding: 0 4%;
    }
    #section_voice .tab li .img {
      background-size: 150% auto;
      background-position: 90% top;
    }
    #section_voice .tab li.comingsoon button {
      background: #c7c7c7;
    }
    #section_voice .tab li.comingsoon .img {
      display: none;
    }
    #section_voice .tab li.comingsoon .name {
      display: block;
      padding-left: 0;
      text-align: left;
      color: #666;
      padding-left: 10px;
      padding-top: 10px;
      line-height: 1.2em;
    }
    #section_voice .tab li.comingsoon .chef {
      font-size: 1.2rem;
    }
    .voicecont {
      padding: 6%;
    }
    .voicecont .sliderCont {
      height: 188px;
    }
    .voicecont > div > .detail h3 {
      padding-bottom: 5%;
    }
    .voicecont > div > .detail h3 .pc {
      display: none;
    }
    .voicecont > div > .detail .name {
      padding-bottom: 5%;
    }
    .voicecont > div > .detail p button {
      display: block;
      color: #000;
    }
    .voicecont > div > .detail p .hide {
      display: none;
    }
    .voicecont > div > .detail .foodcont {
      margin-top: 8%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .voicecont > div > .detail .foodcont .detail {
      padding: 4% 5%;
    }
    .voicecont > div > .detail .foodcont .product_img {
      display: none;
    }
    #section_regist > div {
      margin-top: 20px;
      padding: 8% 6% 12%;
    }
    #section_regist h2 {
      text-align: center;
      line-height: 1.4em;
    }
    #section_regist h2 span {
      padding: 3px 30px;
      display: inline-block;
      margin: 0 auto 20px;
      top: 0;
      line-height: 1.6em;
    }
    #section_regist .btn_resist {
      border-width: 6px;
      height: 70px;
      margin-bottom: 10%;
    }
    #section_regist .coupon {
      margin-bottom: 10%;
    }
    #section_regist .inquiry {
      padding: 8% 6%;
    }
    #section_regist .inquiry h3 span {
      width: 80%;
    }
    #section_regist .inquiry > p {
      margin-bottom: 30px;
    }
    #section_regist .inquiry ul li {
      width: 100%;
      max-width: 255px;
      margin: 0 auto;
    }
    #section_regist .inquiry ul li a {
      height: 50px;
    }
    #section_regist .inquiry ul li a img {
      width: 14px;
      margin-right: 8px;
    }
    #section_regist .inquiry ul li dl {
      margin-bottom: 30px;
    }
    footer {
      padding: 12% 6%;
    }
    footer .footCont {
      display: block;
    }
    footer .logo {
      max-width: 146px;
      width: 100%;
      display: block;
      margin: 0 auto 40px;
    }
    footer .inquiry {
      display: none;
    }
    footer nav ul {
      display: flex;
      justify-content: space-between;
      max-width: 330px;
      margin: 0 auto;
    }
    footer nav ul li a {
      white-space: nowrap;
    }
    footer .policy {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    footer .policy li {
      margin-bottom: 12px;
      margin-right: 12px;
      border-right: 1px solid #666;
      padding-right: 12px;
    }
    footer .policy li:last-child {
      border-right: none;
      margin-right: 0;
      padding-right: 0;
    }
    footer .policy li.icon {
      display: flex;
      align-items: center;
    }
    footer .policy li.icon a {
      display: flex;
      align-items: center;
    }
    .modal-container {
      max-height: 80vh;
      max-width: 1600px;
      width: 96%;
    }
    .modal-content {
      height: 0;
      padding-bottom: 56.25%;
      position: relative;
      width: auto;
      max-height: none;
      max-width: none;
      margin: 0;
    }
    .modal-content video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    body > .follow {
      display: none;
    }
  }
  @media screen and (max-height: 450px) {
    header .resist {
      top: 16px;
      right: 58px;
      position: fixed;
    }
    header .resist li {
      min-width: 100px;
    }
    header .resist a {
      font-size: 1.2rem;
      height: 30px;
    }
    header .resist a .txt .en {
      display: none;
    }
    header .resist a .img {
      margin-right: 5px;
      position: relative;
      bottom: -2px;
    }
    header .resist a .img svg {
      width: 12px;
    }
    header .resist a .img img {
      width: 12px;
      vertical-align: text-top;
    }
    header .navbtnCont {
      top: 16px;
      right: 16px;
    }
    header .nav-button {
      width: 30px;
      height: 30px;
    }
    header .nav-button:hover {
      background: #000;
      color: #fff;
    }
    header .nav-button:hover span {
      background-color: #fff;
    }
    header .nav-button span {
      width: 29%;
      height: 1px;
    }
    header .nav-button span:nth-of-type(1) {
      top: 35%;
    }
    header .nav-button span:nth-of-type(2) {
      bottom: 49%;
    }
    header .nav-button span:nth-of-type(3) {
      display: none;
    }
    header .nav-button.active span:nth-of-type(1) {
      -webkit-transform: translateX(-50%) translateY(2.5px) rotate(45deg);
      transform: translateX(-50%) translateY(2.5px) rotate(45deg);
    }
    header .nav-button.active span:nth-of-type(2) {
      -webkit-transform: translateX(-50%) translateY(-2px) rotate(-45deg);
      transform: translateX(-50%) translateY(-2px) rotate(-45deg);
    }
    header nav > div .logo {
      max-width: 200px;
    }
    header nav > div .insta {
      height: 36px;
      max-width: 180px;
      font-size: 1.2rem;
    }
    header nav > div .insta img {
      width: 14px;
    }
    header nav > div > ul {
      padding: 10% 20px;
    }
    header nav > div > ul > li {
      padding: 8%;
    }
    header nav > div > ul > li > a {
      font-size: 1.6rem;
    }
    #hero .campaign {
      width: 140px;
      bottom: 16px;
      right: 16px;
    }
    body > .follow {
      display: none;
    }
    .modal-close {
      top: 0;
      right: -50px;
    }
    header nav > div > ul {
      padding-top: 0;
    }
    header nav > div > ul > li {
      padding: 0 8%;
    }
    header nav > div .logo {
      display: none;
    }
    #section_message p {
      height: 90vh;
    }
    #section_message .txtcont {
      max-width: 460px;
    }
    #section_message h2 {
      margin-left: 30px;
      font-size: 20px;
    }
    #section_message p {
      font-size: 14px;
      font-size: 1.4rem;
      margin-left: 10px;
    }
    footer {
      padding: 12% 6%;
    }
    footer .footCont {
      display: block;
    }
    footer .logo {
      max-width: 146px;
      width: 100%;
      display: block;
      margin: 0 auto 40px;
    }
    footer .inquiry {
      display: none;
    }
    footer nav ul {
      display: flex;
      justify-content: space-between;
      max-width: 330px;
      margin: 0 auto;
    }
    footer nav ul li a {
      white-space: nowrap;
    }
    footer .policy {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    footer .policy li {
      margin-bottom: 12px;
      margin-right: 12px;
      border-right: 1px solid #666;
      padding-right: 12px;
    }
    footer .policy li:last-child {
      border-right: none;
      margin-right: 0;
      padding-right: 0;
    }
    footer .policy li.icon {
      display: flex;
      align-items: center;
    }
    footer .policy li.icon a {
      display: flex;
      align-items: center;
    }
  }
  main#policy h1 {
    margin-top: 40px;
    text-align: center;
  }
  main#policy h1 img {
    width: 30%;
    max-width: 200px;
  }
  main#policy section {
    max-width: 800px;
    padding: 0 20px;
    margin: 40px auto 60px;
  }
  main#policy h2 {
    text-align: center;
    font-size: 30px;
    font-size: clamp(2rem, 3vw, 3rem);
    margin-bottom: 40px;
  }
  main#policy h3 {
    margin-top: 4%;
    font-size: 16px;
    font-size: clamp(1.4rem, 2vw, 1.6rem);
  }
  main#policy p {
    margin-top: 1%;
    font-size: 13px;
    font-size: clamp(1.2rem, 1.6vw, 1.3rem);
  }
  main#policy p a {
    text-decoration: underline;
  }
  main#policy p a:hover {
    text-decoration: none;
  }
  main#policy ul {
    margin-top: 1%;
    font-size: 13px;
    font-size: clamp(1.2rem, 1.6vw, 1.3rem);
    list-style-type: disc;
    margin-left: 20px;
  }
  main#policy ul li {
    margin-top: 1%;
  }
  main#policy ul li a {
    text-decoration: underline;
  }
  main#policy ul li a:hover {
    text-decoration: none;
  }
  main#policy ol {
    margin-top: 1%;
    font-size: 13px;
    font-size: clamp(1.2rem, 1.6vw, 1.3rem);
    list-style-type: decimal;
    margin-left: 20px;
  }
  main#policy ol li {
    margin-top: 1%;
  }
  main#policy ol li a {
    text-decoration: underline;
  }
  main#policy ol li a:hover {
    text-decoration: none;
  }
  main#policy ol li > ol {
    list-style: none;
    counter-reset: number;
    margin-left: 0;
  }
  main#policy ol li > ol li {
    position: relative;
    padding-left: 40px;
  }
  main#policy ol li > ol li::before {
    counter-increment: number;
    content: '（' counter(number) '）';
    position: absolute;
    left: 0;
  }
  main#policy .sign {
    text-align: right;
    margin-top: 6%;
    font-size: 13px;
    font-size: clamp(1.2rem, 1.6vw, 1.3rem);
  }
  .message_slide {
    display: none;
  }
  .message_slide.slick-initialized {
    display: block;
  }
`
