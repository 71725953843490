import { createContext, useContext, ReactNode, useState } from 'react'

interface PreviousPageContextType {
  previousPage: string | null
  setPreviousPage: (page: string | null) => void
}

const PreviousPageContext = createContext<PreviousPageContextType | undefined>(undefined)

export const PreviousPageProvider = ({ children }: { children: ReactNode }) => {
  const [previousPage, setPreviousPage] = useState<string | null>(null)

  return (
    <PreviousPageContext.Provider value={{ previousPage, setPreviousPage }}>{children}</PreviousPageContext.Provider>
  )
}

export const usePreviousPage = (): PreviousPageContextType => {
  const context = useContext(PreviousPageContext)

  if (!context) {
    throw new Error('usePreviousPage must be used within a PreviousPageProvider')
  }

  return context
}
