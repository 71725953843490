import { YcfJudgeStatus } from 'src/generated/graphql'
export const JUDGE_STATUS: {
  [key in keyof typeof YcfJudgeStatus]: string
} = {
  [YcfJudgeStatus.Judging]: '利用審査中',
  [YcfJudgeStatus.Unavailable]: 'サービス利用不可',
  [YcfJudgeStatus.Available]: '利用審査完了',
}

export const STATUS_UNAVAILABLE_TITLE = 'サービス利用停止中'
