export const storageKey = {
  LOCAL_CART_ITEMS: 'LOCAL_CART_ITEMS', //Array of objects
  AUTH0_VERIFICATION_EMAIL_EXPIRED: 'auth0.is.Verification_Email_Expired',
  BUTTON_ACTIVE: 'BUTTON_ACTIVE',
  LOCAL_JUDGING_STATUS: 'LOCAL_JUDGING_STATUS',
  LOCAL_BROWSE_HISTORY: 'LOCAL_BROWSE_HISTORY',
  LOCAL_ON_SALE: 'LOCAL_ON_SALE',
  SEARCH_FILTER_CATEGORY: 'SEARCH_FILTER_CATEGORY',
  LINE: 'LINE',
} as const
