import { css } from 'twin.macro'
import { Field, Form } from 'react-final-form'
// @ts-expect-error TS6133
import { useEffect } from 'react'

interface Props {
  searchKeyword?: string
  changeKeyword?: boolean
  search: (values: { keyword: string }) => void
  setChangeKeyword: () => void
}

export function ProductSearchBar({ searchKeyword, changeKeyword, search, setChangeKeyword }: Props) {
  return (
    <Form onSubmit={search} initialValues={{ keyword: searchKeyword }}>
      {({ handleSubmit, form }) => (
        // デバイス端末の「改行」を「検索」に変更するために action="#" を設定しています
        <form action="#" onSubmit={handleSubmit}>
          <Field name="keyword">
            {({ input }) => (
              <div tw="flex w-full h-full border-solid rounded-lg bg-white">
                <div tw="flex w-full">
                  <input
                    type="search"
                    placeholder="品名・都道府県・生産者名などで検索"
                    tw="w-full outline-none pl-1 landing-pc:pl-3 m-1 text-12px landing-pc:text-14px"
                    {...input}
                    css={css`
                      ::-webkit-search-cancel-button {
                        -webkit-appearance: none;
                      }
                    `}
                    value={changeKeyword ? searchKeyword : input.value}
                    onChange={(e) => {
                      form.change('keyword', e.target.value)
                      setChangeKeyword()
                    }}
                  />
                  {(changeKeyword ? searchKeyword : input.value) && (
                    <div
                      onClick={() => {
                        form.reset({ keyword: '' })
                        search({ keyword: '' })
                        setChangeKeyword()
                      }}
                      tw="mr-0 text-right w-6 pr-1"
                    >
                      <img tw="h-10 w-10 cursor-pointer" src="/icon_close3.svg" />
                    </div>
                  )}
                </div>
                <button type="submit" tw="bg-orange rounded-r-lg mr-0 text-right w-10 h-10">
                  <img tw="h-10 w-10 max-w-max" src="/icon_search.svg" />
                </button>
              </div>
            )}
          </Field>
        </form>
      )}
    </Form>
  )
}
