import tw, { TwStyle } from 'twin.macro'

export const envNames: {
  [key: string]: {
    title: string
    cssParam: TwStyle
  }
} = {
  ['local']: {
    title: 'ローカル環境',
    cssParam: tw`bg-blue-300`,
  },
  ['development']: {
    title: 'DEV環境',
    cssParam: tw`bg-green-line`,
  },
  ['development2']: {
    title: 'DEV2環境',
    cssParam: tw`bg-orange-line`,
  },
  ['staging']: {
    title: 'STG環境',
    cssParam: tw`bg-red-landing`,
  },
}
