export const SUBCATEGORY_RANK = [
  '葉茎菜類',
  '果菜類',
  '豆類',
  'いも類',
  '根菜',
  'きのこ類',
  '山菜',
  '果物',
  '発芽野菜',
  'ハーブ、スパイス',
  '香辛（香味）野菜',
  '新顔野菜（特殊野菜）',
  'つまもの（飾り野菜）',
  '加工品',
  'その他',
]

export const PRODUCT_CATEGORY_MAP: { [key: string]: string } = {
  Latest: '新着順',
  Limited: '限定商品',
  Ranking: '売れ筋トップ50',
  Repeat: '再購入する',
  TopRecommend: 'あなたへのおすすめ',
  SeasonalIngredients: '旬の食材',
  BargainArticle: '特価品',
  Reservation: '予約商品',
  Freeze: 'こだわり冷凍食材',
}
