export const Route = {
  // Common
  landing: '/',
  authLoading: '/auth-loading',
  error: '/error',
  verifyEmail: '/verify-email',
  campaignLanding: '/campaign/autumn_0901',
  sendVerificationEmail: '/verify-email/send-email',
  genericError: '/generic-error',
  ycfJudge: '/ycf-judge',
  mobileSearch: '/product/mobile-search',
  lineConnectAuth0AuthorizeCallback: '/line/connect/auth0/authorize/callback',
  lineConnectAuth0LoginCallback: '/line/connect/auth0/login/callback',
  lineConnectAuth0LoginCushion: '/line/connect/auth0/login/cushion',
  lineConnectCallback: '/line/connect/callback',
  lineConnectCancel: '/line/connect/cancel',
  lineConnectConfirm: '/line/connect/confirm',
  lineConnectComplete: '/line/connect/complete',
  lineConnectError: '/line/connect/error',
  lineDisconnect: '/line/disconnect',
  lineDisconnectCallback: '/line/disconnect/callback',
  lineDisconnectLogin: '/line/disconnect/login',
  lineDisconnectConfirm: '/line/disconnect/confirm',
  lineDisconnectCancel: '/line/disconnect/cancel',
  lineDisconnectComplete: '/line/disconnect/complete',
  lineDisconnectError: '/line/disconnect/error',
  lineLogin: '/line/login',
  lineLoginCallback: '/line/login/callback',
  lineLoginAuto: '/line/login/auto',
  sellerLineConnectAuth0AuthorizeCallback: '/seller/line/connect/auth0/authorize/callback',
  sellerLineConnectAuth0LoginCallback: '/seller/line/connect/auth0/login/callback',
  sellerLineConnectAuth0LoginCushion: '/seller/line/connect/auth0/login/cushion',
  sellerLineConnectCallback: '/seller/line/connect/callback',
  sellerLineConnectCancel: '/seller/line/connect/cancel',
  sellerLineConnectConfirm: '/seller/line/connect/confirm',
  sellerLineConnectComplete: '/seller/line/connect/complete',
  sellerLineConnectError: '/seller/line/connect/error',
  sellerLineDisconnect: '/seller/line/disconnect',
  sellerLineDisconnectCallback: '/seller/line/disconnect/callback',
  sellerLineDisconnectLogin: '/seller/line/disconnect/login',
  sellerLineDisconnectConfirm: '/seller/line/disconnect/confirm',
  sellerLineDisconnectCancel: '/seller/line/disconnect/cancel',
  sellerLineDisconnectComplete: '/seller/line/disconnect/complete',
  sellerLineDisconnectError: '/seller/line/disconnect/error',
  sellerLineLogin: '/seller/line/login',
  sellerLineLoginCallback: '/seller/line/login/callback',
  sellerLineLoginAuto: '/seller/line/login/auto',
  // Buyer
  onboarding: '/onboarding',
  buyerQuestionnaire: '/buyer-questionnaire',
  buyerQuestionnaireCompleted: '/buyer-questionnaire/completed',
  productSearch: '/product/search',
  productSearchResult: '/product/search/result',
  productSearchResultOld: '/product/search/resultOld',
  productSearchDelivery: '/product/delivery-date',
  productSearchDeliveryResult: '/product/search/result/DeliveryDateResult',
  // meatFeatureProducts: '/product/meat',
  asparagusFeatureProducts: '/product/asparagus',
  chickenFeatureProducts: '/product/chicken',
  specialFeatureProducts: '/product/special/[type]',
  specialFreighter: '/product/special/freighter',
  mushroomProducts: '/product/mushroom',
  productDetail: '/product/[id]',
  orderHistory: '/order/history',
  orderHistoryCanceled: '/order/history/canceled',
  setting: '/setting',
  coupon: '/coupon',
  termsAndConitions: '/terms-and-conditions',
  scta: '/scta', //specified commercial transaction act
  cart: '/cart',
  favorite: '/product/favorite',
  productTop: '/product/top',
  // Seller
  sellerLanding: '/seller/landing',
  sellerHome: '/seller/home',
  sellerOnboarding: '/seller/onboarding',
  sellerShowseller: '/seller/showseller',
  sellerProductNew: '/seller/product/new',
  sellerProductEdit: '/seller/product/[id]/edit',
  sellerDraftProductEdit: '/seller/product/edit-draft-product',
  sellerProductDetail: '/seller/product/[id]',
  sellerOrderHistory: '/seller/order/history',
  sellerSetting: '/seller/setting',
  sellerTermsAndConitions: '/seller/terms-and-conditions',
  sellerScta: '/seller/scta', //specified commercial transaction act
  sellerBusinessEdit: '/seller/setting/edit/business',
  sellerPrEdit: '/seller/setting/edit/pr',
  sellerBankEdit: '/seller/setting/edit/bank',
  sellerBusinessType: '/seller/setting/edit/businessType',
} as const

export const COMMON_ROUTE = [
  Route.scta,
  Route.sellerScta,
  Route.sellerLanding,
  Route.termsAndConitions,
  Route.sellerTermsAndConitions,
  Route.sendVerificationEmail,
  Route.genericError,
  Route.error,
  Route.verifyEmail,
  Route.ycfJudge,
  Route.mobileSearch,
] as string[]

export const GUEST_ROUTE = [
  Route.landing,
  Route.scta,
  Route.sellerLanding,
  Route.sellerScta,
  Route.termsAndConitions,
  Route.sellerTermsAndConitions,
  Route.campaignLanding,
  Route.lineConnectAuth0LoginCushion,
  Route.lineConnectCallback,
  Route.lineConnectCancel,
  Route.lineConnectConfirm,
  Route.lineConnectError,
  // LINE認可からの戻りでログインセッションが取得できる前にヘッダー出し分け処理が実行されてしまいヘッダーが非表示になることを防ぐ為、
  // 本来はBUYER_ROUTEだが追加。未ログインでlineDisconnectConfirmに遷移しても実害はない。
  Route.lineDisconnectConfirm,
  Route.lineLogin,
  Route.lineLoginCallback,
  Route.lineLoginAuto,
  Route.sellerLineConnectAuth0LoginCushion,
  Route.sellerLineConnectCallback,
  Route.sellerLineConnectCancel,
  Route.sellerLineConnectConfirm,
  Route.sellerLineConnectError,
  Route.sellerLineDisconnectConfirm,
  Route.sellerLineLogin,
  Route.sellerLineLoginCallback,
  Route.sellerLineLoginAuto,
] as string[]

export const LEVEL_TWO_ROUTE = [
  Route.productSearchResult,
  Route.productSearchDelivery,
  Route.productSearchDeliveryResult,
  Route.specialFeatureProducts,
  Route.specialFreighter,
  // Route.meatFeatureProducts,
  Route.asparagusFeatureProducts,
  Route.chickenFeatureProducts,
] as string[]

export const BUYER_ROUTE = [
  Route.coupon,
  Route.productSearch,
  Route.productTop,
  Route.productSearchResult,
  Route.productSearchResultOld,
  Route.productSearchDelivery,
  Route.productSearchDeliveryResult,
  // Route.meatFeatureProducts,
  Route.chickenFeatureProducts,
  // アスパラ一時撤去: https://yamato-hd.slack.com/archives/C025WAYG96U/p1650355979445969
  // 五月中旬再開予定
  // Route.asparagusFeatureProducts,
  Route.specialFeatureProducts,
  Route.specialFreighter,
  Route.mushroomProducts,
  Route.productDetail,
  Route.orderHistory,
  Route.orderHistoryCanceled,
  Route.setting,
  Route.cart,
  Route.favorite,
  Route.mobileSearch,
  Route.lineConnectAuth0AuthorizeCallback,
  Route.lineConnectAuth0LoginCallback,
  Route.lineConnectCallback,
  Route.lineConnectCancel,
  Route.lineConnectConfirm,
  Route.lineConnectError,
  Route.lineConnectComplete,
  Route.lineDisconnect,
  Route.lineDisconnectCallback,
  Route.lineDisconnectConfirm,
  Route.lineDisconnectCancel,
  Route.lineDisconnectLogin,
  Route.lineDisconnectComplete,
  Route.lineDisconnectError,
  Route.lineLogin,
  Route.lineLoginCallback,
  Route.lineLoginAuto,
  Route.buyerQuestionnaire,
  Route.buyerQuestionnaireCompleted,
  ...COMMON_ROUTE,
] as string[]

export const SELLER_ROUTE = [
  Route.sellerHome,
  Route.sellerProductNew,
  Route.sellerProductEdit,
  Route.sellerDraftProductEdit,
  Route.sellerProductDetail,
  Route.sellerOrderHistory,
  Route.sellerSetting,
  Route.sellerBusinessEdit,
  Route.sellerPrEdit,
  Route.sellerBankEdit,
  Route.sellerBusinessType,
  Route.productDetail,
  Route.sellerShowseller,
  Route.sellerLineConnectAuth0AuthorizeCallback,
  Route.sellerLineConnectAuth0LoginCallback,
  Route.sellerLineConnectCallback,
  Route.sellerLineConnectCancel,
  Route.sellerLineConnectConfirm,
  Route.sellerLineConnectError,
  Route.sellerLineConnectComplete,
  Route.sellerLineDisconnect,
  Route.sellerLineDisconnectCallback,
  Route.sellerLineDisconnectLogin,
  Route.sellerLineDisconnectConfirm,
  Route.sellerLineDisconnectCancel,
  Route.sellerLineDisconnectComplete,
  Route.sellerLineDisconnectError,
  Route.sellerLineLogin,
  Route.sellerLineLoginCallback,
  Route.sellerLineLoginAuto,
  ...COMMON_ROUTE,
] as string[]

export const SHOWSELLER_ROUTE = [
  Route.productSearch,
  Route.productSearchResult,
  Route.productSearchDelivery,
  Route.productSearchDeliveryResult,
  Route.productDetail,
  Route.sellerShowseller,
  ...COMMON_ROUTE,
] as string[]

export type RouteValue = typeof Route[keyof typeof Route]
