import 'twin.macro'
import { Button } from 'src/components/Button/Button'
import { Portal } from 'src/components/Portal/Portal'
import { Overlay } from 'src/components/Overlay/Overlay'

export interface Props {
  onBuyerButtonClicked: () => void
  onSellerButtonClicked: () => void
  onClickOverlay: () => void
}

export function LoginModal({ onBuyerButtonClicked, onSellerButtonClicked, onClickOverlay }: Props) {
  return (
    <Portal>
      <Overlay onClick={onClickOverlay}>
        <div tw="relative flex flex-col justify-between items-center w-11/12 max-w-lg bg-white rounded-lg p-24px">
          <p tw="text-center text-18px font-bold text-green-deep">ログイン</p>
          <Button type="button" tw="bg-green-light text-white mt-16px" onClick={onBuyerButtonClicked}>
            <span tw="text-14px flex justify-center">
              <img src="/landing/icon_restaurant.svg" tw="mr-1" />
              飲食店さまログイン
            </span>
          </Button>
          <Button type="button" tw="bg-blue-900 text-white mt-1" onClick={onSellerButtonClicked}>
            <span tw="text-14px flex justify-center">
              <img src="/landing/icon_farmer.svg" tw="mb-1 mr-1" />
              生産者さまログイン
            </span>
          </Button>
        </div>
      </Overlay>
    </Portal>
  )
}
