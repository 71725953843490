export enum SpecialTagStatus {
  Latest = 'Latest', // 新着の食材
  Limited = 'Limited',
  Repeat = 'Repeat', // 購入したことのある食材  おすすめの食材
  Ranking = 'Ranking', // 🥇️売れ筋トップ50
  TopRecommend = 'TopRecommend', //あなたへのおすすめ
  SeasonalIngredients = 'SeasonalIngredients', //旬の食材
  BargainArticle = 'BargainArticle', // 特価品
  Sample = 'Sample', //お試し品
}

export const MenuSpecialTagLabel: { [key: string]: string } = {
  Ranking: '売れ筋トップ50',
  TopRecommend: 'あなたへのおすすめ',
  SeasonalIngredients: '旬の食材・特価品',
}

export const SpecialTagTitle: { [key: string]: string } = {
  Latest: '新着順',
  Limited: '限定商品',
  Ranking: '売れ筋トップ50',
  TopRecommend: 'あなたへのおすすめ',
  SeasonalIngredients: '旬の食材',
  BargainArticle: '特価品',
  Reservation: '予約商品',
  Freeze: 'こだわり冷凍食材',
}

// ProductCategoryに入る値で除外するタグ
export const ExcludeSpecialTag: string[] = [
  'Latest',
  'Repeat',
  'Ranking',
  'TopRecommend',
  'Limited',
  'SeasonalIngredients',
  'Reservation',
  'Freeze',
  'Sample',
]
